import React, {useContext, useEffect, useState} from 'react';

import 'chart.js'
import BodyContadorPage from "./body";
import Filtro from "./filtro";
import Axios from "axios";
import {catchApiErrors, expulsarIfTokenInvalido, getLocalStorageData} from "../../../helpers/auth-helpers";
import {AuthDataContext} from "../../../context/auth-context";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Redirect} from 'react-router-dom';

import './style.scss';
import {url_api} from "../../../axiosConfig";
import DivGrisTitulo from "../../common-components/seccion-titulo";
import RenderHeader from "../../common-components/header";
import {BackdropCargandoContext} from "../../../context/backdrop-cargando-context";
import {useTranslation} from 'react-i18next';
import {positions, useAlert} from "react-alert";
import dedo from "../../../img/dedo-ico.png";
import cross from "../../../img/danger.png";

function GraficoPageContador(props) {

    const alert = useAlert();

    const {state} = useContext(AuthDataContext);
    const {backdrop_dispatch} = useContext(BackdropCargandoContext);

    const [dataGrafico, setdataGrafico] = useState();
    const [btnconectarDisabled, setbtnconectarDisabled] = useState(true);
    const [chartLoading] = useState(false);
    const {t} = useTranslation();



    function getContadorData() {

        let data_post = {
            uuid: state.user.uniqid,
        };


        setdataGrafico(null)

        backdrop_dispatch({
            type: 'open',
            data: {}
        });

        Axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(getLocalStorageData('token'))}`;
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.defaults.withCredentials = true;
        Axios.post(`${url_api + 'contador/get'}`, data_post)
            .then(res => {

                if(!res.data.S01 || res.data.S01==null || res.data.S01== ''){
                    setTimeout(() => {
                        backdrop_dispatch({
                            type: 'close',
                            data: {}
                        });
                    }, 500)
                    alert.show(<div className="Alert Alert_danger"><img src={cross} alt=""/> <span className="pt-1">{res.data}</span></div>, { position: positions.MIDDLE_RIGHT})

                    return
                }
                if(!res.data.S01['@Pimp'] || res.data.S01['@Pimp']==null || res.data.S01['@Pimp']== ''){
                    setTimeout(() => {
                        backdrop_dispatch({
                            type: 'close',
                            data: {}
                        });
                    }, 500)
                    alert.show(<div className="Alert Alert_danger"><img src={cross} alt=""/> <span className="pt-1">{t('no_dades')}</span></div>, { position: positions.MIDDLE_RIGHT})

                    return
                }
                let res_formateada = res.data.S01


                if( res_formateada['@Eacti'] ==='0' || res_formateada['@Eacti'] ==='2'){ // si el icp está apagado
                    setbtnconectarDisabled(false)//hago que el disabled del boton conectar contador sea false
                }else{
                    setbtnconectarDisabled(true)
                }


                if (res.data.length < 1) {

                    setdataGrafico('vacio')

                } else {


                    setdataGrafico(res_formateada)

                }
                /*setchartLoading(false)*/
                setTimeout(() => {
                    backdrop_dispatch({
                        type: 'close',
                        data: {}
                    });
                }, 500)

            }).catch(function (error) {
            catchApiErrors(error,backdrop_dispatch,t,alert)
        })
    }
    function conectarContador() {

        let data_post = {
            uuid: state.user.uniqid,
        };


        setdataGrafico(null)

        backdrop_dispatch({
            type: 'open',
            data: {}
        });


        Axios.post(`${url_api + 'contador/conectar'}`, data_post)
            .then(res => {

                backdrop_dispatch({
                    type: 'close',
                    data: {}
                });

             if(res){ //la respuesta de la api es true o false

                 alert.show(<div className="Alert Alert_success"><img src={dedo} alt=""/> <span className="pt-1">{t('cont_conectado')}  </span></div>, { position: positions.MIDDLE_RIGHT})
                 setbtnconectarDisabled(true)
             }else{


                 alert.show(<div className="Alert Alert_error"><img src={cross} alt=""/> <span className="pt-1">{t('err_cont')} </span></div>, { position: positions.MIDDLE_RIGHT})

             }
             /*setTimeout( () => {
                 getContadorData()
             },1000)*/





            }).catch(function (error) {
            alert.show(<div className="Alert Alert_success"><img src={dedo} alt=""/> <span className="pt-1">Error Resp. api: {error} </span></div>, { position: positions.MIDDLE_RIGHT})

            catchApiErrors(error,backdrop_dispatch,t,alert)
        })
    }


    if (!props.location.state) {
        return <Redirect to="/potencia-maxima"/>
    } else {
        return (
            <>
                <RenderHeader/>
                <DivGrisTitulo tituloBienvenida={false} titulo={t('contador_titulo')}/>
                <div className="Padding-seccion-principal">


                    <div className="Ver-grafica-contador">
                        <div className="container px-md-5">
                            <div className="text-center mb-3"><h2 className=" mb-4 text-center Borde-gris">CUPS: &nbsp;
                                 {props.location.state.cups}</h2>

                                <p className="text-center my-4"  ><b>{t('direccion')}: </b> <span style={{'fontSize':'17px','textTransform':'uppercase'}}> {props.location.state.direccion}</span> </p>
                            </div>
                            <div className="row">
                                <div className="col-12  d-flex flex-wrap flex-column">


                                    <p className="text-center pl-0 pt-0" style={{'fontSize': '15px'}}>{t('contador_subtitulo')} </p>
                                    <p className="text-center pl-0 pt-0" style={{'fontSize': '15px'}}>{t('contador_tiempo')} <br/>{t('contador_tiempo2')}  </p>

                                   {/* <div>
                                        <h2 className=" Titulo-pag-grafico">
                                            {t('sp')}
                                        </h2>
                                    </div>*/}
                                    <div className="d-flex w-100">
                                        <Filtro btnconectarDisabled={btnconectarDisabled} getContadorData={getContadorData} conectarContador = { conectarContador}/>
                                    </div>

                                </div>


                            </div>
                        </div>

                        <div className="container px-0 px-md-5 Z-index-1">
                            <div className="row Z-index-1">
                                <div className="col-12 mt-5 text-center">
                                    {chartLoading &&
                                    <CircularProgress className="mx-auto text-center "
                                                      disableShrink/>}
                                    { dataGrafico && <BodyContadorPage potencia_contratada={props.location.state.potencia_contratada} dataGrafico={dataGrafico} />}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }


}

export default GraficoPageContador;