import React, {Component, useContext, useEffect, useState} from 'react';

import {AuthDataContext} from "../context/auth-context";
import {getLocalStorageData} from "../helpers/auth-helpers";
import RenderHeader from "./common-components/header";
import {Router} from "./router";
import {Footer} from "./common-components/footer";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {BackdropCargandoContext} from "../context/backdrop-cargando-context";
import Axios from "axios";
import {url_api} from "../axiosConfig";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function CheckLogguedOnLoad(props) {
    const {dispatch} = useContext(AuthDataContext);
    const {backdrop_state} = useContext(BackdropCargandoContext);
    const [loading, setLoading] = useState(true)
    const classes = useStyles();
    /*COGEMOS LOS TOKENS DEL LOCALSTORAGE Y SE LO PASAMOS AL CHECK_LOGGED QUE LO UNICO QUE HACE ES PONERLO
     EN EL STORAGE DEL CONTEXT, A PARTIR DE ESTE STORAGE YA SE CONTROLA SI SE ESTÁ LOGUEADO O NO
    * */
    useEffect(() => {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(getLocalStorageData('token'))}`;
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.defaults.withCredentials = true;

        if (getLocalStorageData('token')) {

            /*Compruebo si el token del localstorage es válido, si lo es actualizo el authstorage con los datos
            del usuario logueado con ese token que me devuelve la llamada a checkAuthenticated
             */
            Axios.get(url_api + `auth/checkAuthenticated`)
                .then(res => {
                    if(res.status===200){
                        const params_update= {
                            user : res.data.user,
                            token : getLocalStorageData('token')
                        }
                        dispatch({
                            type: 'update_auth_storage',
                            data: params_update
                        });
                    }else{
                        dispatch({
                            type: 'delete_auth_storage'
                        });
                    }



                }).catch((e) => { // si no es válido y devuelve error borro dejo el authstorage vacio
                dispatch({
                    type: 'delete_auth_storage'
                });

            })

            /*LE PONGO UN SETTIMEOUT PARA QUE DE TIEMPO A CARGAR LAS TRADUCCIONES DEL i18next
            * y no se vea la key antes*/
            setTimeout( () => {
                setLoading(false)

            },2000)

        }else{
            /*LE PONGO UN SETTIMEOUT PARA QUE DE TIEMPO A CARGAR LAS TRADUCCIONES DEL i18next
           * y no se vea la key antes*/
            setTimeout( () => {
                setLoading(false)

            },2000)
        }
    }, [])



    /*CUANDO SE ACABA LA FUNCION DE CHECKLOGGED DEL CONTEXT SE ACTUALIZA LA VARAIBLE LOADING DEL STATEHOOK
    * Y POR LO TANTO SE VUELVE A RENDERIZAR EL COMPONENTE*/
    if (loading) {

        return (
            <div className="Vh-100">

                <CircularProgress style={{'position': 'absolute', 'top': '40%', 'left': '48%'}} disableShrink/>
            </div>
        )
    } else {
        return (
            <>
                {backdrop_state.open &&
                <Backdrop className={classes.backdrop} open={true} >
                    <CircularProgress color="inherit" />
                </Backdrop>}
                <Router/>
                <Footer/>

            </>
        );
    }

}

export default CheckLogguedOnLoad;