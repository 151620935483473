import React, {useContext, useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Link, Redirect, useParams
} from "react-router-dom";
import {AuthDataContext} from "../../context/auth-context";
import {useForm} from 'react-hook-form'
import Axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import RenderHeader from "../common-components/header";

import DivGrisTitulo from "../common-components/seccion-titulo";
import {useAlert} from "react-alert";
import { useTranslation } from 'react-i18next';
import {setLocalStorageData} from "../../helpers/auth-helpers";

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '35px 20px'
    },
    avatar: {
        margin: theme.spacing(1),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        fontSize: '15px'
    },
}));


export default function SignIn(props) {
    const classes = useStyles();
    const {state, dispatch} = useContext(AuthDataContext);
    const {register, handleSubmit} = useForm()
    const [cargando, setCargando] = useState(false);
    const [mensajeError, setmensajeError] = useState('');
    const alert = useAlert();
    const { t, i18n } = useTranslation();
    let { lang } = useParams();

    const onSubmit = data => {
        setCargando(true)

        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.defaults.withCredentials = true;
        Axios.post(`https://api.zona-privada.electracaldensedistribucio.com/api/auth/login`, data)
            .then(res => {

                dispatch({
                    type: 'login',
                    data: res.data
                });

                setCargando(false)


                props.history.push(`/home`);


            }).catch(function (error) {
            if (error.response) {

                if (error.response.status === 401) {
                    setmensajeError(t('uci'))

                } else if (error.response.status === 422) {
                    setmensajeError(t('uci'))
                } else {
                    setmensajeError(t('ei'))

                }

                setCargando(false)

            }
        })


    }

    useEffect(()=>{
        if(lang=='es'){
            setLocalStorageData(lang,'lang')
            i18n.changeLanguage(lang).then(r => {
            });
        }else{
            setLocalStorageData('ca','lang')
            i18n.changeLanguage('ca').then(r => {
            });
        }
    },[])
    if (state.token) {
        return <Redirect to="/home"/>
    } else {
        return (
            <>
                <RenderHeader/>
                <DivGrisTitulo tituloBienvenida={null} titulo={t('bienvenido')}/>

                <div className="Bg-Transition Padding-seccion-principal">
                    {/*  {JSON.stringify(state)}
*/}


                    <div className=" Login White-card">
                        <CssBaseline/>
                        <Avatar className='Logo-login'>
                            <LockOutlinedIcon/>
                        </Avatar>
                        {/* <div>
                        <img src={logo} className="Logo mb-5" alt=""/>
                    </div>*/}
                        <Typography component="h1" variant="h5" className="">
                            {t('iniciar_sesion')}
                        </Typography>
                        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>

                            <div className="Row-flex">

                                <div>


                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="usuario_login"
                                        label="NIF/Pasaporte/NIE o CIF"
                                        name="usuario_login"
                                        className="T-uppercase"
                                        inputRef={register}
                                        autoComplete="nif"
                                        autoFocus
                                    />
                                </div>
                                <div>

                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label={t('contrasenya')}
                                        type="password"
                                        id="password"
                                        inputRef={register}
                                        autoComplete="current-password"
                                    />
                                </div>

                            </div>

                            <p className="my-4 d-block Txt-gray">{t('login_msg')}</p>

                            {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary"/>}
                            label="Remember me"
                        />*/}
                            <p className='text-danger text-center mt-3'> {mensajeError}</p>
                            <button className="Btn Btn_green mw-100 w-100 w-m-100 mb-5" type="submit">

                                {cargando ? <CircularProgress style={{'color': '#fff'}}/> : 'Entrar'}
                            </button>
                            <div className="Row-flex mb-2">
                                <div>
                                    <Link to='/recuperar-contrasenya-email'
                                          className="Txt-gray text-center d-inline-block mb-2 w-100 Txt-link-login">
                                                                                       <span className="Txt-link-login">
                                                                                          {t('olvidar_contrasenya')}</span>
                                        <button className="Btn Btn-transparent-border-gray mt-3 ">{t('recuperar')}</button>
                                    </Link>

                                </div>

                                <div>

                                    <Link to='/registrarse'
                                          className="Txt-gray text-center d-inline-block mb-2 w-100 ">
                                            <span className="Txt-link-login">
                                                                                       {t('no_cuenta')}     

                                            </span>
                                        <button className="Btn Btn-transparent-border-gray mt-3 ">{t('registrate')}</button>
                                    </Link>

                                </div>
                            </div>

                        </form>

                    </div>


                </div>
            </>
        );
    }


}