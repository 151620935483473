import Axios from 'axios';
import cross from "../img/cruz-a.png";
import {positions} from "react-alert";
import React, {useContext} from "react";
import {BackdropCargandoContext} from "../context/backdrop-cargando-context";


export function setLocalStorageData(token,key) {
    localStorage.setItem(key, token);
}

export function getLocalStorageData(key) {

    return localStorage.getItem(key);
}

export function deleteLocalStorageData(key) {
    localStorage.removeItem(key);
}

export function initAxiosInterceptors() {
    Axios.interceptors.request.use(config => {
        const token = getLocalStorageData('token');

        if (token) {
            config.headers.Authorization = `bearer ${token}`;
        }

        return config;
    });

    Axios.interceptors.response.use(
        response => response,
        error => {
            if (error.response.status === 401) {
                deleteLocalStorageData('token');
                window.location = '/login';
            } else {
                return Promise.reject(error);
            }
        }
    )
}

export function checkIfLogged(){
    if(!getLocalStorageData('token')){
        return false
    }else{
        return true
    }
}

export function expulsarIfTokenInvalido(res_code,alert){
    if(res_code===401){
        alert.error('El seu token ha expirat o no es vàlid. Siusplau torni a entrar a l\'aplicació')

        setTimeout(()=>{
            window.location.reload()

        },2000)
    }
}


export function catchApiErrors(error,backdrop_dispatch,t,alert){

    if (error.response) {

        if (error.response.status === 401) {
            expulsarIfTokenInvalido(error.response.status, alert);
        }else{
            setTimeout(() => {
                backdrop_dispatch({
                    type: 'close',
                    data: {}
                });
            }, 500)
            alert.show(<div style={{  }}><img src={cross} alt=""/> <span className="pt-1">{t('ei')}</span></div>, { position: positions.MIDDLE_RIGHT})

        }


    }
}