import React, {Component, useContext, useEffect, useState} from 'react';
import imgpdf from '../../img/pdf-pre.png'

import './style.scss';
import TextField from "@material-ui/core/TextField";
import {Controller, useForm} from "react-hook-form";
import {AuthDataContext} from "../../context/auth-context";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import {FormControl, InputLabel, MenuItem, Paper, Select} from "@material-ui/core";
import {expulsarIfTokenInvalido, getLocalStorageData} from "../../helpers/auth-helpers";
import axiosConfig from "../../axiosConfig";
import Axios from "axios";
import {positions, useAlert} from "react-alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link, withRouter} from 'react-router-dom';
import {Footer} from "../common-components/footer";
import DivGrisTitulo from "../common-components/seccion-titulo";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import RenderHeader from "../common-components/header";
import dedo from "../../img/dedo-ico.png";
import {useTranslation} from 'react-i18next';
import TransitionsModal from "../common-components/modal";
import moment from "moment";
import {ChartHoras} from "../common-components/charts/mi-consumo-chart";
import MyDropzone from "../signup-page/forms/fileUpload/dropzone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faHome} from "@fortawesome/free-solid-svg-icons";



function PerfilPage(props) {
    const {register, errors, handleSubmit, control, watch} = useForm();
    const {
        register: register2,
        errors: errors2,
        handleSubmit: handleSubmit2,
        setValue: setValue2,
        getValues: getValues2
    } = useForm();
    const alert = useAlert();
    const {state, dispatch} = useContext(AuthDataContext);

    const [formSubmited, setFormSubmited] = useState(false);
    const [activarCambiarPass, setactivarCambiarPass] = useState(false);
    const [activarCambiarMail, setactivarCambiarMail] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [errors_res, seterrors_res] = useState();
    const [errors_res_editar, seterrors_res_editar] = useState();
    const [ok_res_editar, setok_res_editar] = useState();
    const [fileDocumentoIdentificativo, setfileDocumentoIdentificativo] = useState();
    const [fileDocumentoIdentificativo2, setfileDocumentoIdentificativo2] = useState();
    const [fileDocumentoIdentificativoB64, setfileDocumentoIdentificativoB64] = useState();
    const [fileDocumentoIdentificativoB64_pdf, setfileDocumentoIdentificativoB64_pdf] = useState(false);
    const [fileDocumentoIdentificativo2B64, setfileDocumentoIdentificativo2B64] = useState();
    const [fileDocumentoIdentificativo2B64_pdf, setfileDocumentoIdentificativo2B64_pdf] = useState(false);
    const [fileApoderamiento, setfileApoderamiento] = useState();
    const [fileApoderamiento2, setfileApoderamiento2] = useState();
    const [fileApoderamientoB64, setfileApoderamientoB64] = useState();
    const [fileApoderamientoB64_pdf, setfileApoderamientoB64_pdf] = useState(false);
    const [fileApoderamiento2B64, setfileApoderamiento2B64] = useState();
    const [fileApoderamiento2B64_pdf, setfileApoderamiento2B64_pdf] = useState(false);
    const [fileCif, setfileCif] = useState();
    const [fileCif2, setfileCif2] = useState();
    const [fileCifB64, setfileCifB64] = useState();
    const [fileCifB64_pdf, setfileCifB64_pdf] = useState(false);
    const [fileCif2B64, setfileCif2B64] = useState();
    const [fileCif2B64_pdf, setfileCif2B64_pdf] = useState(false);
    const [editImagenes, seteditImagenes] = useState();
    const [cargandoEditar, setcargandoEditar] = useState();
    const [cargandoImgs, setcargandoImgs] = useState();

    const {t, i18n} = useTranslation();
    const [openModal_dni, setopenModal_dni] = useState();
    const [openModal_cif, setopenModal_cif] = useState();
    const [openModal_apoderament, setopenModal_apoderament] = useState();
    const fileToForm_dni = (name, files) => { //funcion que pasaré por prop al dropzone y que actualiza la variables file registro arriba del formlario

        let data_editar = {
            file_documento: files,
            uuid: state.user.uniqid
        }
        seteditImagenes(data_editar)

    }
    const fileToForm_cif = (name, files) => { //funcion que pasaré por prop al dropzone y que actualiza la variables file registro arriba del formlario

        let data_editar = {
            file_cif: files,
            uuid: state.user.uniqid
        }
        seteditImagenes(data_editar)

    }
    const fileToForm_apoderamiento = (name, files) => { //funcion que pasaré por prop al dropzone y que actualiza la variables file registro arriba del formlario

        let data_editar = {
            file_apoderamiento: files,
            uuid: state.user.uniqid
        }
        seteditImagenes(data_editar)

    }
    const editarImagenes = () => {

        setok_res_editar('')
        seterrors_res_editar('')
        setcargandoEditar(true)
        Axios.post(`https://api.zona-privada.electracaldensedistribucio.com/api/user/edit_images`, editImagenes)
            .then(res => {
                setcargandoEditar(false)


                setok_res_editar(t('gc'))

                setTimeout(() => {
                    setopenModal_dni(false)
                    setopenModal_cif(false)
                    setopenModal_apoderament(false)

                    window.location.reload();
                }, 1000)


            }).catch(function (error) {
            if (error.response) {
                setcargandoEditar(false)

                if (error.response.status === 401) {
                    expulsarIfTokenInvalido(error.response.status, alert);

                } else {
                    seterrors_res_editar(t('ei'))

                }

                setCargando(false)

            }
        })

    }
    const onSubmit = data => {
        setCargando(true)
        data.uuid = state.user.uniqid


        Axios.post(`https://api.zona-privada.electracaldensedistribucio.com/api/user/edit`, data)
            .then(res => {
                seterrors_res('')

                const params_update = {
                    user: res.data.data,
                }

                dispatch({
                    type: 'update_auth_storage',
                    data: params_update
                });

                setCargando(false)
                alert.show(<div className="Alert Alert_success"><img src={dedo} alt=""/> <span
                    className="pt-1">{t('uec')}</span></div>, {position: positions.MIDDLE_RIGHT})

                /* setTimeout(() => {
                     props.history.push('/home')

                 }, 1000)*/
            }).catch(function (error) {
            if (error.response) {

                if (error.response.status === 401) {
                    expulsarIfTokenInvalido(error.response.status, alert);

                } else if (error.response.status === 422) {
                    seterrors_res('Ese email ya está en uso')
                } else {
                    seterrors_res(t('ei'))

                }

                setCargando(false)

            }
        })
    }


    useEffect(() => {

        getFiles();
    }, [])


    function handle2Docs(doc, setFile1, setFile2, setFileB64_1, setFileB64_2 , setfile1pdf, setfile2pdf) {
        if (doc.includes(",")) {//para saber si viene 1 imagen o 2

            let array_docIf = doc.split(",")
            setFile1(array_docIf[0])
            setFile2(array_docIf[1])
            if(array_docIf[0].includes('.pdf')){
                setfile1pdf(true)
            }
            if(array_docIf[1].includes('.pdf')){
                setfile2pdf(true)
            }
            /*PARA OBTENER EL b64 de la img */
            setB64(array_docIf[0], setFileB64_1)
            setB64(array_docIf[1], setFileB64_2)

        } else {
            if(doc.includes('.pdf')){
                setfile1pdf(true)
            }
            setFile1(doc)
            setB64(doc, setFileB64_1)

        }
    }

    function setB64(url_file, set_function) {
        if (url_file) {

            let data = {
                file: url_file
            }
            Axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(getLocalStorageData('token'))}`;
            Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
            Axios.defaults.withCredentials = true;
            Axios.post(`https://api.zona-privada.electracaldensedistribucio.com/api/user/getb64`, data)
                .then(res => {


                    set_function(res.data)

                }).catch(function (error) {
                if (error.response) {

                    if (error.response.status === 401) {
                        expulsarIfTokenInvalido(error.response.status, alert);

                    } else {
                        seterrors_res(t('ei'))

                    }

                    setCargando(false)

                }
            })
        }

    }


    function getFiles() {
        setcargandoImgs(true)
        let data_params = {
            uuid: state.user.uniqid
        }
        Axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(getLocalStorageData('token'))}`;
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.defaults.withCredentials = true;
        Axios.post(`https://api.zona-privada.electracaldensedistribucio.com/api/user/get_files`, data_params)
            .then(res => {

                handle2Docs(res.data.data[0].url_documento_identificativo, setfileDocumentoIdentificativo, setfileDocumentoIdentificativo2, setfileDocumentoIdentificativoB64, setfileDocumentoIdentificativo2B64, setfileDocumentoIdentificativoB64_pdf, setfileDocumentoIdentificativo2B64_pdf)

                if(state.user.tipo == 'representante'){
                    handle2Docs(res.data.data[0].url_documento_cif, setfileCif, setfileCif2, setfileCifB64, setfileCif2B64,setfileCifB64_pdf,setfileCif2B64_pdf)
                    handle2Docs(res.data.data[0].url_documento_apoderamiento, setfileApoderamiento, setfileApoderamiento2, setfileApoderamientoB64, setfileApoderamiento2B64, setfileApoderamientoB64_pdf,setfileApoderamiento2B64_pdf)
                }
                   setTimeout(() => {
                    setcargandoImgs(false)

                }, 2000)

            }).catch(function (error) {
            if (error.response) {

                if (error.response.status === 401) {
                    expulsarIfTokenInvalido(error.response.status, alert);

                } else {
                    seterrors_res(t('ei'))

                }

                setTimeout(() => {
                    setcargandoImgs(false)

                }, 2000)

            }
        })
    }

    function downloadDoc(file, nom) {

        let extension = file.split('.')[1]

        Axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(getLocalStorageData('token'))}`;
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.defaults.withCredentials = true;
        Axios.get('https://api.zona-privada.electracaldensedistribucio.com/api/user/download_file/' + state.user.uniqid + '/' + file + '/ds', {
            responseType: 'blob',
            timeout: 30000,
        }).then((response) => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nom + '.' + extension);
            document.body.appendChild(link);
            link.click();
        }).catch((error) => {
        });

    }

    function renderCambiarPass() {


        if (activarCambiarPass) {
            return (
                <div className="d-flex p-relative">
                    <TextField name="pass" autoFocus fullWidth
                               margin="normal" className="m-0 mr-5"
                               label={t('nc')} required
                               variant="outlined" type="password"
                               inputRef={register({
                                   required: t('co'),
                                   pattern: {
                                       value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                                       message: t('cob')
                                   }

                               })}/>
                    <FormHelperText style={{position: 'absolute', bottom: '-25px', color: 'red'}}>
                        {errors.pass && errors.pass.message}
                    </FormHelperText>
                    <TextField name="nuevo_pass" fullWidth
                               margin="normal" className="m-0"
                               label={t('confirmar_contrasenya')} required
                               variant="outlined" type="password" inputRef={register({
                        validate: (value) => value === watch('pass') || t('cob')
                    })}/>
                    <FormHelperText style={{position: 'absolute', bottom: '-25px', right: 0, color: 'red'}}>
                        {errors.nuevo_pass && errors.nuevo_pass.message}
                    </FormHelperText>
                </div>
            )
        } else {
            return (<TextField disabled autoFocus fullWidth
                               margin="normal" className="m-0"
                               label={t('contrasenya')}
                               variant="outlined" value="pass" type="password"/>
            )
        }
    }

    function renderCambiarMail() {

        if (activarCambiarMail) {
            return (
                <>
                    <TextField name="email" autoFocus fullWidth
                               margin="normal" className="m-0 mr-5"
                               label={t('ne')} required
                               variant="outlined" type="email"
                               inputRef={register()}/>

                    <TextField name="nuevo_email" autoFocus fullWidth
                               margin="normal" className="m-0"
                               label={t('rne')} required
                               variant="outlined" type="email"
                               inputRef={register({
                                   validate: (value) => value === watch('email') || t('emc')
                               })}/>
                    <FormHelperText style={{position: 'absolute', bottom: '0px', color: 'red'}}>
                        {errors.nuevo_email && errors.nuevo_email.message}
                    </FormHelperText>
                </>
            )
        } else {
            return (<TextField disabled autoFocus fullWidth
                               margin="normal" className="m-0"
                               label="Email"
                               variant="outlined" value={state.user.email} type="text"/>
            )
        }
    }

    function renderFormRepresentante() {

        return (
            <div className="row">
                <div className="col-12 d-flex flex-column">
                    <form key={1} onSubmit={handleSubmit(onSubmit)} className="Form-edit-user">

                        <div className="Row-flex Row_input_btn">
                            <TextField name="razon_social" autoFocus fullWidth
                                       margin="normal"
                                       label={t('rao')} inputRef={register()} required
                                       variant="outlined" defaultValue={state.user.razon_social}
                            />
                        </div>
                        <h2 className="Subtitulo2 ">

                            {t('da')}
                        </h2>
                        <div className="Row-flex">

                            <TextField name="cif" className="w-100" autoFocus fullWidth
                                       margin="normal"
                                       label="CIF"
                                       variant="outlined" defaultValue={state.user.cif}
                                       disabled/>
                        </div>
                        <div className="Row-flex Row_input_btn">


                            {
                                renderCambiarPass()
                            }


                            <div
                                className=" Btn_gray Btn  d-flex align-items-center justify-content-center Cursor-pointer"
                                onClick={() => {
                                    if (activarCambiarPass) {
                                        setactivarCambiarPass(false)
                                    } else {
                                        setactivarCambiarPass(true)

                                    }
                                }}>                               {!activarCambiarPass ? t('modpass') : t('cancelar')}

                            </div>


                        </div>
                        <h2 className="Subtitulo2 M-form-titulo ">

                            {t('dp')}
                        </h2>

                        <div className="Row-flex">
                            <TextField name="nif" autoFocus fullWidth
                                       margin="normal"
                                       label="NIF/PASAPORTE/NIE" className="w-100"
                                       variant="outlined" defaultValue={state.user.n_documento}
                                       disabled/>
                        </div>
                        <div className="Row-flex">

                            <div className=" w-100-m d-flex align-items-center Padding-movil"
                                 style={{paddingLeft: "0"}}>

                                <TextField name="nombre" defaultValue={state.user.nombre} autoFocus fullWidth
                                           margin="normal" label={t('nombre')} variant="outlined"
                                           inputRef={register()} required/>
                            </div>
                            <div className=" w-100-m d-flex align-items-center Padding-movil"
                                 style={{paddingLeft: "0"}}>

                                <TextField name="apellidos" defaultValue={state.user.apellidos} autoFocus fullWidth
                                           margin="normal"
                                           label={t('apellidos')}
                                           variant="outlined"
                                           inputRef={register()} required/>

                            </div>
                        </div>

                        <div className="Row-flex Row_input_btn">


                            {
                                renderCambiarMail()
                            }


                            <div
                                className="Btn  d-flex align-items-center justify-content-center Cursor-pointer  Btn_gray"
                                onClick={() => {
                                    if (activarCambiarMail) {
                                        setactivarCambiarMail(false)
                                    } else {
                                        setactivarCambiarMail(true)

                                    }
                                }}>
                                {!activarCambiarMail ? 'Modificar Email' : t('cancelar')}

                            </div>

                        </div>
                        <div className="Row-flex Row_input_btn">

                            <FormControl fullWidth error={Boolean(errors.perfil_habitual)}>
                                <InputLabel>
                                    {t('ph')}
                                </InputLabel>

                                <Controller
                                    as={
                                        <Select native>
                                            <option value="consumidor">
                                                {t('perfil1')}
                                            </option>
                                            <option value="instalador">
                                                {t('perfil2')}
                                            </option>
                                            <option value="productor">
                                                {t('perfil3')}
                                            </option>
                                        </Select>
                                    }
                                    name="perfil_habitual"
                                    rules={{required: "Campo obligatorio"}}
                                    control={control}
                                    defaultValue={state.user.perfil_habitual} fullWidth
                                />
                                <FormHelperText>
                                    {errors.perfil_habitual && errors.perfil_habitual.message}
                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div className="Row-flex ">
                            <div className="w-33 d-flex align-items-center pb-0 pl-0 Padding-movil"
                            >
                                <FormControl fullWidth error={Boolean(errors.prefijo_tlf)}>
                                    <InputLabel>
                                        {t('pt')}
                                    </InputLabel>

                                    <Select inputRef={register()} fullWidth name="prefijo_tlf" native
                                            defaultValue={state.user.prefijo_tlf}>
                                        <option value="34">España (+34)</option>
                                        <option value="44">UK (+44)</option>
                                        <option value="1">USA (+1)</option>
                                        <option value="213">Algeria (+213)</option>
                                        <option value="376">Andorra (+376)</option>
                                        <option value="244">Angola (+244)</option>
                                        <option value="1264">Anguilla (+1264)</option>
                                        <option value="1268">Antigua &amp; Barbuda
                                            (+1268)
                                        </option>
                                        <option value="54">Argentina (+54)</option>
                                        <option value="374">Armenia (+374)</option>
                                        <option value="297">Aruba (+297)</option>
                                        <option value="61">Australia (+61)</option>
                                        <option value="43">Austria (+43)</option>
                                        <option value="994">Azerbaijan (+994)</option>
                                        <option value="1242">Bahamas (+1242)</option>
                                        <option value="973">Bahrain (+973)</option>
                                        <option value="880">Bangladesh (+880)</option>
                                        <option value="1246">Barbados (+1246)</option>
                                        <option value="375">Belarus (+375)</option>
                                        <option value="32">Belgium (+32)</option>
                                        <option value="501">Belize (+501)</option>
                                        <option value="229">Benin (+229)</option>
                                        <option value="1441">Bermuda (+1441)</option>
                                        <option value="975">Bhutan (+975)</option>
                                        <option value="591">Bolivia (+591)</option>
                                        <option value="387">Bosnia Herzegovina (+387)</option>
                                        <option value="267">Botswana (+267)</option>
                                        <option value="55">Brazil (+55)</option>
                                        <option value="673">Brunei (+673)</option>
                                        <option value="359">Bulgaria (+359)</option>
                                        <option value="226">Burkina Faso (+226)</option>
                                        <option value="257">Burundi (+257)</option>
                                        <option value="855">Cambodia (+855)</option>
                                        <option value="237">Cameroon (+237)</option>
                                        <option value="1">Canada (+1)</option>
                                        <option value="238">Cape Verde Islands (+238)</option>
                                        <option value="1345">Cayman Islands (+1345)</option>
                                        <option value="236">Central African Republic
                                            (+236)
                                        </option>
                                        <option value="56">Chile (+56)</option>
                                        <option value="86">China (+86)</option>
                                        <option value="57">Colombia (+57)</option>
                                        <option value="269">Comoros (+269)</option>
                                        <option value="242">Congo (+242)</option>
                                        <option value="682">Cook Islands (+682)</option>
                                        <option value="506">Costa Rica (+506)</option>
                                        <option value="385">Croatia (+385)</option>
                                        <option value="53">Cuba (+53)</option>
                                        <option value="90392">Cyprus North (+90392)</option>
                                        <option value="357">Cyprus South (+357)</option>
                                        <option value="42">Czech Republic (+42)</option>
                                        <option value="45">Denmark (+45)</option>
                                        <option value="253">Djibouti (+253)</option>
                                        <option value="1809">Dominica (+1809)</option>
                                        <option value="1809">Dominican Republic
                                            (+1809)
                                        </option>
                                        <option value="593">Ecuador (+593)</option>
                                        <option value="20">Egypt (+20)</option>
                                        <option value="503">El Salvador (+503)</option>
                                        <option value="240">Equatorial Guinea (+240)</option>
                                        <option value="291">Eritrea (+291)</option>
                                        <option value="372">Estonia (+372)</option>
                                        <option value="251">Ethiopia (+251)</option>
                                        <option value="500">Falkland Islands (+500)</option>
                                        <option value="298">Faroe Islands (+298)</option>
                                        <option value="679">Fiji (+679)</option>
                                        <option value="358">Finlandia (+358)</option>
                                        <option value="33">Francia (+33)</option>
                                        <option value="594">French Guiana (+594)</option>
                                        <option value="689">French Polynesia (+689)</option>
                                        <option value="241">Gabon (+241)</option>
                                        <option value="220">Gambia (+220)</option>
                                        <option value="7880">Georgia (+7880)</option>
                                        <option value="49">Germany (+49)</option>
                                        <option value="233">Ghana (+233)</option>
                                        <option value="350">Gibraltar (+350)</option>
                                        <option value="30">Greece (+30)</option>
                                        <option value="299">Greenland (+299)</option>
                                        <option value="1473">Grenada (+1473)</option>
                                        <option value="590">Guadeloupe (+590)</option>
                                        <option value="671">Guam (+671)</option>
                                        <option value="502">Guatemala (+502)</option>
                                        <option value="224">Guinea (+224)</option>
                                        <option value="245">Guinea - Bissau (+245)</option>
                                        <option value="592">Guyana (+592)</option>
                                        <option value="509">Haiti (+509)</option>
                                        <option value="504">Honduras (+504)</option>
                                        <option value="852">Hong Kong (+852)</option>
                                        <option value="36">Hungary (+36)</option>
                                        <option value="354">Iceland (+354)</option>
                                        <option value="91">India (+91)</option>
                                        <option value="62">Indonesia (+62)</option>
                                        <option value="98">Iran (+98)</option>
                                        <option value="964">Iraq (+964)</option>
                                        <option value="353">Ireland (+353)</option>
                                        <option value="972">Israel (+972)</option>
                                        <option value="39">Italia (+39)</option>
                                        <option value="1876">Jamaica (+1876)</option>
                                        <option value="81">Japan (+81)</option>
                                        <option value="962">Jordan (+962)</option>
                                        <option value="7">Kazakhstan (+7)</option>
                                        <option value="254">Kenya (+254)</option>
                                        <option value="686">Kiribati (+686)</option>
                                        <option value="850">Korea North (+850)</option>
                                        <option value="82">Korea South (+82)</option>
                                        <option value="965">Kuwait (+965)</option>
                                        <option value="996">Kyrgyzstan (+996)</option>
                                        <option value="856">Laos (+856)</option>
                                        <option value="371">Latvia (+371)</option>
                                        <option value="961">Lebanon (+961)</option>
                                        <option value="266">Lesotho (+266)</option>
                                        <option value="231">Liberia (+231)</option>
                                        <option value="218">Libya (+218)</option>
                                        <option value="417">Liechtenstein (+417)</option>
                                        <option value="370">Lithuania (+370)</option>
                                        <option value="352">Luxembourg (+352)</option>
                                        <option value="853">Macao (+853)</option>
                                        <option value="389">Macedonia (+389)</option>
                                        <option value="261">Madagascar (+261)</option>
                                        <option value="265">Malawi (+265)</option>
                                        <option value="60">Malaysia (+60)</option>
                                        <option value="960">Maldives (+960)</option>
                                        <option value="223">Mali (+223)</option>
                                        <option value="356">Malta (+356)</option>
                                        <option value="692">Marshall Islands (+692)</option>
                                        <option value="596">Martinique (+596)</option>
                                        <option value="222">Mauritania (+222)</option>
                                        <option value="269">Mayotte (+269)</option>
                                        <option value="52">Mexico (+52)</option>
                                        <option value="691">Micronesia (+691)</option>
                                        <option value="373">Moldova (+373)</option>
                                        <option value="377">Monaco (+377)</option>
                                        <option value="976">Mongolia (+976)</option>
                                        <option value="1664">Montserrat (+1664)</option>
                                        <option value="212">Morocco (+212)</option>
                                        <option value="258">Mozambique (+258)</option>
                                        <option value="95">Myanmar (+95)</option>
                                        <option value="264">Namibia (+264)</option>
                                        <option value="674">Nauru (+674)</option>
                                        <option value="977">Nepal (+977)</option>
                                        <option value="31">Netherlands (+31)</option>
                                        <option value="687">New Caledonia (+687)</option>
                                        <option value="64">New Zealand (+64)</option>
                                        <option value="505">Nicaragua (+505)</option>
                                        <option value="227">Niger (+227)</option>
                                        <option value="234">Nigeria (+234)</option>
                                        <option value="683">Niue (+683)</option>
                                        <option value="672">Norfolk Islands (+672)</option>
                                        <option value="670">Northern Marianas (+670)</option>
                                        <option value="47">Norway (+47)</option>
                                        <option value="968">Oman (+968)</option>
                                        <option value="680">Palau (+680)</option>
                                        <option value="507">Panama (+507)</option>
                                        <option value="675">Papua New Guinea (+675)</option>
                                        <option value="595">Paraguay (+595)</option>
                                        <option value="51">Peru (+51)</option>
                                        <option value="63">Philippines (+63)</option>
                                        <option value="48">Poland (+48)</option>
                                        <option value="351">Portugal (+351)</option>
                                        <option value="1787">Puerto Rico (+1787)</option>
                                        <option value="974">Qatar (+974)</option>
                                        <option value="262">Reunion (+262)</option>
                                        <option value="40">Romania (+40)</option>
                                        <option value="7">Russia (+7)</option>
                                        <option value="250">Rwanda (+250)</option>
                                        <option value="378">San Marino (+378)</option>
                                        <option value="239">Sao Tome &amp; Principe
                                            (+239)
                                        </option>
                                        <option value="966">Saudi Arabia (+966)</option>
                                        <option value="221">Senegal (+221)</option>
                                        <option value="381">Serbia (+381)</option>
                                        <option value="248">Seychelles (+248)</option>
                                        <option value="232">Sierra Leone (+232)</option>
                                        <option value="65">Singapore (+65)</option>
                                        <option value="421">Slovak Republic (+421)</option>
                                        <option value="386">Slovenia (+386)</option>
                                        <option value="677">Solomon Islands (+677)</option>
                                        <option value="252">Somalia (+252)</option>
                                        <option value="27">South Africa (+27)</option>
                                        <option value="34">España (+34)</option>
                                        <option value="94">Sri Lanka (+94)</option>
                                        <option value="290">St. Helena (+290)</option>
                                        <option value="1869">St. Kitts (+1869)</option>
                                        <option value="1758">St. Lucia (+1758)</option>
                                        <option value="249">Sudan (+249)</option>
                                        <option value="597">Suriname (+597)</option>
                                        <option value="268">Swaziland (+268)</option>
                                        <option value="46">Sweden (+46)</option>
                                        <option value="41">Switzerland (+41)</option>
                                        <option value="963">Syria (+963)</option>
                                        <option value="886">Taiwan (+886)</option>
                                        <option value="7">Tajikstan (+7)</option>
                                        <option value="66">Thailand (+66)</option>
                                        <option value="228">Togo (+228)</option>
                                        <option value="676">Tonga (+676)</option>
                                        <option value="1868">Trinidad &amp; Tobago
                                            (+1868)
                                        </option>
                                        <option value="216">Tunisia (+216)</option>
                                        <option value="90">Turkey (+90)</option>
                                        <option value="7">Turkmenistan (+7)</option>
                                        <option value="993">Turkmenistan (+993)</option>
                                        <option value="1649">Turks &amp; Caicos Islands (+1649)
                                        </option>
                                        <option value="688">Tuvalu (+688)</option>
                                        <option value="256">Uganda (+256)</option>
                                        <option value="380">Ukraine (+380)</option>
                                        <option value="971">United Arab Emirates
                                            (+971)
                                        </option>
                                        <option value="598">Uruguay (+598)</option>
                                        <option value="7">Uzbekistan (+7)</option>
                                        <option value="678">Vanuatu (+678)</option>
                                        <option value="379">Vatican City (+379)</option>
                                        <option value="58">Venezuela (+58)</option>
                                        <option value="84">Vietnam (+84)</option>
                                        <option value="84">Virgin Islands - British
                                            (+1284)
                                        </option>
                                        <option value="84">Virgin Islands - US
                                            (+1340)
                                        </option>
                                        <option value="681">Wallis &amp; Futuna
                                            (+681)
                                        </option>
                                        <option value="969">Yemen (North)(+969)</option>
                                        <option value="967">Yemen (South)(+967)</option>
                                        <option value="260">Zambia (+260)</option>
                                        <option value="263">Zimbabwe (+263)</option>
                                    </Select>


                                    <FormHelperText>
                                        {errors.prefijo_tlf && errors.prefijo_tlf.message}
                                    </FormHelperText>
                                </FormControl>
                            </div>
                            <div className="w-33 d-flex align-items-center pb-0 pl-0 Padding-movil"
                                 style={{position: 'relative'}}>
                                <TextField name="tlf" defaultValue={state.user.tlf} autoFocus fullWidth
                                           margin="normal" className="mt-0"
                                           label={t('tlf')}
                                           inputRef={register({
                                               pattern: /[0-9]{6,10}/,
                                               maxLength: 9
                                           })}/>


                                <FormHelperText style={{position: 'absolute', bottom: '-10px', color: 'red'}}>
                                    {errors.tlf &&
                                    errors.tlf.type === "pattern" &&
                                    "Debe de tener formato tel" +
                                    "éfono"}
                                    {errors.tlf &&
                                    errors.tlf.type === "maxLength" &&
                                    "Debe de tener formato tel" +
                                    "éfono"}
                                </FormHelperText>
                            </div>
                            <div className="w-33 d-flex align-items-center pb-0 pl-0 Padding-movil"
                                 style={{position: 'relative'}}>
                                <TextField name="confirmar_tlf" autoFocus fullWidth margin="normal" className="mt-0"
                                           label="Confirmar teléfono" defaultValue={state.user.tlf}
                                           inputRef={register({
                                               validate: (value) => value === watch('tlf') || "El teléfono no coincide"
                                           })}/>
                                <FormHelperText style={{position: 'absolute', bottom: '-10px', color: 'red'}}>
                                    {errors.confirmar_tlf && errors.confirmar_tlf.message}
                                </FormHelperText>
                            </div>

                        </div>
                        <div className="Row-flex">

                            <FormControl fullWidth error={Boolean(errors.idioma)}>
                                <InputLabel>
                                    Idioma *
                                </InputLabel>

                                <Controller
                                    as={
                                        <Select native>
                                            <option value="es">
                                                Español
                                            </option>
                                            <option value="ca">
                                                Catalán
                                            </option>
                                        </Select>
                                    }
                                    name="idioma"
                                    rules={{required: "Campo obligatorio"}}
                                    control={control}
                                    defaultValue={state.user.idioma} fullWidth
                                />
                                <FormHelperText>
                                    {errors.idioma && errors.idioma.message}
                                </FormHelperText>
                            </FormControl>
                        </div>
                        {<p className="text-danger text-center">{errors_res}</p>}

                        <div className="Row-flex">
                            <Link to="/home"
                                  className="d-flex align-items-center justify-content-center Btn Btn_gray d-block mb-4 mb-md-0 w-m-100"><span>Cancelar
  </span></Link>
                            <button className="Btn Btn_green ml-auto w-m-100" type="submit">
                            <span>
                                {cargando ? <CircularProgress style={{'color': '#fff'}}/> : 'Guardar'}
                                </span>
                            </button>
                        </div>
                    </form>

                </div>
            </div>

        )
    }

    function renderFormParticular() {

        return (
            <div className="row">
                <div className="col-12 d-flex flex-column">
                    <h2 className="Subtitulo2  ">
                        {t('da')}
                    </h2>

                    <form key={1} onSubmit={handleSubmit(onSubmit)} className="Form-edit-user">
                        <div className="Row-flex Row_input_btn">

                            <TextField name="nif" autoFocus fullWidth
                                       margin="normal"
                                       label="NIF/PASAPORTE/NIE"
                                       variant="outlined" defaultValue={state.user.n_documento}
                                       disabled/>
                        </div>


                        <div className="Row-flex Row_input_btn">

                            {
                                renderCambiarPass()
                            }


                            <div
                                className=" Btn_gray Btn  d-flex align-items-center justify-content-center Cursor-pointer"
                                onClick={() => {
                                    if (activarCambiarPass) {
                                        setactivarCambiarPass(false)
                                    } else {
                                        setactivarCambiarPass(true)

                                    }
                                }}>                               {!activarCambiarPass ? t('modpass') : t('cancelar')}

                            </div>

                        </div>

                        <h2 className="Subtitulo2 M-form-titulo ">
                            {t('dp')}
                        </h2>

                        <div className="Row-flex">

                            <div className=" w-100-m d-flex align-items-center Padding-movil"
                                 style={{paddingLeft: "0"}}>

                                <TextField name="nombre" defaultValue={state.user.nombre} autoFocus fullWidth
                                           margin="normal" label={t('nombre')} variant="outlined"
                                           inputRef={register()} required/>
                            </div>
                            <div className=" w-100-m d-flex align-items-center Padding-movil"
                                 style={{paddingLeft: "0"}}>

                                <TextField name="apellidos" defaultValue={state.user.apellidos} autoFocus fullWidth
                                           margin="normal"
                                           label={t('apellidos')}
                                           variant="outlined"
                                           inputRef={register()} required/>

                            </div>
                        </div>


                        <div className="Row-flex Row_input_btn">
                            {
                                renderCambiarMail()
                            }


                            <div
                                className="  Btn_gray Btn  d-flex align-items-center justify-content-center Cursor-pointer"
                                onClick={() => {
                                    if (activarCambiarMail) {
                                        setactivarCambiarMail(false)
                                    } else {
                                        setactivarCambiarMail(true)

                                    }
                                }}>
                                {!activarCambiarMail ? 'Modificar Email' : t('cancelar')}

                            </div>

                        </div>

                        <div className="Row-flex Row_input_btn">

                            <FormControl fullWidth error={Boolean(errors.perfil_habitual)}>
                                <InputLabel>
                                    {t('ph')}
                                </InputLabel>

                                <Controller
                                    as={
                                        <Select native>
                                            <option value="consumidor">
                                                {t('perfil1')}
                                            </option>
                                            <option value="instalador">
                                                {t('perfil2')}
                                            </option>
                                            <option value="productor">
                                                {t('perfil3')}
                                            </option>
                                        </Select>
                                    }
                                    name="perfil_habitual"
                                    rules={{required: "Campo obligatorio"}}
                                    control={control}
                                    defaultValue={state.user.perfil_habitual} fullWidth
                                />
                                <FormHelperText>
                                    {errors.perfil_habitual && errors.perfil_habitual.message}
                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div className="Row-flex ">
                            <div className="w-33 d-flex align-items-center pb-0 pl-0 Padding-movil"
                            >
                                <FormControl fullWidth error={Boolean(errors.prefijo_tlf)}>
                                    <InputLabel>
                                        {t('pt')}
                                    </InputLabel>

                                    <Select inputRef={register()} fullWidth name="prefijo_tlf" native
                                            defaultValue={state.user.prefijo_tlf}>
                                        <option value="34">España (+34)</option>
                                        <option value="44">UK (+44)</option>
                                        <option value="1">USA (+1)</option>
                                        <option value="213">Algeria (+213)</option>
                                        <option value="376">Andorra (+376)</option>
                                        <option value="244">Angola (+244)</option>
                                        <option value="1264">Anguilla (+1264)</option>
                                        <option value="1268">Antigua &amp; Barbuda
                                            (+1268)
                                        </option>
                                        <option value="54">Argentina (+54)</option>
                                        <option value="374">Armenia (+374)</option>
                                        <option value="297">Aruba (+297)</option>
                                        <option value="61">Australia (+61)</option>
                                        <option value="43">Austria (+43)</option>
                                        <option value="994">Azerbaijan (+994)</option>
                                        <option value="1242">Bahamas (+1242)</option>
                                        <option value="973">Bahrain (+973)</option>
                                        <option value="880">Bangladesh (+880)</option>
                                        <option value="1246">Barbados (+1246)</option>
                                        <option value="375">Belarus (+375)</option>
                                        <option value="32">Belgium (+32)</option>
                                        <option value="501">Belize (+501)</option>
                                        <option value="229">Benin (+229)</option>
                                        <option value="1441">Bermuda (+1441)</option>
                                        <option value="975">Bhutan (+975)</option>
                                        <option value="591">Bolivia (+591)</option>
                                        <option value="387">Bosnia Herzegovina (+387)</option>
                                        <option value="267">Botswana (+267)</option>
                                        <option value="55">Brazil (+55)</option>
                                        <option value="673">Brunei (+673)</option>
                                        <option value="359">Bulgaria (+359)</option>
                                        <option value="226">Burkina Faso (+226)</option>
                                        <option value="257">Burundi (+257)</option>
                                        <option value="855">Cambodia (+855)</option>
                                        <option value="237">Cameroon (+237)</option>
                                        <option value="1">Canada (+1)</option>
                                        <option value="238">Cape Verde Islands (+238)</option>
                                        <option value="1345">Cayman Islands (+1345)</option>
                                        <option value="236">Central African Republic
                                            (+236)
                                        </option>
                                        <option value="56">Chile (+56)</option>
                                        <option value="86">China (+86)</option>
                                        <option value="57">Colombia (+57)</option>
                                        <option value="269">Comoros (+269)</option>
                                        <option value="242">Congo (+242)</option>
                                        <option value="682">Cook Islands (+682)</option>
                                        <option value="506">Costa Rica (+506)</option>
                                        <option value="385">Croatia (+385)</option>
                                        <option value="53">Cuba (+53)</option>
                                        <option value="90392">Cyprus North (+90392)</option>
                                        <option value="357">Cyprus South (+357)</option>
                                        <option value="42">Czech Republic (+42)</option>
                                        <option value="45">Denmark (+45)</option>
                                        <option value="253">Djibouti (+253)</option>
                                        <option value="1809">Dominica (+1809)</option>
                                        <option value="1809">Dominican Republic
                                            (+1809)
                                        </option>
                                        <option value="593">Ecuador (+593)</option>
                                        <option value="20">Egypt (+20)</option>
                                        <option value="503">El Salvador (+503)</option>
                                        <option value="240">Equatorial Guinea (+240)</option>
                                        <option value="291">Eritrea (+291)</option>
                                        <option value="372">Estonia (+372)</option>
                                        <option value="251">Ethiopia (+251)</option>
                                        <option value="500">Falkland Islands (+500)</option>
                                        <option value="298">Faroe Islands (+298)</option>
                                        <option value="679">Fiji (+679)</option>
                                        <option value="358">Finlandia (+358)</option>
                                        <option value="33">Francia (+33)</option>
                                        <option value="594">French Guiana (+594)</option>
                                        <option value="689">French Polynesia (+689)</option>
                                        <option value="241">Gabon (+241)</option>
                                        <option value="220">Gambia (+220)</option>
                                        <option value="7880">Georgia (+7880)</option>
                                        <option value="49">Germany (+49)</option>
                                        <option value="233">Ghana (+233)</option>
                                        <option value="350">Gibraltar (+350)</option>
                                        <option value="30">Greece (+30)</option>
                                        <option value="299">Greenland (+299)</option>
                                        <option value="1473">Grenada (+1473)</option>
                                        <option value="590">Guadeloupe (+590)</option>
                                        <option value="671">Guam (+671)</option>
                                        <option value="502">Guatemala (+502)</option>
                                        <option value="224">Guinea (+224)</option>
                                        <option value="245">Guinea - Bissau (+245)</option>
                                        <option value="592">Guyana (+592)</option>
                                        <option value="509">Haiti (+509)</option>
                                        <option value="504">Honduras (+504)</option>
                                        <option value="852">Hong Kong (+852)</option>
                                        <option value="36">Hungary (+36)</option>
                                        <option value="354">Iceland (+354)</option>
                                        <option value="91">India (+91)</option>
                                        <option value="62">Indonesia (+62)</option>
                                        <option value="98">Iran (+98)</option>
                                        <option value="964">Iraq (+964)</option>
                                        <option value="353">Ireland (+353)</option>
                                        <option value="972">Israel (+972)</option>
                                        <option value="39">Italia (+39)</option>
                                        <option value="1876">Jamaica (+1876)</option>
                                        <option value="81">Japan (+81)</option>
                                        <option value="962">Jordan (+962)</option>
                                        <option value="7">Kazakhstan (+7)</option>
                                        <option value="254">Kenya (+254)</option>
                                        <option value="686">Kiribati (+686)</option>
                                        <option value="850">Korea North (+850)</option>
                                        <option value="82">Korea South (+82)</option>
                                        <option value="965">Kuwait (+965)</option>
                                        <option value="996">Kyrgyzstan (+996)</option>
                                        <option value="856">Laos (+856)</option>
                                        <option value="371">Latvia (+371)</option>
                                        <option value="961">Lebanon (+961)</option>
                                        <option value="266">Lesotho (+266)</option>
                                        <option value="231">Liberia (+231)</option>
                                        <option value="218">Libya (+218)</option>
                                        <option value="417">Liechtenstein (+417)</option>
                                        <option value="370">Lithuania (+370)</option>
                                        <option value="352">Luxembourg (+352)</option>
                                        <option value="853">Macao (+853)</option>
                                        <option value="389">Macedonia (+389)</option>
                                        <option value="261">Madagascar (+261)</option>
                                        <option value="265">Malawi (+265)</option>
                                        <option value="60">Malaysia (+60)</option>
                                        <option value="960">Maldives (+960)</option>
                                        <option value="223">Mali (+223)</option>
                                        <option value="356">Malta (+356)</option>
                                        <option value="692">Marshall Islands (+692)</option>
                                        <option value="596">Martinique (+596)</option>
                                        <option value="222">Mauritania (+222)</option>
                                        <option value="269">Mayotte (+269)</option>
                                        <option value="52">Mexico (+52)</option>
                                        <option value="691">Micronesia (+691)</option>
                                        <option value="373">Moldova (+373)</option>
                                        <option value="377">Monaco (+377)</option>
                                        <option value="976">Mongolia (+976)</option>
                                        <option value="1664">Montserrat (+1664)</option>
                                        <option value="212">Morocco (+212)</option>
                                        <option value="258">Mozambique (+258)</option>
                                        <option value="95">Myanmar (+95)</option>
                                        <option value="264">Namibia (+264)</option>
                                        <option value="674">Nauru (+674)</option>
                                        <option value="977">Nepal (+977)</option>
                                        <option value="31">Netherlands (+31)</option>
                                        <option value="687">New Caledonia (+687)</option>
                                        <option value="64">New Zealand (+64)</option>
                                        <option value="505">Nicaragua (+505)</option>
                                        <option value="227">Niger (+227)</option>
                                        <option value="234">Nigeria (+234)</option>
                                        <option value="683">Niue (+683)</option>
                                        <option value="672">Norfolk Islands (+672)</option>
                                        <option value="670">Northern Marianas (+670)</option>
                                        <option value="47">Norway (+47)</option>
                                        <option value="968">Oman (+968)</option>
                                        <option value="680">Palau (+680)</option>
                                        <option value="507">Panama (+507)</option>
                                        <option value="675">Papua New Guinea (+675)</option>
                                        <option value="595">Paraguay (+595)</option>
                                        <option value="51">Peru (+51)</option>
                                        <option value="63">Philippines (+63)</option>
                                        <option value="48">Poland (+48)</option>
                                        <option value="351">Portugal (+351)</option>
                                        <option value="1787">Puerto Rico (+1787)</option>
                                        <option value="974">Qatar (+974)</option>
                                        <option value="262">Reunion (+262)</option>
                                        <option value="40">Romania (+40)</option>
                                        <option value="7">Russia (+7)</option>
                                        <option value="250">Rwanda (+250)</option>
                                        <option value="378">San Marino (+378)</option>
                                        <option value="239">Sao Tome &amp; Principe
                                            (+239)
                                        </option>
                                        <option value="966">Saudi Arabia (+966)</option>
                                        <option value="221">Senegal (+221)</option>
                                        <option value="381">Serbia (+381)</option>
                                        <option value="248">Seychelles (+248)</option>
                                        <option value="232">Sierra Leone (+232)</option>
                                        <option value="65">Singapore (+65)</option>
                                        <option value="421">Slovak Republic (+421)</option>
                                        <option value="386">Slovenia (+386)</option>
                                        <option value="677">Solomon Islands (+677)</option>
                                        <option value="252">Somalia (+252)</option>
                                        <option value="27">South Africa (+27)</option>
                                        <option value="34">España (+34)</option>
                                        <option value="94">Sri Lanka (+94)</option>
                                        <option value="290">St. Helena (+290)</option>
                                        <option value="1869">St. Kitts (+1869)</option>
                                        <option value="1758">St. Lucia (+1758)</option>
                                        <option value="249">Sudan (+249)</option>
                                        <option value="597">Suriname (+597)</option>
                                        <option value="268">Swaziland (+268)</option>
                                        <option value="46">Sweden (+46)</option>
                                        <option value="41">Switzerland (+41)</option>
                                        <option value="963">Syria (+963)</option>
                                        <option value="886">Taiwan (+886)</option>
                                        <option value="7">Tajikstan (+7)</option>
                                        <option value="66">Thailand (+66)</option>
                                        <option value="228">Togo (+228)</option>
                                        <option value="676">Tonga (+676)</option>
                                        <option value="1868">Trinidad &amp; Tobago
                                            (+1868)
                                        </option>
                                        <option value="216">Tunisia (+216)</option>
                                        <option value="90">Turkey (+90)</option>
                                        <option value="7">Turkmenistan (+7)</option>
                                        <option value="993">Turkmenistan (+993)</option>
                                        <option value="1649">Turks &amp; Caicos Islands (+1649)
                                        </option>
                                        <option value="688">Tuvalu (+688)</option>
                                        <option value="256">Uganda (+256)</option>
                                        <option value="380">Ukraine (+380)</option>
                                        <option value="971">United Arab Emirates
                                            (+971)
                                        </option>
                                        <option value="598">Uruguay (+598)</option>
                                        <option value="7">Uzbekistan (+7)</option>
                                        <option value="678">Vanuatu (+678)</option>
                                        <option value="379">Vatican City (+379)</option>
                                        <option value="58">Venezuela (+58)</option>
                                        <option value="84">Vietnam (+84)</option>
                                        <option value="84">Virgin Islands - British
                                            (+1284)
                                        </option>
                                        <option value="84">Virgin Islands - US
                                            (+1340)
                                        </option>
                                        <option value="681">Wallis &amp; Futuna
                                            (+681)
                                        </option>
                                        <option value="969">Yemen (North)(+969)</option>
                                        <option value="967">Yemen (South)(+967)</option>
                                        <option value="260">Zambia (+260)</option>
                                        <option value="263">Zimbabwe (+263)</option>
                                    </Select>


                                    <FormHelperText>
                                        {errors.prefijo_tlf && errors.prefijo_tlf.message}
                                    </FormHelperText>
                                </FormControl>
                            </div>
                            <div className="w-33 d-flex align-items-center pb-0 pl-0 Padding-movil"
                                 style={{position: 'relative'}}>
                                <TextField name="tlf" defaultValue={state.user.tlf} autoFocus fullWidth
                                           margin="normal" className="mt-0"
                                           label={t('tlf')}
                                           inputRef={register({
                                               pattern: /[0-9]{6,10}/,
                                               maxLength: 9
                                           })}/>


                                <FormHelperText style={{position: 'absolute', bottom: '-10px', color: 'red'}}>
                                    {errors.tlf &&
                                    errors.tlf.type === "pattern" &&
                                    "Debe de tener formato tel" +
                                    "éfono"}
                                    {errors.tlf &&
                                    errors.tlf.type === "maxLength" &&
                                    "Debe de tener formato tel" +
                                    "éfono"}
                                </FormHelperText>
                            </div>
                            <div className="w-33 d-flex align-items-center pb-0 pl-0 Padding-movil"
                                 style={{position: 'relative'}}>
                                <TextField name="confirmar_tlf" autoFocus fullWidth margin="normal" className="mt-0"
                                           label="Confirmar teléfono" defaultValue={state.user.tlf}
                                           inputRef={register({
                                               validate: (value) => value === watch('tlf') || "El teléfono no coincide"
                                           })}/>
                                <FormHelperText style={{position: 'absolute', bottom: '-10px', color: 'red'}}>
                                    {errors.confirmar_tlf && errors.confirmar_tlf.message}
                                </FormHelperText>
                            </div>

                        </div>
                        <div className="">

                            <FormControl fullWidth error={Boolean(errors.idioma)}>
                                <InputLabel>
                                    Idioma *
                                </InputLabel>

                                <Controller
                                    as={
                                        <Select native>
                                            <option value="es">
                                                Español
                                            </option>
                                            <option value="ca">
                                                Catalán
                                            </option>
                                        </Select>
                                    }
                                    name="idioma"
                                    rules={{required: "Campo obligatorio"}}
                                    control={control}
                                    defaultValue={state.user.idioma} fullWidth
                                />
                                <FormHelperText>
                                    {errors.idioma && errors.idioma.message}
                                </FormHelperText>
                            </FormControl>
                        </div>
                        {<p className="text-danger text-center">{errors_res}</p>}

                        <div className="Row-flex">
                            <Link to="/home"
                                  className="d-flex align-items-center justify-content-center Btn Btn_gray d-block mb-4 mb-md-0 w-m-100"><span>Cancelar
  </span></Link>

                            <button className="Btn Btn_green ml-md-auto w-m-100" type="submit">
                            <span>
                                {cargando ? <CircularProgress style={{'color': '#fff'}}/> : 'Guardar'}
                                </span>
                            </button>
                        </div>
                    </form>


                </div>
            </div>

        )

    }

    return (
        <>
            <RenderHeader/>

            <DivGrisTitulo tituloBienvenida={false} titulo={t('lmd')}/>


            <TransitionsModal titulo={t('modal1')} clase={'Clase-modal'} modalWidth={'80%'}
                              open={openModal_dni}
                              setopenModal_parent={setopenModal_dni}>
                <div className="pt-5">
                    <MyDropzone functionFileToForm={fileToForm_dni} inputName='file_dni'/>

                    <div className="container">
                        <button className="Btn Btn_green ml-auto my-4" type="button" onClick={editarImagenes}>
                            {cargandoEditar ? <CircularProgress style={{'color': '#fff'}}/> : 'Guardar'}

                        </button>
                        <span className="text-danger p-absolute ">{errors_res_editar}</span>
                        <span className="text-success p-absolute ">{ok_res_editar}</span>
                    </div>

                </div>
            </TransitionsModal>

            <TransitionsModal titulo={t('modal2')} modalWidth={'80%'}
                              open={openModal_cif}
                              setopenModal_parent={setopenModal_cif}>
                <div className="pt-5">

                    <MyDropzone functionFileToForm={fileToForm_cif} inputName='file_cif'/>
                    <div className="container">

                        <button className="Btn Btn_green ml-auto my-4" type="button" onClick={editarImagenes}>
                            {cargandoEditar ? <CircularProgress style={{'color': '#fff'}}/> : 'Guardar'}

                        </button>
                        <span className="text-danger p-absolute ">{errors_res_editar}</span>
                        <span className="text-success p-absolute ">{ok_res_editar}</span>
                    </div>

                </div>
            </TransitionsModal>

            <TransitionsModal titulo={t('modal3')}  modalWidth={'80%'}
                              open={openModal_apoderament}
                              setopenModal_parent={setopenModal_apoderament}>
                <div className="pt-5">

                    <MyDropzone functionFileToForm={fileToForm_apoderamiento} inputName='file_apoderamiento'/>
                    <div className="container">

                        <button className="Btn Btn_green ml-auto my-4" type="button" onClick={editarImagenes}>
                            {cargandoEditar ? <CircularProgress style={{'color': '#fff'}}/> : 'Guardar'}
                        </button>
                        <span className="text-danger p-absolute ">{errors_res_editar}</span>
                        <span className="text-success p-absolute ">{ok_res_editar}</span>
                    </div>
                </div>
            </TransitionsModal>

            <div className="container PerfilPage Padding-seccion-principal">
                {state.user.tipo == 'representante' ? renderFormRepresentante() : renderFormParticular()}
                <div className="Tabla-descarga-archivos">
                    <h2 className="Subtitulo2  mb-5 mt-3">

                        {t('docs')}
                    </h2>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow className="Bg-gray">
                                    <TableCell align="center">{t('arch')}</TableCell>
                                    <TableCell align="center">{t('imga')}</TableCell>
                                    <TableCell align="center">{t('acc')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        {t('di')}
                                    </TableCell>
                                    <TableCell align="center" className="Td-imgs">
                                        <div className="Div-img">

                                            {cargandoImgs
                                                ?
                                                <CircularProgress style={{'color': 'lightgray'}}/>
                                                :
                                                <>
                                                    {/*<FontAwesomeIcon title={t('descargar')} onClick={
                                                        () => {
                                                            downloadDoc(fileDocumentoIdentificativo, 'document-identificatiu')
                                                        }
                                                    } icon={faDownload} className="Ico-descargar"
                                                                     color="black"/>*/}

                                                    <img onClick={
                                                        () => {
                                                            downloadDoc(fileDocumentoIdentificativo, 'document-identificatiu')
                                                        }
                                                    } className="Thumbnail-tabla"
                                                         src={ !fileDocumentoIdentificativoB64_pdf ? 'data:image/png;base64,' + fileDocumentoIdentificativoB64 : imgpdf }/>
                                                </>
                                            }

                                        </div>
                                        {fileDocumentoIdentificativo2B64 &&
                                        <div className="Div-img">
                                            {cargandoImgs
                                                ?
                                                <CircularProgress style={{'color': 'lightgray'}}/>
                                                :
                                                <>
                                                   {/* <FontAwesomeIcon title={t('descargar')} onClick={
                                                        () => {
                                                            downloadDoc(fileDocumentoIdentificativo2, 'document-identificatiu')
                                                        }
                                                    } icon={faDownload} className="Ico-descargar"
                                                                     color="black"/>*/}
                                                    <img onClick={
                                                        () => {
                                                            downloadDoc(fileDocumentoIdentificativo2, 'document-identificatiu')
                                                        }
                                                    } className="Thumbnail-tabla"
                                                    src={ !fileDocumentoIdentificativo2B64_pdf ? 'data:image/png;base64,' + fileDocumentoIdentificativo2B64 : imgpdf }/>

                                                </>
                                            }
                                        </div>
                                        }
                                    </TableCell>

                                    <TableCell align="center">
                                        {/*    {fileDocumentoIdentificativo ? <button
                                            className="Btn-transparent-border-green  Btn-tabla-descargar"
                                            onClick={
                                                () => {
                                                    downloadDoc(fileDocumentoIdentificativo, 'document-identificatiu')
                                                }
                                            }>

                                            {t('descargar')}

                                        </button> : <span>{t('nd')}</span>}

                                        {fileDocumentoIdentificativo2 ? <button
                                            className="Btn-transparent-border-green  Btn-tabla-descargar"
                                            onClick={
                                                () => {
                                                    downloadDoc(fileDocumentoIdentificativo2, 'document-identificatiu')
                                                }
                                            }>

                                            {t('dsd')}


                                        </button> : null}*/}
                                        <button className="Btn-transparent-border-green  Btn-tabla-descargar"
                                                onClick={() => setopenModal_dni(true)}>

                                            Modificar
                                        </button>
                                    </TableCell>

                                </TableRow>

                                {state.user.tipo == 'representante' && <TableRow>
                                    <TableCell align="center">
                                        CIF
                                    </TableCell>
                                    <TableCell align="center" className="Td-imgs">
                                        <div className="Div-img">
                                            {cargandoImgs
                                                ?
                                                <CircularProgress style={{'color': 'lightgray'}}/>
                                                :
                                                <>
                                                    {/*<FontAwesomeIcon title={t('descargar')} onClick={
                                                        () => {
                                                            downloadDoc(fileCif, 'cif')
                                                        }
                                                    } icon={faDownload} className="Ico-descargar"
                                                                     color="black"/>*/}
                                                    <img onClick={
                                                        () => {
                                                            downloadDoc(fileCif, 'cif')
                                                        }
                                                    } className="Thumbnail-tabla"
                                                    src={ !fileCifB64_pdf ? 'data:image/png;base64,' + fileCifB64 : imgpdf }/>

                                                </>
                                            }
                                        </div>
                                        {fileCif2B64 &&
                                        <div className="Div-img">
                                            {cargandoImgs
                                                ?
                                                <CircularProgress style={{'color': 'lightgray'}}/>
                                                :
                                                <>
                                                  {/*  <FontAwesomeIcon title={t('descargar')} onClick={
                                                        () => {
                                                            downloadDoc(fileCif2, 'cif')
                                                        }
                                                    } icon={faDownload} className="Ico-descargar"
                                                                     color="black"/>*/}
                                                    <img onClick={
                                                        () => {
                                                            downloadDoc(fileCif2, 'cif')
                                                        }
                                                    } className="Thumbnail-tabla"
                                                    src={ !fileCif2B64_pdf ? 'data:image/png;base64,' + fileCif2B64 : imgpdf }/>

                                                </>
                                            }
                                        </div>
                                        }
                                    </TableCell>

                                    <TableCell align="center">
                                        {/*    {fileCif ? <button
                                            className="Btn-transparent-border-green  Btn-tabla-descargar"
                                            onClick={
                                                () => {
                                                    downloadDoc(fileCif, 'cif')
                                                }
                                            }>

                                            {t('descargar')}

                                        </button> : <span>{t('nd')}</span>}

                                        {fileCif2 ? <button
                                            className="Btn-transparent-border-green  Btn-tabla-descargar"
                                            onClick={
                                                () => {
                                                    downloadDoc(fileCif2, 'cif')
                                                }
                                            }>

                                            {t('dsd')}

                                        </button> : null}*/}
                                        <button className="Btn-transparent-border-green  Btn-tabla-descargar"
                                                onClick={() => setopenModal_cif(true)}>Modificar
                                        </button>

                                    </TableCell>

                                </TableRow>}
                                {state.user.tipo == 'representante' && <TableRow>
                                    <TableCell align="center">
                                        {t('apoderament')}
                                    </TableCell>
                                    <TableCell align="center" className="Td-imgs">
                                        <div className="Div-img">
                                            {cargandoImgs
                                                ?
                                                <CircularProgress style={{'color': 'lightgray'}}/>
                                                :
                                                <>
                                                   {/* <FontAwesomeIcon title={t('descargar')} onClick={
                                                        () => {
                                                            downloadDoc(fileApoderamiento, 'apoderament')
                                                        }
                                                    } icon={faDownload} className="Ico-descargar"
                                                                     color="black"/>*/}
                                                    <img onClick={
                                                        () => {
                                                            downloadDoc(fileApoderamiento, 'apoderament')
                                                        }
                                                    }
                                                         className="Thumbnail-tabla"
                                                         src={ !fileApoderamientoB64_pdf ? 'data:image/png;base64,' + fileApoderamientoB64 : imgpdf }/>

                                                </>}
                                        </div>
                                        {fileApoderamiento2B64 &&
                                        <div className="Div-img">
                                            {cargandoImgs
                                                ?
                                                <CircularProgress style={{'color': 'lightgray'}}/>
                                                :
                                                <>
                                                    {/*<FontAwesomeIcon title={t('descargar')} onClick={
                                                        () => {
                                                            downloadDoc(fileApoderamiento2, 'apoderament')
                                                        }
                                                    } icon={faDownload} className="Ico-descargar"
                                                                     color="black"/>*/}
                                                    <img onClick={
                                                        () => {
                                                            downloadDoc(fileApoderamiento2, 'apoderament')
                                                        }
                                                    }
                                                        className="Thumbnail-tabla"
                                                         src={ !fileApoderamiento2B64_pdf ? 'data:image/png;base64,' + fileApoderamiento2B64 : imgpdf }/>

                                                </>}
                                        </div>
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {/*    {fileApoderamiento ? <button
                                            className="Btn-transparent-border-green  Btn-tabla-descargar"
                                            onClick={
                                                () => {
                                                    downloadDoc(fileApoderamiento, 'apoderament')
                                                }
                                            }>

                                            {t('descargar')}

                                        </button> : <span>{t('nd')}</span>}

                                        {fileApoderamiento ? <button
                                            className="Btn-transparent-border-green  Btn-tabla-descargar"
                                            onClick={
                                                () => {
                                                    downloadDoc(fileApoderamiento, 'apoderament')
                                                }
                                            }>

                                            {t('dsd')}

                                        </button> : null}*/}
                                        <button className="Btn-transparent-border-green  Btn-tabla-descargar"
                                                onClick={() => setopenModal_apoderament(true)}>Modificar
                                        </button>

                                    </TableCell>

                                </TableRow>}


                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

            </div>

        </>
    );
}

export default withRouter(PerfilPage);