import React, {useContext} from "react";
import "./style.scss";
import CardMenu from "./card-menu";
import {AuthDataContext} from "../../context/auth-context";
import wifi from "../../img/wifi-a.png";
import potencia from "../../img/grafico-a.png";
import consum from "../../img/casa-a.png";
import DivGrisTitulo from "../common-components/seccion-titulo";
import RenderHeader from "../common-components/header";
import {useTranslation} from "react-i18next";

function Home(props) {
    const {state} = useContext(AuthDataContext);
    const { t, i18n } = useTranslation();
    return (
        <>
            <RenderHeader/>

            <DivGrisTitulo tituloBienvenida={false} titulo={t('bienvenidoh')} />
            <div className=" container Padding-seccion-principal px-0">

                <div className="row">
                    <div className="col-12  d-flex flex-wrap">
                        <CardMenu link={'/mi-consumo'} title={t('consum')}
                                  description={t('consum1')}>
                            <img src={consum}   className="Ico-g"  alt='ico' />

                        </CardMenu>
                        <CardMenu link={'/potencia-maxima'}  title={t('potencia_maxima')}
                                  description={t('potenica1')}>
                            <img src={potencia}  className="Ico-g"  alt='ico' />

                        </CardMenu>
                        <CardMenu title={t('contador')} link={'/contador-online'} description={t('contador1')}>
                           <img src={wifi} className="Ico-g"  alt='ico' />
                        </CardMenu>


                    </div>
                   {/* <div className="col-12 col-md-4 d-flex flex-column">
                        <div className="d-flex flex-column">
                            <img src="https://via.placeholder.com/500x500" className="img-fluid" alt="contador"/>
                            <div className="text-center py-4 Bg-blue">
                                <p className="text-white w-75 text-center mx-auto">Consulta el teu consumenergètic per
                                    data i trams horaris </p>
                                <Link to='/' className="text-white">VEURE EL SERVEI</Link>
                            </div>

                            <hr/>
                            <div className=" Inferior-Contador py-2">
                                <h6 className="Txt-green">A un clic</h6>
                                <Link to='/' className="Txt-green ">
                                    <FontAwesomeIcon icon={faAngleRight}/>
                                    Conèixer l'estat del teu subministrament</Link>

                            </div>

                            <hr/>

                            <div className=" Inferior-Contador py-2">
                                <h6 className="Txt-green">Gestiona el teu perfil</h6>
                                <Link to='/perfil' className="Txt-green">
                                    <FontAwesomeIcon icon={faAngleRight}/>
                                    Verificar les teves dades de perfil</Link>
                                <Link to='/' className="Txt-green">
                                    <FontAwesomeIcon icon={faAngleRight}/>
                                    Verificar les teves dades de perfil</Link>

                            </div>
                        </div>
                    </div>*/}
                </div>
            </div>

        </>
    );
}

export default Home;
