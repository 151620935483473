import React, {useEffect} from 'react';
import moment from "moment";
import { useTranslation } from 'react-i18next';
import {ResponsiveBar} from "@nivo/bar";
import {ResponsiveLine} from "@nivo/line";
import {intToHour} from "../../../helpers/generales";



export function ChartPotenciaMaximaMesLineal(props) {
    const { t } = useTranslation();

    useEffect(()=>{

    },[props.data])
    return (
        <ResponsiveLine
            data={props.data}
            margin={{top: 50, right: 110, bottom: 50, left: 60}}
            xScale={{
                type: "time",
                format: "%Y-%m"
            }}
            xFormat="time:%Y-%m"
            yScale={{
                type: "linear",
                min: 0,
                max: "auto",
                stacked: false,
                reverse: false
            }}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            curve="monotoneX"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickSize: 11,
                tickPadding: 2,
                tickRotation: -25,
                legend: t('meses'),
                legendOffset: 45,
                legendPosition: 'middle',
                format: "%m-%Y",
                tickValues:"every month"
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: d => `${d.toString().replace('.',',')}`,//para ponerle la ,
                legend: 'kW',
                legendOffset: -50,
                legendPosition: 'middle'
            }}
            tooltip={e => {

                return (
                    <div
                        style={{
                            background: "white",
                            padding: "9px 12px",
                            borderLeft: "3px solid #060155",
                            /*borderLeft: e.point.data.facturada ? "3px solid #060155" : "3px solid red",*/
                            color: "#000",
                            fontSize: "17px",
                            textAlign:'left',
                        }}
                    >

                        <span style={{'fontWeight':'600','display':'block','textAlign':'left'}}>{(e.point.data.y).toString().replace('.',',')}kW</span>


                        <small style={{'display':'block','textAlign':'left'}}> {moment(e.point.data.xFormatted).format('MM-YYYY')}</small>
                        <small style={{'display':'block','textAlign':'left'}}>   <span style={{'textTransform':'capitalize'}}><b> Max. </b></span>: {e.point.data.maximetre}</small>

                        {/* <br/>
                        <span> Consum Real</span>*/}
                        {/*  <br/>

                        {e.point.data.facturada ? <span> Facturada</span> : <span>NO Facturada</span>}
*/}
                    </div>
                );
            }}

            /* colors={['#060155',"rgba(107,167,156,0.85)","rgba(0,156,159,0.75)","rgba(30,30,30,0.8)","rgba(133,132,133,0.98)","rgba(6,1,85,0.58)"]}
           */
            colors={d => d.color}

            pointSize={11}

            pointBorderWidth={1}

            pointLabelYOffset={0}
            enableArea={false}
            areaOpacity={0.1}
            areaBaselineValue={0}
            useMesh={true}
        />
    )
}

export function ChartPotenciaMaximaMes(props) {
    const potencia_contratada = props.data[0].potencia_contratada
    const { t } = useTranslation();

    return(
       <ResponsiveBar
           data={props.data[0].data}


           keys={['energia' ]}
           indexBy="anyo_mes"
           margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
           padding={0.3}
           colors={['rgba(6,1,85)']}
           borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
           axisTop={null}
           axisRight={null}
           axisBottom={{
               tickSize: 5,
               tickPadding: 5,
               tickRotation: 0,
               legend: 'Mes',
               legendPosition: 'middle',
               legendOffset: 32
           }}
           axisLeft={{
               tickSize: 5,
               tickPadding: 5,
               tickRotation: 0,
               legend: 'kW',
               legendPosition: 'middle',
               legendOffset: -40
           }}
       /*   markers={[
               {
                   axis: 'y',
                   value: potencia_contratada,
                   lineStyle: { stroke: '#6ba79c', strokeWidth: 2 },
                   legend: 'Potencia contratada '+potencia_contratada+' kW',
                   textStyle: {
                       fill: '#6ba79c',
                   },
               },


           ]}*/
           maxValue={potencia_contratada}
           tooltip={e => {
               return (
                   <div
                       style={{
                           background: "white",
                           padding: "9px 12px",
                           borderLeft: e.data.energia>e.data.potencia_contratada ? "3px solid red" : "3px solid #060155",
                           color: "#000",
                           fontSize: "17px",
                           textAlign:'left',
                       }}
                   >

                       <span style={{'fontWeight':'600','display':'block','textAlign':'left'}}>{(e.data.energia).toString().replace('.',',')}kW</span>
                       <small style={{'display':'block','textAlign':'left'}}><b>{t('pc')}: {(e.data.potencia_contratada).toString().replace('.',',')}{e.data.potencia_contratada!='No definida' && 'kW'}</b></small>
                       <small style={{'display':'block','textAlign':'left'}}> {moment(e.data.fecha_completa).format('DD-MM-YYYY')} - {intToHour(e.data.hora)} </small>
                       {/* <br/>
                        <span> Consum Real</span>*/}
                       {/*  <br/>

                        {e.data.facturada ? <span> Facturada</span> : <span>NO Facturada</span>}
*/}
                   </div>
               );
           }}

           enableLabel={false}
           labelSkipWidth={12}
           labelSkipHeight={12}
           labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
           animate={true}
           motionStiffness={90}
           motionDamping={15}
       />
      )
}