import React, {useContext,useState} from 'react';
import {Select, InputLabel, MenuItem, FormControl} from '@material-ui/core';
import {useForm} from "react-hook-form";
import FormHelperText from "@material-ui/core/FormHelperText";
import './style.scss';
import Button from "@material-ui/core/Button";
import {SignUpFormContext} from "../../../context/signup-form-context";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';

function RegisterFormStep1(props) {


    const {register, errors, handleSubmit} = useForm();

    const {state, dispatch} = useContext(SignUpFormContext);

    const [formSubmited, setFormSubmited] = useState(false);
    const { t, i18n } = useTranslation();



    const onSubmit = data => {

        setFormSubmited(true) // para mostrar errores de selects una vez hecho el submit

        if(!checkEmptySelects()){
            data.step = 2//lo paso a la pos 2 del step
            dispatch({
                type: 'update_form_value',
                data
            });
        }


    }

    const checkEmptySelects = () => { //porque el required para selects no funciona con el material ui select
        if(state.tipo=='' || state.perfil_habitual==''){
            return true;
        }else{
            return false;
        }
    }

    /*le paso por dispatch un objeto a partir del name y el value del input
    * y actualizo el state en el context a partir de estos valores */
    const handleChange = (e) => {
        let data = {
            [e.target.name]: e.target.value
        };
        dispatch({
            type: 'update_form_value',
            data
        });
    }


    return (
        <>
            <Typography component="h3" variant="h4" className="mt-3 text-center">
                {t('seleccionar_perfil')}
            </Typography>
            <Typography component="p" variant="subtitle1" className="my-4 text-center d-block ">
                {t('estamos_trabajando')}
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)} className="Form-signup">

                <FormControl className="mt-2" fullWidth error={Boolean(errors.perfil_habitual)}>
                    <InputLabel>
                        {t('ph')}
                    </InputLabel>

                    <Select required onChange={handleChange} name="perfil_habitual"  fullWidth
                            value={state.perfil_habitual}>

                        <MenuItem value="consumidor">
                            {t('perfil1')}
                        </MenuItem>
                        <MenuItem value="instalador">
                            {t('perfil2')}
                        </MenuItem>
                        <MenuItem value="productor">
                            {t('perfil3')}
                        </MenuItem>

                    </Select>
                    <FormHelperText className="text-danger">
                        { (!state.perfil_habitual && formSubmited) && t('sel')}
                    </FormHelperText>
                </FormControl>
                <FormControl className="mt-3" fullWidth error={Boolean(errors.tipo)}>
                    <InputLabel>
                       {t('prp')}
                    </InputLabel>

                    <Select required onChange={handleChange} name="tipo"  fullWidth
                            value={state.tipo}>
                        <MenuItem value="particular">
                            Particular
                        </MenuItem>
                        <MenuItem value="representante">
                            {t('rp')}
                        </MenuItem>

                    </Select>

                    <FormHelperText className="text-danger">
                        { (!state.tipo && formSubmited) && t('sel')}
                    </FormHelperText>
                </FormControl>
                <button
                    type="submit"
                    className="Btn Btn_green mw-100 w-100 my-4 ml-auto w-m-100"
                >
                    {t('siguiente')}
                </button>
            </form>
        </>
    );
}

export default RegisterFormStep1;