import React, {useContext} from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {useAuthDataContext} from "../context/auth-context";
import Login from "./login-page";
import SignUp from "./signup-page";
import Home from "./home-page";
import {AuthDataContext} from "../context/auth-context";
import ElTeuConsum from "./consumo-page";
import GraficoPageConsumo from "./consumo-page/ver-grafica-page";
import {checkIfLogged} from "../helpers/auth-helpers";
import PerfilPage from "./perfil-page";
import ForgetPasswordCambiarPass from "./forget-password-page/recuperar-contrasenya-cambiar";
import ForgetPasswordSolicitarEmail from "./forget-password-page/solicitar-email";
import PotenciaMaxima from "./potencia-maxima";
import GraficoPagePotencia from "./potencia-maxima/ver-grafica-page";
import ContadorOnline from "./contador-page";
import GraficoPageContador from "./contador-page/ver-grafica-page";


const PrivateRoute = ({ component, ...options }) => {
    const {state} = useContext(AuthDataContext);

   if(!state.token){
       return <Redirect to='/' />;
   }else{
       return  <Route {...options} component={component} />
   }
};

export const Router = () => (
    <Switch>
        <Route exact path='/' component={Login}/>
        <Route exact path='/login/:lang?' component={Login}/>

        <Route  exact path='/registrarse' component={SignUp}/>
        <Route  exact path='/recuperar-contrasenya-email' component={ForgetPasswordSolicitarEmail}/>
        <Route  exact path='/recuperar-contrasenya-cambiar/:token' component={ForgetPasswordCambiarPass}/>
        <PrivateRoute  exact path='/home' component={Home}/>
        <PrivateRoute  exact path='/mi-consumo' component={ElTeuConsum}/>
        <PrivateRoute  exact path='/potencia-maxima' component={PotenciaMaxima}/>
        <PrivateRoute  exact path='/contador-online' component={ContadorOnline}/>
        <PrivateRoute  exact path='/grafico-consumo' component={GraficoPageConsumo}/>
        <PrivateRoute  exact path='/grafico-potencia' component={GraficoPagePotencia}/>
        <PrivateRoute  exact path='/grafico-contador' component={GraficoPageContador}/>
        <PrivateRoute  exact path='/perfil' component={PerfilPage}/>
    </Switch>
);