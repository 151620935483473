import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import './style.css'
import {faAngleRight, faCross, faTimes, faTrash} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import imgpdf from '../../../../img/pdf-pre.png'
const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
    justifyContent : 'center',
    position: 'relative'
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'contain'
};


function MyDropzone(props) {
    const [files, setFiles] = useState([]);
    const [mensajeMaxFiles, setmensajeMaxFiles] = useState(false);
    const { t, i18n } = useTranslation();

    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*,application/pdf',
        multiple: true,
        onDrop: (acceptedFiles, rejectedFiles) => {



            if(acceptedFiles.length>2){ //para que no se puedan mas de 2
                setmensajeMaxFiles(true)
                return
            }


            let array_files_b64 = []

            acceptedFiles.forEach( (file) => {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = function() {
                    array_files_b64.push(reader.result)
                }
            })

            props.functionFileToForm(props.inputName, array_files_b64)//funcion por prop que añade al react hook form las files


            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));



        }
    });
    const  handleDelete = (index) => {
        setFiles([])
    }

    function blobToFile(theBlob, fileName){
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    const thumbs = files.map(file => (

        <div style={thumb} key={file.name}>
            <div style={thumbInner} >

                { file.name.split('.').pop() === 'pdf' ? <> <img
                    src={imgpdf}
                    style={img}
                />
                    <small className="Small-dropzone-name-img">{file.name}</small>


                </>
                    :
                    <img
                    src={file.preview}
                    style={img}
                />}

            </div>
        </div>
    ));

    useEffect(() => () => {

        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));

    }, [files]);

    return (
        <section className="container">
            <div {...getRootProps({className: 'dropzone Cursor-pointer'})}>
                <input {...getInputProps()} />
                <p>{t('fes_click')}</p>
            </div>
            {mensajeMaxFiles && <p className="text-danger"> {t('noespot')}</p>}
            <aside style={thumbsContainer}>
                {files.length>0 && <div onClick={handleDelete} className="p-absolute Cursor-pointer" style={{'right':'5px'}}>
                    <FontAwesomeIcon icon={faTimes} style={{'color':'red','fontSize':'18px'}}/>
                </div>}
                {thumbs}
            </aside>
        </section>
    );
}

export default MyDropzone