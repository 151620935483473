import React, {useReducer} from "react";
import axiosConfig from "../axiosConfig";

export const SignUpFormContext = React.createContext();


const initialState = {
    tipo: '',
    perfil_habitual: '',
    nombre: null,
    apellidos: null,
    tipo_documento: 'nif',
    n_documento: null,
    email: null,
    razon_social: null,
    password: null,
    password_confirmation: null,
    email_re: null,
    prefijo_tlf: '34',
    cif: null,
    tlf: null,
    confirmar_tlf: null,
    idioma: 'es',
    step: 1
}


function formSignupReducer(state, action) {

    switch (action.type) {
        case 'update_form_value':

            return {
                ...state,
                ...action.data
            };
        case 'come_back_form':

            return {
                ...state,
                ...action.data,
                step: state.step - 1
            };

        default:
            return state
    }
}


export function FormSignupProvider(props) {
    const [state, dispatch] = useReducer(formSignupReducer, initialState);

    return (
        <SignUpFormContext.Provider value={{state, dispatch}}>
            {props.children}
        </SignUpFormContext.Provider>
    )

}