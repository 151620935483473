import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function SimpleMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [desplegado, setDesplegado] = React.useState(false);
    const { t, i18n } = useTranslation();
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        setDesplegado(true)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setDesplegado(false)

    };

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                {props.openElement} &nbsp; {!desplegado ? <FontAwesomeIcon icon={faAngleDown} color="#fff" size="lg"/> :
                <FontAwesomeIcon icon={faAngleUp} color="#fff" size="lg"/>}
            </Button>
            <Menu
                id="simple-menu" className="Menu-desplegable"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Link to='mi-consumo'>
                    <MenuItem onClick={handleClose}>{t('consum')}</MenuItem>
                </Link>
                <Link to='potencia-maxima'>

                    <MenuItem onClick={handleClose}>{t('potencia_maxima')}</MenuItem>
                </Link>
                <Link to='contador-online'>

                    <MenuItem onClick={handleClose}>{t('contador')}</MenuItem>
                </Link>

            </Menu>
        </div>
    );
}