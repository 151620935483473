import React, {useContext, useEffect, useState} from 'react';

import 'chart.js'
import TabGraficoFiltro from "./tab-filtro";
import TabGraficoTabla from "./tab-grafico-tabla";
import Axios from "axios";
import {catchApiErrors, expulsarIfTokenInvalido, getLocalStorageData} from "../../../helpers/auth-helpers";
import {AuthDataContext} from "../../../context/auth-context";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Redirect} from 'react-router-dom';

import './style.scss';
import {url_api} from "../../../axiosConfig";
import TransitionsModal from "../../common-components/modal";
import { ChartHoras} from "../../common-components/charts/mi-consumo-chart";
import DivGrisTitulo from "../../common-components/seccion-titulo";
import RenderHeader from "../../common-components/header";
import {BackdropCargandoContext} from "../../../context/backdrop-cargando-context";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import {positions, useAlert} from "react-alert";
import cross from "../../../img/cruz-a.png";



function GraficoPageConsumo(props) {

    const alert = useAlert();

    const {state} = useContext(AuthDataContext);
    const {backdrop_dispatch} = useContext(BackdropCargandoContext);

    const [dataDatatable, setdataDatatable] = useState('vacio');
    const [dataGrafico, setdataGrafico] = useState();
    const [dataGraficoModal, setdataGraficoModal] = useState('vacio');
    const [chartLoading, setchartLoading] = useState(false);
    const [chartLoadingModal, setchartLoadingModal] = useState(true);
    const [energiaTotal, setenergiaTotal] = useState(false);
    const [energiaTotalModal, setenergiaTotalModal] = useState(false);
    const [tipoGrafico, settipoGrafico] = useState('dias');
    const [periodos, setperiodos] = useState();
    const [openModal, setopenModal] = useState();
    const [filtro_lineas_archivo_index, setfiltro_lineas_archivo_index] = useState();
    const [fechaModal, setfechaModal] = useState('');
    const [cargandoPeriodo, setcargandoPeriodo] = useState(true);
    const [autoconsumo, setAutoconsumo] = useState(false);
    const { t, i18n } = useTranslation();
    const [filtroLineasGrafico, setfiltroLineasGrafico] = useState([t('energia'),t('eneta'),t('excedent'),t('eexportada')]);
   /*Variables para guardar las lineas del grafico y poder recuperarlas en los filtros*/
    const [lineaEnergia, setlineaEnergia] = useState();
    const [lineaEneta, setlineaEneta] = useState();
    const [lineaExcedent, setlineaExcedent] = useState();
    const [lineaEexportada, setlineaEexportada] = useState();
/**/
    /*NUEVO MOSTRAR ENERGIA TOTAL POR Tramo*/
    const [energiaTramo1, setenergiaTramo1] = useState(0);
    const [energiaTramo2, setenergiaTramo2] = useState(0);
    const [energiaTramo3, setenergiaTramo3] = useState(0);
    const [energiaTramo4, setenergiaTramo4] = useState(0);
    const [energiaTramo5, setenergiaTramo5] = useState(0);
    const [energiaTramo6, setenergiaTramo6] = useState(0);
/*-----*/
    /*para habilitar o deshabilitar botones filtro lineas*/
    const [btnFiltro_energia, setbtnFiltro_energia] = useState(true);
    const [btnFiltro_excedent, setbtnFiltro_excedent] = useState(true);
    const [btnFiltro_eneta, setbtnFiltro_eneta] = useState(true);
    const [btnFiltro_eexportada, setbtnFiltro_eexportada] = useState(true);



    /**/
    function dividirEnergiaTotalPorTramos(param_data){
        /*DEJO LAS VARIABLES A 0 DE NUEVO*/
        setenergiaTramo1(0)
        setenergiaTramo2(0)
        setenergiaTramo3(0)
        setenergiaTramo4(0)
        setenergiaTramo5(0)
        setenergiaTramo6(0)
        /*RECORRO EL OBJETO PARA SUMAR LAS ENERGIAS POR TRAMOS Y GUARDARLAS EN LAS VARIABLES */
        for (var item in param_data){
            switch (param_data[item].periode) {
                case "1":
                    setenergiaTramo1(energiaTramo1 => energiaTramo1 + parseFloat(param_data[item].y));
                    break;
                case "2":
                    setenergiaTramo2(energiaTramo2 => energiaTramo2 + parseFloat(param_data[item].y));
                    break;
                case "3":
                    setenergiaTramo3(energiaTramo3 => energiaTramo3 + parseFloat(param_data[item].y));
                    break;
                case "4":
                    setenergiaTramo4(energiaTramo4 => energiaTramo4 + parseFloat(param_data[item].y));
                    break;
                case "5":
                    setenergiaTramo5(energiaTramo5 => energiaTramo5 + parseFloat(param_data[item].y));
                    break;
                case "6":
                    setenergiaTramo6(energiaTramo6 => energiaTramo6 + parseFloat(param_data[item].y));
                    break;
                default:
                    break;
            }
        }


    }
    /*-----*/


    /*al cargar por primera vez cargo la grafica de hoy*/
    useEffect(() => {
      /*  getDataGraficoTabla(null, null, moment().subtract(1, 'days').format('YYYY-MM-DD'), 'days');*/ //PARA CARGAR GRAFICO DEL DIA ANTERIOR POR DEFECTO
        getPeriodos(props.location.state.cups);
    }, [])

    function crearObjetodelGrafico(res){


        let array_lineas = [

        ]
            let linea_energia = {
                "id":  t('energia'),
                "color": "#060155",
                "data": res.data.energia
            }
            setlineaEnergia(linea_energia) //variable donde guardo la linea para poder añadirla al pulsar el boton de filtro ya que al clickar lo borro

            array_lineas.push(linea_energia)
            let linea_eneta ={
                "id":  t('eneta'),
                "color": "#57a257",
                "data": res.data.energia_neta
            }
            setlineaEneta(linea_eneta) //variable donde guardo la linea para poder añadirla al pulsar el boton de filtro ya que al clickar lo borro

            array_lineas.push(linea_eneta)
            let linea_excedent ={
                "id":  t('excedent'),
                "color": "#df7ad0",
                "data": res.data.excedent
            }
            setlineaExcedent(linea_excedent) //variable donde guardo la linea para poder añadirla al pulsar el boton de filtro ya que al clickar lo borro

            array_lineas.push(linea_excedent)
            let linea_eexportada ={
                "id":  t('eexportada_id'),
                "color": "#df8439",
                "data": res.data.energia_exportada
            }
            setlineaEexportada(linea_eexportada) //variable donde guardo la linea para poder añadirla al pulsar el boton de filtro ya que al clickar lo borro
            array_lineas.push(linea_eexportada)
      return array_lineas
    }
    function crearObjetodelGraficoModal(res){
   /*En el del modal creare el grafico a partir de los filtros seleccionados */

        let array_lineas = [

        ]
        if(filtroLineasGrafico.includes(t('energia'))){
            let linea_energia = {
                "id":  t('energia'),
                "color": "#060155",
                "data": res.data.energia
            }

            array_lineas.push(linea_energia)
        }
        if(filtroLineasGrafico.includes(t('eneta'))){
            let linea_eneta ={
                "id":  t('eneta'),
                "color": "#57a257",
                "data": res.data.energia_neta
            }

            array_lineas.push(linea_eneta)
        }

        if(filtroLineasGrafico.includes(t('excedent'))){
            let linea_excedent ={
                "id":  t('excedent'),
                "color": "#df7ad0",
                "data": res.data.excedent
            }

            array_lineas.push(linea_excedent)
        }
        if(filtroLineasGrafico.includes(t('eexportada'))){
            let linea_eexportada ={
                "id":  'E.exportada',
                "color": "#df8439",
                "data": res.data.energia_exportada
            }
            array_lineas.push(linea_eexportada)
        }
        return array_lineas
    }

    function guardarLineasGrafico(res){

    }
    //funcion que pasaré al component filtro para actualizar las fechas con el filtro
    function getDataGraficoTabla(fecha_actual_param = null, fecha_filtro_param = null, fecha_filtro_dia = null, tipo_filtro = null ,filtro_lineas = null ) {

        let url_segun_tipo_filtro = 'get_corbes_por_dia';
        let data_post = {
            uuid: state.user.uniqid,
            cups: props.location.state.cups,
            fecha_actual: fecha_actual_param,
            fecha_filtro: fecha_filtro_param
        };

        if (tipo_filtro === 'days') {

            settipoGrafico('horas')
            url_segun_tipo_filtro = 'get_corbes_por_hora';
            data_post = {
                uuid: state.user.uniqid,
                cups: props.location.state.cups,
                fecha_actual: fecha_filtro_dia,
                fecha_filtro: fecha_filtro_dia
            };
        } else {
            settipoGrafico('dias')

        }


        /*setchartLoading(true)*/
        setdataGrafico(null)

        backdrop_dispatch({
            type: 'open',
            data: {}
        });

        Axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(getLocalStorageData('token'))}`;
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.defaults.withCredentials = true;
        Axios.post(`${url_api + 'mi_consumo/' + url_segun_tipo_filtro}`, data_post)
            .then(res => {


                setenergiaTotal(res.data.energia_total) //le paso la energia total a la variale energiatotal
                delete res.data.energia_total//borro la energia total de la data ya que esta data se la pasare al grafico
                if(tipo_filtro==='days'){
                    dividirEnergiaTotalPorTramos(res.data.energia)

                }

               /* res.data = Object.values(res.data)*///convierto el objeto en array de objetos que es el formato que pide el grafico

                if (res.data.energia.length < 1) {
                    setdataDatatable('vacio')
                    setdataGrafico('vacio')
                } else {



                   let autoconsumo = false
                    /*compruebo si alguna fila del periodo tiene autoconsumo ya pongo el autoconsumo en true
                    * para mostrar los filtros*/
                    res.data.energia.forEach((item, index)=>{
                        if(item.autoconsum){
                            autoconsumo=true//variable local
                            setAutoconsumo(true) // state que pasare por prop para mostrar los btoones o no
                            return
                        }
                    })

                    /*Si tiene autoconsumo muestro las diferentes lineas sino no*/
                    if(autoconsumo){
                        /*funcion para mostrar lineas segun el filtro*/
                        setdataGrafico(crearObjetodelGrafico(res))
                    }else{
                        setdataGrafico([
                            {
                                "id":  t('energia'),
                                "color": "#060155",
                                "data": res.data.energia
                            }
                        ])
                    }





                    /*HAGO LA LLAMADA A LA QUE ME DEVUELVE DIAS CON HORAS PARA LOS DATOS DE LA TABLA DEL OTRO TAB
                    * LA HAGO AQUÍ PARA QUE ASÍ CARGUE EL GRAFICO PRIMERO Y PUEDAN VER DATOS AHÍ*/

                    Axios.post(`${url_api + 'mi_consumo/get_corbes_por_dia_hora '}`, data_post)
                        .then(res2 => {

                            setdataDatatable(res2.data)

                        }).catch(function (error) {
                        catchApiErrors(error,backdrop_dispatch,t,alert)
                    })

                    /*END */

                }
                /*setchartLoading(false)*/
               setTimeout(() => {
                   backdrop_dispatch({
                       type: 'close',
                       data: {}
                   });
               },500)


                if(!autoconsumo){
                    if(filtro_lineas.includes(t('eeneta')) || filtro_lineas.includes(t('excedent')) || filtro_lineas.includes(t('eexportada'))){

                    alert.show(<div style={{  }}><img src={cross} alt=""/> <span className="pt-1">{t('noconsumo')}</span></div>, { position: positions.MIDDLE_RIGHT})

                    }

                }

            }).catch(function (error) {
                catchApiErrors(error,backdrop_dispatch,t,alert)
        })
    }

    function generarGraficoModal(fecha_filtro_dia) {//funcion que se usará al clickar en los días para abrir un modal y generar un grafico de ese dia en concreto
        setchartLoadingModal(true)

        setfechaModal(fecha_filtro_dia)
        setdataGraficoModal('vacio')
        setopenModal(true)

        let data = {
            uuid: state.user.uniqid,
            cups: props.location.state.cups,
            fecha_actual: fecha_filtro_dia,
            fecha_filtro: fecha_filtro_dia
        };


        Axios.post(`${url_api + 'mi_consumo/get_corbes_por_hora'}`, data)
            .then(res => {

                setenergiaTotalModal(res.data.energia_total) //le paso la energia total a la variale energiatotal
                delete res.data.energia_total//borro la energia total de la data ya que esta data se la pasare al grafico
                dividirEnergiaTotalPorTramos(res.data.energia)
             /*   res.data = Object.values(res.data)//convierto el objeto en array de objetos que es el formato que pide el grafico

*/
                if (res.data.length < 1) {
                    setdataGraficoModal('vacio')
                } else {
                    /*funcion para mostrar lineas segun el filtro*/
                    var autoconsumo_modal= false;
                    /*compruebo si alguna fila del periodo tiene autoconsumo ya pongo el autoconsumo en true
                    * para mostrar los filtros*/
                    res.data.energia.forEach((item, index)=>{
                        if(item.autoconsum){
                            autoconsumo_modal=true
                            return
                        }
                    })
                    if(autoconsumo_modal){
                        /*funcion para mostrar lineas segun el filtro*/
                        setdataGraficoModal(crearObjetodelGraficoModal(res))
                    }else{
                        setdataGraficoModal([
                            {
                                "id":  t('energia'),
                                "color": "#060155",
                                "data": res.data.energia
                            }
                        ])
                    }

                }
                setchartLoadingModal(false)


            }).catch(function (error) {
            catchApiErrors(error,backdrop_dispatch,t,alert)
        })
    }

    /*Aqui con periodos me refiero a las fechas de consumo de energia divididas por meses
    * ya que un periodo no va de mes a mes completo*/
    function getPeriodos(cups){


        let data = {
            uuid: state.user.uniqid,
            cups: props.location.state.cups,
        };


        Axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(getLocalStorageData('token'))}`;
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.defaults.withCredentials = true;
        Axios.post(`${url_api + 'mi_consumo/get_periodos'}`, data)
            .then(res => {
                setcargandoPeriodo(false)
                if (res.data.length < 1) {
                    setperiodos()
                } else {
                    setperiodos(res.data.data)
                }
                setchartLoadingModal(false)


            }).catch(function (error) {
            catchApiErrors(error,backdrop_dispatch,t,alert)
        })
    }
    if (!props.location.state) {
        return <Redirect to="/mi-consumo"/>
    } else {
        return (
            <>
                <RenderHeader/>
                <DivGrisTitulo tituloBienvenida={false} titulo={t('consum')}/>
            <div className="Padding-seccion-principal Modal-gr">

                <TransitionsModal loading={chartLoadingModal} clase={'Modal-Grafico'} titulo={t('cdd')+` ${moment(fechaModal).format('DD/MM/YYYY')} `} modalWidth={'80%'}
                                  open={openModal}
                                  setopenModal_parent={setopenModal}>
                    <div className="  Grafico-div-consumo mw-100  p-relative">
                       {/* <span className="Titulo-grafico">kWh - dies</span>*/}
                        <span className="Energia-total text-center d-block">Acumulada: {energiaTotalModal.toString().replace('.',',')} kWh</span>
                        {energiaTramo1>0 && <span className="Energia-total M-txt text-center d-block">{t('pertr')} 1: {energiaTramo1.toFixed(3).toString().replace('.',',')} kWh</span>}
                        {energiaTramo2>0 && <span className="Energia-total M-txt text-center d-block">{t('pertr')} 2: {energiaTramo2.toFixed(3).toString().replace('.',',')} kWh</span>}
                        {energiaTramo3>0 && <span className="Energia-total M-txt text-center d-block">{t('pertr')} 3: {energiaTramo3.toFixed(3).toString().replace('.',',')} kWh</span>}
                        {energiaTramo4>0 && <span className="Energia-total M-txt text-center d-block">{t('pertr')} 4: {energiaTramo4.toFixed(3).toString().replace('.',',')} kWh</span>}
                        {energiaTramo5>0 && <span className="Energia-total M-txt text-center d-block">{t('pertr')} 5: {energiaTramo5.toFixed(3).toString().replace('.',',')} kWh</span>}
                        {energiaTramo6>0 && <span className="Energia-total M-txt text-center d-block">{t('pertr')} 6: {energiaTramo6.toFixed(3).toString().replace('.',',')} kWh</span>}
                        {dataGraficoModal !== 'vacio'
                            ?
                            <ChartHoras tarifa={props.location.state.tarifa} generar_grafico_modal={props.generar_grafico_modal} data={
                               dataGraficoModal
                            }

                            />
                            :
                            <h2 className="text-center mt-5">{t('nodades')}</h2>}
                    </div>
                </TransitionsModal>

                <div className="Ver-grafica">
                    <div className="container px-md-5">
                        <div className="text-center mb-3"><h2 className=" mb-4 text-center Borde-gris">CUPS: &nbsp;
                            {props.location.state.cups}</h2>

                            <p className="text-center my-4"  ><b>{t('direccion')}: </b> <span style={{'fontSize':'17px','textTransform':'uppercase'}}> {props.location.state.direccion}</span> </p>
                        </div>
                        <div className="row">
                            <div className="col-12  d-flex flex-wrap flex-column">


                                <p className="text-center pl-0 pt-0" style={{'fontSize':'15px'}}>{t('consultar_corba')} </p>

                                <div>
                                    <h2 className=" Titulo-pag-grafico">
                                        {t('periodo')}
                                    </h2>
                                </div>
                                <div className="d-flex w-100">
                                    <TabGraficoFiltro cargandoPeriodo={cargandoPeriodo} periodos={periodos} actualizarFechas={getDataGraficoTabla}/>


                                </div>

                            </div>


                        </div>
                    </div>

                    <div className="container-fluid px-5 Z-index-1 m-pl0 m-pr0">
                        <div className="row Z-index-1">
                            <div className="col-12 mt-5 text-center">
                                {chartLoading &&
                                <CircularProgress  className="mx-auto text-center "
                                                  disableShrink/>}
                                {dataGrafico &&
                                <TabGraficoTabla generar_grafico_modal={generarGraficoModal} tipo_grafico={tipoGrafico} autoconsumo={autoconsumo}
                                                 energia_total={energiaTotal} data_grafico={dataGrafico} setdataGrafico={setdataGrafico} tarifa={props.location.state.tarifa}
                                                 data_tabla={dataDatatable} energiaTramo1 ={energiaTramo1} energiaTramo2 ={energiaTramo2} energiaTramo3 ={energiaTramo3} lineaEnergia={lineaEnergia} lineaEneta={lineaEneta} lineaExcedent={lineaExcedent} lineaEexportada={lineaEexportada}
                                                 energiaTramo4 ={energiaTramo4} energiaTramo5 ={energiaTramo5} energiaTramo6 ={energiaTramo6} filtroLineasGrafico={filtroLineasGrafico} setFiltroLineasGrafico={setfiltroLineasGrafico}
                                                 btnFiltro_energia={btnFiltro_energia} btnFiltro_excedent={btnFiltro_excedent} btnFiltro_eneta={btnFiltro_eneta} btnFiltro_eexportada={btnFiltro_eexportada} cupstxt={props.location.state.cups}
                                                 setbtnFiltro_energia={setbtnFiltro_energia} setbtnFiltro_excedent={setbtnFiltro_excedent} setbtnFiltro_eneta={setbtnFiltro_eneta} setbtnFiltro_eexportada={setbtnFiltro_eexportada} />}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </>
        );
    }


}

export default GraficoPageConsumo;