import { useEffect } from "react";

export function ScrollToTopOnMount() {
    useEffect(() => {
        setTimeout( () =>

            document.body.scrollIntoView({behavior: 'smooth', block: 'start'}),1)

    }, []);

    return null;
}
