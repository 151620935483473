import React from 'react';

import AuthDataProvider from "./context/auth-context";

import './css/bootstrap.min.css';
import './App.scss';
import CheckLogguedOnLoad from "./components/checkLoggedOnLoad";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import BackdropCargandoProvider from "./context/backdrop-cargando-context";

import './i18n';

function App(props) {
    const options = {
        timeout: 5000,
        position: positions.BOTTOM_CENTER
    };


    return (
              <AuthDataProvider>
                <BackdropCargandoProvider>
                <Provider template={AlertTemplate} {...options}>
                <div className="App">
                        <CheckLogguedOnLoad/>
                </div>
                </Provider>
                </BackdropCargandoProvider>
            </AuthDataProvider>


    );
}





export default App;
