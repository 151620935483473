
import './style.scss';
import React, {useContext} from "react";
import {AuthDataContext} from "../../../context/auth-context";
import { useTranslation } from 'react-i18next';

function DivGrisTitulo(props) {
    const {state} = useContext(AuthDataContext);
    const { t, i18n } = useTranslation();

    return (
        <div className="Div-gris">
            <div className="container">
             {/*   {props.tituloBienvenida ?    <p> Hola  {state.user.tipo=='representante' ? state.user.razon_social : state.user.nombre}, {t('bienvenido')}! </p> : <h1> {props.titulo}</h1>}
          */}
           <h1> {props.titulo}</h1>
            </div>
        </div>
    );
}

export default DivGrisTitulo;
