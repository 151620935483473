import React, {createContext, useReducer, useEffect, useMemo} from "react";

import {deleteLocalStorageData, setLocalStorageData} from "../helpers/auth-helpers";
export const AuthDataContext = createContext(null);

const initialAuthData = {
    token : null,
    user: null
};

function AuthDataReducer(state, action) {
    switch (action.type) {
        case 'login':
            setLocalStorageData(JSON.stringify(action.data.token),'token')
            return {
                ...state,
                ...action.data
            };

        case 'logout':
            deleteLocalStorageData('token')
            return {
                ...state,
                token: null,
                user: null
            };
        case 'update_auth_storage':

            return {
                ...state,
                ...action.data
            };
        case 'delete_auth_storage':
            deleteLocalStorageData('token')
            return {
                state : initialAuthData
            };
        default:
            return state
    }
}

const AuthDataProvider = props => {
    const [state, dispatch] = useReducer(AuthDataReducer, initialAuthData);

    useEffect(() => {

        dispatch({
            type: 'check_authenticated',
            data : {}
        });

    }, []);
    return (
        <AuthDataContext.Provider value={{state, dispatch}}>
            {props.children}
        </AuthDataContext.Provider>
    );
};

export default AuthDataProvider;