// First we need to import axios.js
import axios from 'axios';
import {getLocalStorageData} from './helpers/auth-helpers'
// Next we make an 'instance' of it
const axiosConfig = axios.create({
// .. where we make our configurations
    baseURL: 'https://api.zona-privada.electracaldensedistribucio.com/api/'
});

// Where you would set stuff like your 'Authorization' header, etc ...
axiosConfig.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(getLocalStorageData('token'))}`;
axiosConfig.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
axiosConfig.defaults.withCredentials=true;

// Also add/ configure interceptors && all the other cool stuff


export const url_api = 'https://api.zona-privada.electracaldensedistribucio.com/api/';



export default axiosConfig;