import React, {Component, useContext, useEffect, useState} from 'react';

import CardMenu from "../home-page/card-menu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faChartBar, faHome, faWifi} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Datatable from "../common-components/datatables/datatable";
import {Footer} from "../common-components/footer";
import Axios from "axios";
import {catchApiErrors, expulsarIfTokenInvalido, getLocalStorageData} from "../../helpers/auth-helpers";
import {AuthDataContext} from "../../context/auth-context";
import CircularProgress from "@material-ui/core/CircularProgress";
import DivGrisTitulo from "../common-components/seccion-titulo";
import RenderHeader from "../common-components/header";
import {BackdropCargandoContext} from "../../context/backdrop-cargando-context";
import {useTranslation} from 'react-i18next';
import {useAlert} from "react-alert";
import moment from "moment";
import {returnProvincia} from "../../helpers/generales";

import imges from "../../img/atca.png"
import imgca from "../../img/ates.png"

function PotenciaMaxima(props) {

    const [FiltroCorbes, setFiltroCorbes] = useState();
    const {state} = useContext(AuthDataContext);
    const {backdrop_state, backdrop_dispatch} = useContext(BackdropCargandoContext);

    const [dataDatatable, setdataDatatable] = useState();
    const {t, i18n} = useTranslation();
    const alert = useAlert();


    function handleChange() {

    }

    const columns = [
        {
            name: "CUPS",
            label: "CUPS",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "DataInici",
            label: t('data_inici'),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {

                    return (
                        <> {moment(value).format('DD-MM-YYYY')}</>
                    );
                }
            },

        },
        {
            name: "DataFinal",
            label: t('data_fi'),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {

                    return (
                        <> { value!='' ? moment(value).format('DD-MM-YYYY') : ''}</>

                    );
                }
            },

        },
        {
            name: "TARIFA",
            label: t('te'),
            options: {
                filter: true,
                sort: false,
                display: false,


            }

        },
        {
            name: "POTENCIA",
            label: t('pc2'),
            options: {
                filter: true,
                sort: false,
                display: false,

                customBodyRender: (value, tableMeta, updateValue) => {

                    return (
                        <> {value.replace('.', ',')}kWh
                            {tableMeta.rowData[8] && ' ' + tableMeta.rowData[8].replace('.', ',') + 'kWh'}
                            {tableMeta.rowData[9] && ' ' + tableMeta.rowData[9].replace('.', ',') + 'kWh'}
                            {tableMeta.rowData[10] && ' ' + tableMeta.rowData[10].replace('.', ',') + 'kWh'}
                            {tableMeta.rowData[11] && ' ' + tableMeta.rowData[11].replace('.', ',') + 'kWh'}
                            {tableMeta.rowData[12] && ' ' + tableMeta.rowData[12].replace('.', ',') + 'kWh'}
                            {tableMeta.rowData[13] && ' ' + tableMeta.rowData[13].replace('.', ',') + 'kWh'}
                        </>
                    );
                }
            },

        },
        {
            name: "DOMICILI",
            label: t('direccion'),
            options: {
                filter: true,
                sort: false,

            }
        },
        {
            name: "poblacio",
            label: t('poblacion'),
            options: {
                filter: true,
                sort: false,

            }
        },
        {
            name: "codi_post",
            label: "C.Postal",
            options: {
                filter: true,
                sort: false,
                display: false,

            }
        },
        {
            name: "provincia",
            label: t('provincia'),
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <> {returnProvincia(tableMeta.rowData[7])}</>
                    );
                }
            }
        },
        {
            name: "POTENCIA2",
            label: "POTENCIA2",
            options: {

                filter: false,
                sort: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: "POTENCIA3",
            label: "POTENCIA3",
            options: {

                filter: false,
                sort: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: "POTENCIA4",
            label: "POTENCIA4",
            options: {

                filter: false,
                sort: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: "POTENCIA5",
            label: "POTENCIA5",
            options: {

                filter: false,
                sort: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: "POTENCIA6",
            label: "POTENCIA6",
            options: {

                filter: false,
                sort: false,
                display: false,
                viewColumns: false
            }
        },

        {
            name: "grafico",
            label: t('curvas'),
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {

                    return (
                        <Link to={{
                            pathname: '/grafico-potencia',
                            state: {
                                "cups": tableMeta.rowData[0],
                                "tarifa": tableMeta.rowData[3],
                                "potencia_contratada": tableMeta.rowData[4],
                                "potencia_contratada2": tableMeta.rowData[9],
                                "potencia_contratada3": tableMeta.rowData[10],
                                "potencia_contratada4": tableMeta.rowData[11],
                                "potencia_contratada5": tableMeta.rowData[12],
                                "potencia_contratada6": tableMeta.rowData[13],
                                "direccion": tableMeta.rowData[5] + ', ' + tableMeta.rowData[7] + ', ' + tableMeta.rowData[6] + ' ' + returnProvincia(tableMeta.rowData[7])
                            }
                        }} className="Btn-transparent-border-green Btn-small">

                            {t('consultarpot')}

                        </Link>
                    );
                }
            },

        }
    ];
    const options = {
        selectableRows: 'none',
        responsive:false
    }
    useEffect(() => {
        backdrop_dispatch({
            type: 'open',
            data: {}
        });
        Axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(getLocalStorageData('token'))}`;
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.defaults.withCredentials = true;
        Axios.post(`https://api.zona-privada.electracaldensedistribucio.com/api/mi_consumo/get_peatges`, {uuid: state.user.uniqid})
            .then(res => {

                setdataDatatable(res.data.data)
                backdrop_dispatch({
                    type: 'close',
                    data: {}
                });
            }).catch(function (error) {
            catchApiErrors(error, backdrop_dispatch, t, alert)
        })

    }, [])


    return (
        <>
            <RenderHeader/>

            <DivGrisTitulo style={{'textTransform': 'uppercase'}} tituloBienvenida={false} titulo={t('ptm')}/>

            <div className="El-teu-consum Padding-seccion-principal">
                <div className=" px-3 container">

                    <div className="row">
                        <div className="col-12 col-md-8 d-flex flex-wrap">

                            {/*    <h2 className=" Titulo2 mb-4">
                                Consulta de corbes de consum
                            </h2>*/}
                            <p>{t('potentitulo')}</p>

                            <div>
                                <h5 className=" Titulo3 mt-5 mb-4">
                                    {t('selecrao')}
                                </h5>
                            </div>
                            <div className="Row-flex Row_input_btn my-2">

                                <FormControl className="mt-0" fullWidth>
                                    <InputLabel>
                                        {t('razon_social')}
                                    </InputLabel>

                                    <Select required onChange={handleChange} name="filtro_corbes" fullWidth
                                            value={FiltroCorbes} defaultValue="nif1">

                                        <MenuItem value="nif1">
                                            {state.user.tipo == 'representante' ? state.user.razon_social : state.user.nombre + ' ' + state.user.apellidos}

                                        </MenuItem>


                                    </Select>


                                </FormControl>
                                <button className="Btn Btn_green Btn-movil" type="button"><span>Aplicar</span></button>

                            </div>

                        </div>
                        <div className="col-12 col-md-4 d-flex flex-column">
                            <div className="d-flex flex-column">


                                {/*  <hr/>*/}
                                <div className=" Caja-gris Inferior-Contador ">
                                    <a href="tel:+34900181776" className="mw-100 mh-100 d-block">
                                        {
                                            getLocalStorageData('lang') === 'es'
                                                ?
                                                <img src={imges} className="w-100 h-100" style={{'objectFit': 'cover'}}
                                                     alt=""/>
                                                :
                                                <img src={imgca} className="w-100 h-100" style={{'objectFit': 'cover'}}
                                                     alt=""/>

                                        }
                                    </a>

                                </div>
                                {/*       <hr/>*/}


                            </div>
                        </div>

                        <div className="col-12 mt-5">
                            {/* PARA QUE CARGUE EL SPINNER EN LA TABLA
                            {dataDatatable ? <Datatable titulo="Peatges" data={dataDatatable} options={options} columns={columns}/> :
                                <CircularProgress className="mx-auto" disableShrink/>}*/}
                            {!backdrop_state.open &&
                            <Datatable titulo={t('peatges')} data={dataDatatable} options={options} columns={columns}/>}
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
}

export default PotenciaMaxima;