import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';

import 'react-day-picker/lib/style.css';

import {useTranslation} from 'react-i18next';

import {ContadorChart2} from "../../common-components/charts/contador-chart2";


export default function BodyContadorPage(props) {

    const {t} = useTranslation();

    /*const [ potencia_instantanea , setPotencia_instantanea] = useState()*/


    const potencia_instantanea = props.dataGrafico ?  parseFloat(props.dataGrafico['@Pimp'])/1000 : null;




    /* useEffect( () => {
         setPotencia_instantanea();
     } ,props.dataGrafico)*/

    return (
        <div>
          {/*  <div className="d-flex">
                <table className="Tabla-Contador">
                    <tbody>

                    <tr>
                        <td>
                            {t('ctt')}
                        </td>
                        <td>
                            {props.dataGrafico && (props.dataGrafico['@AIa'] / 1000).toString().replace('.',',') + ' kWh'}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('eicp')}

                        </td>
                        <td className="p-0">
                            {
                                <RenderEstadoIcp dataGrafico={props.dataGrafico}/>

                            }
                        </td>
                    </tr>
                    </tbody>

                </table>*/}



                {/* <div className="Grafico-contador">
                        <ContadorChart/>
                    </div>*/}
            {/*</div>
*/}
            <div className="d-flex flex-column-m justify-content-center px-4">

                <div className="Col-1t">
                    <div className="R1">
                     <p>
                         {t('ctt')}
                     </p>
                    </div>
                    <div className="R2">
                        {props.dataGrafico && (props.dataGrafico['@AIa'] / 1000).toString().replace('.',',') + ' kWh'}

                    </div>
                </div>
                <div className="Col-2t" >
                    <div className="R1">
                        <p>

                        {t('eicp')}
                        </p>
                    </div>
                    <div className=" R22">
                        {
                            <RenderEstadoIcp dataGrafico={props.dataGrafico}/>

                        }
                    </div>
                </div>
            </div>

            {
                props.dataGrafico &&
                <div>
                    <div className="Grafico-contador2 mt-5">
                        <p className="Potencia-p">{t('pc')} {props.potencia_contratada.replace('.',',')} kWh</p>
                        <ContadorChart2 potencia_contratada={props.potencia_contratada} datos={props.dataGrafico}/>

                        <p className="Potencia-i2">kWh</p>
                        <p className="Potencia-i"><b>{t('potencia_instantanea_actual')}</b> {(props.dataGrafico['@Pimp']/1000).toString().replace('.',',')} kWh ( { Math.round(potencia_instantanea * 100 / props.potencia_contratada * 10) / 10 } %)</p>

                    </div>
                </div>

            }


        </div>
    );


}

export function RenderEstadoIcp(props){
    const {t} = useTranslation();


        if(props.dataGrafico['@Eacti'] ==='1'){
            return(
                <div className="Icp Icp_conectado">
                    {t('conectado')}
                </div>
            )
        }else if(props.dataGrafico['@Eacti'] ==='0' ){
            return(
                <div className="Icp Icp_desconectado">
                    {t('desconectado')}
                </div>
            )

        }else if(props.dataGrafico['@Eacti'] ==='2'){
            return(
                <div className="Icp Icp_desconectado">
                    {t('desconectado_icp')}
                </div>
            )
        }else{
            return(
                <div className="Icp Icp_ind">
                    {t('no_obt')}
                </div>
            )
        }
    }

