import React, {useContext,useState} from 'react';
import {TextField} from '@material-ui/core';
import {useForm} from "react-hook-form";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import DivGrisTitulo from "../common-components/seccion-titulo";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import {Link, Redirect} from "react-router-dom";
import Axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import {url_api} from "../../axiosConfig";
import {positions, useAlert} from "react-alert";
import RenderHeader from "../common-components/header";
import dedo from "../../img/dedo-ico.png";
import {useTranslation} from "react-i18next";

function ForgetPasswordSolicitarEmail(props) {


    const {register, errors, handleSubmit,setValue} = useForm();
    const [cargando, setCargando] = useState(false);
    const [errors_res, seterrors_res] = useState();
    const alert = useAlert();

    const [formSubmited, setFormSubmited] = useState(false);
    const { t, i18n } = useTranslation();


    const onSubmit = data => {

        setCargando(true)

        /*PRIMERO ACTUALIZO EL STATE CON LOS VALORES DE ESTE FORM, YA ESTARÁN LOS DEL STEP 1*/
        Axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        Axios.defaults.withCredentials = true;
        Axios.post(`${url_api + 'forget_password/create'}`, data).then(res => {
                setCargando(false)
            seterrors_res('')

               /* alert.success("Email enviat")*/;
            alert.show(<div className="Alert Alert_success"><img src={dedo} alt=""/> <span className="pt-1">{t('em_en')}</span></div>, { position: positions.MIDDLE_RIGHT})

            /* setTimeout(() => {
                 props.history.push('/')

             }, 2000)*/
            setValue('login','')
            }).catch((error) => {

            if (error.response) {

                if (error.response.status === 400) {
                    seterrors_res(t('nu'))

                }else{
                    seterrors_res(t('ei'))

                }
            }


            setCargando(false)


        })
    }





        return (
            <>
                <RenderHeader/>

                <DivGrisTitulo tituloBienvenida={null} titulo={t('recuperar_pass')}/>

                <div className=" Padding-seccion-principal">
                    <div   className="White-card ">
                        <CssBaseline/>
                        <div className="Signup-div">
                            <Typography component="h3" variant="h4" className="mt-3 text-center">
                                {t('indica')}
                            </Typography>
                            <Typography component="p" variant="subtitle1" className="my-4 text-center d-block ">
                                {t('enviaremos_mensaje')}

                            </Typography>



                            <form onSubmit={handleSubmit(onSubmit)} className="Form-signup">
                                <div className="Row-flex w-100">

                                    <div className=" w-100 d-flex align-items-center pb-0 Padding-movil p-relative"
                                    >
                                        <TextField className={`mt-0 T-uppercase ${errors.login ? 'Error-input-border NoRadius' : 'Vacio'}`}  name="login" id="login"
                                                   autoFocus fullWidth
                                                   margin="normal"
                                                   label={t('n_documento')}
                                                   variant="outlined"

                                                   inputRef={ register({
                                                       required: t('co'),
                                                       pattern : {
                                                           value : /^(X(-|\.)?0?\d{7}(-|\.)?[a-zA-Z]|[a-zA-Z](-|\.)?\d{7}(-|\.)?[0-9a-zA-Z]|\d{8}(-|\.)?[a-zA-Z])$/,
                                                           message: t('finc')
                                                       }
                                                   })} />
                                        <small className="P-absolute-0">{t('cif1')}</small>

                                        <FormHelperText style={{'position':'absolute','bottom':'-20px','color':'red'}}>
                                            {errors.login && errors.login.message}
                                        </FormHelperText>
                                    </div>

                                </div>
                                <button
                                    type="submit"
                                    className="Btn Btn_green w-100 mw-100"
                                >
                                    {cargando ? <CircularProgress style={{'color': '#fff'}}/> : 'Enviar'}
                                </button>
                                <p className="text-danger text-center mt-3">{errors_res}</p>
                            </form>

                            <Grid container className="mt-4">
                                <Grid item xs>
                                    <Link to='/' >
                                        {t('cancelar')}

                                    </Link>
                                </Grid>

                            </Grid>

                        </div>

                    </div>

                </div>
            </>

        );

}

export default ForgetPasswordSolicitarEmail;