import {ResponsiveLine} from '@nivo/line'
import React, {useEffect} from 'react';
import moment from "moment";
import { useTranslation } from 'react-i18next';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export function ChartDias(props) {
    const { t } = useTranslation();
    return (
        <ResponsiveLine
            data={props.data}
            margin={{top: 50, right: 110, bottom: 50, left: 60}}
            xScale={{
                type: "time",
                format: "%Y-%m-%d"
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
                type: "linear",
                min: 0,
                max: "auto",
                stacked: false,
                reverse: false
            }}

            curve="monotoneX"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickSize: 11,
                tickPadding: 2,
                tickRotation: -25,
                legend: t('dias'),
                legendOffset: 45,
                legendPosition: 'middle',
                format: "%d-%m",
                tickValues:"every day"
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: d => `${d.toString().replace('.',',')}`,//para ponerle la ,
                legend: 'kWh',
                legendOffset: -50,
                legendPosition: 'middle'
            }}
            tooltip={e => {
                return (
                    <div
                        style={{
                            background: "white",
                            padding: "9px 12px",
                            borderLeft: "3px solid #060155",
                            /*borderLeft: e.point.data.facturada ? "3px solid #060155" : "3px solid red",*/
                            color: "#000",
                            fontSize: "17px",
                            textAlign:'left',
                        }}
                    >
                        <small style={{'display':'block','textAlign':'left'}}> {moment(e.point.data.xFormatted).format('DD-MM-YYYY')}</small>

                        <span style={{'fontWeight':'600','display':'block','textAlign':'left'}}>{(e.point.data.y).toString().replace('.',',')}kWh</span>

                      {/*  {e.point.serieId == t('energia') && <> <small style={{'display':'block','textAlign':'left'}}> <b>E.Neta: </b> {(e.point.data.energiaNeta).toString().replace('.',',').slice(0, -1)}kWh</small>
                            <small style={{'display':'block','textAlign':'left'}}> <b>Excedent: </b>{(e.point.data.excedent).toString().replace('.',',').slice(0, -1)}kWh</small>
                        </>}*/}

                        {e.point.serieId == t('energia') && <> <small style={{'display':'block','textAlign':'left'}}> <b>{t('energia')} </b> </small></>}
                        {e.point.serieId == t('eneta') && <> <small style={{'display':'block','textAlign':'left'}}> <b>{t('eneta')} </b> </small></>}
                        {e.point.serieId == t('excedent') && <> <small style={{'display':'block','textAlign':'left'}}> <b>{t('excedent')} </b> </small></>}
                        {e.point.serieId == t('eexportada') && <> <small style={{'display':'block','textAlign':'left'}}> <b>{t('eexportada')} </b> </small></>}
                        <small style={{'display':'block','textAlign':'left'}}> {e.point.data.facturada ? <span><b> Facturada </b></span>: <span><b>No Facturada</b></span>}</small>

                        {/* <br/>
                        <span> Consum Real</span>*/}
                      {/*  <br/>

                        {e.point.data.facturada ? <span> Facturada</span> : <span>NO Facturada</span>}
*/}
                    </div>
                );
            }}
            onClick={(point, event) => {
                //llamo a la funcion padre pasandole la fecha del punto clickado para general el gráfico en el modal
                props.generar_grafico_modal(point.data.xFormatted)
            }
            }
            colors={d => d.color}

            legends={[
               {
                   anchor: 'bottom-right',
                   direction: 'column',
                   justify: false,
                   translateX: 175,
                   translateY: 0,
                   itemsSpacing: 0,
                   itemDirection: 'left-to-right',
                   itemWidth: 163,
                   itemHeight: 20,
                   itemOpacity: 0.75,
                   symbolSize: 6,
                   symbolShape: 'circle',
                   symbolBorderColor: 'rgba(0, 0, 0, .5)',
                   effects: [
                       {
                           on: 'hover',
                           style: {
                               itemBackground: 'rgba(0, 0, 0, .03)',
                               itemOpacity: 1
                           }
                       }
                   ]
               }
           ]}
            pointSize={11}
            pointBorderColor={'#060155'}

            pointBorderWidth={1}

            pointSymbol={e => {

                let colorPunto = e.datum.facturada ? 'rgba(6,1,85,0.58)' : 'white'
                let colorBorde = 'rgb(6,1,85)'


                return (
                        <circle cx="0" cy="0" r="5" stroke={colorBorde} strokeWidth="2" fill={colorPunto} />
                );

            }}
            pointLabelYOffset={0}
            enableArea={true}
            areaOpacity={0.1}
            areaBaselineValue={0}
            useMesh={true}
        />
    )
}

export function ChartHoras(props) {
    const { t } = useTranslation();

    function formatHour(hour) {
        if (parseInt(hour) < 10) {
            return '0' + hour;
        } else {
            return hour
        }
    }

    return (
        <ResponsiveLine
            data={props.data}
            margin={{top: 50, right: 110, bottom: 50, left: 60}}
            xScale={{type: 'point'}}
            yScale={{
                type: "linear",
                min: 0,
                max: "auto",
                stacked: false,
                reverse: false
            }}
            curve="monotoneX"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickSize: 11,
                tickPadding: 2,
                tickRotation: -25,
                legend: t('horas'),
                legendOffset: 45,
                legendPosition: 'middle',
               /* legend: '',
                legendOffset: 40,
                legendPosition: 'middle',*/
                /*format: d => `${d}h - ${d+1}h`*/
                format: d => `${formatHour(d)}:00h`

            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: d => `${d.toString().replace('.',',')}`,//para ponerle la ,
                legend: 'kWh',
                legendOffset: -55,
                legendPosition: 'middle'
            }}
            /* colors={{scheme: 'paired'}}*/

            tooltip={e => {
                let txt_tar=''

                if((props.tarifa == '2.0DHA' ||props.tarifa == '2.1DHA') && e.point.data.periode ==='1'){
                    txt_tar = ' Punta'
                }
                if((props.tarifa == '2.0DHA' ||props.tarifa == '2.1DHA') && e.point.data.periode ==='2'){
                    txt_tar = ' - Vall'
                }
                return (
                    <div
                        style={{
                            background: "white",
                            padding: "9px 12px",
                            borderLeft: "3px solid #060155",
                            /*borderLeft: e.point.data.facturada ? "3px solid #060155" : "3px solid red",*/
                            color: "#000",
                            fontSize: "17px",
                            textAlign:'left',
                        }}
                    >
                        <small style={{'display':'block','textAlign':'left'}}> {formatHour(e.point.data.x)}:00h</small>

                        <span style={{'fontWeight':'600','display':'block','textAlign':'left'}}>{(e.point.data.y).toString().replace('.',',')}kWh</span>
                        {e.point.serieId == t('energia') && <> <small style={{'display':'block','textAlign':'left'}}> <b>{t('energia')} </b> </small></>}
                        {e.point.serieId == t('eneta') && <> <small style={{'display':'block','textAlign':'left'}}> <b>{t('eneta')} </b> </small></>}
                        {e.point.serieId == t('excedent') && <> <small style={{'display':'block','textAlign':'left'}}> <b>{t('excedent')} </b> </small></>}
                        {e.point.serieId == t('eexportada') && <> <small style={{'display':'block','textAlign':'left'}}> <b>{t('eexportada')} </b> </small></>}

                        <small style={{'display':'block','textAlign':'left'}}><b> {t('tram_horari')}: {e.point.data.periode} {txt_tar} </b></small>

                    </div>
                );
            }}

            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 175,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 163,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 6,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            colors={d => d.color}
            pointSize={11}
            pointBorderColor={'#060155'}
            pointColor={'rgba(6,1,85,0.58)'}
            pointSymbol={e => {

                let colorPunto = ''
                let colorBorde = ''

                switch (e.datum.periode) {
                    case '1':
                        colorPunto = e.datum.facturada ? 'rgba(6,1,85,0.58)' : 'white'
                        colorBorde = 'rgb(6,1,85)'

                        break;
                    case '2':
                        colorPunto = e.datum.facturada ? 'rgba(107,167,156,0.85)' : 'white'
                        colorBorde = 'rgba(42,146,77,1)'
                        break;

                    case '3':
                        colorPunto = e.datum.facturada ? 'rgba(0,156,159,0.75)' : 'white'
                        colorBorde = 'rgba(0,156,159,1)'
                        break;
                    case '4':
                        colorPunto = e.datum.facturada ? 'rgba(30,30,30,0.8)' : 'white'
                        colorBorde = '#1E1E1E'
                        break;
                    case '5':
                        colorPunto = e.datum.facturada ? 'rgba(133,132,133,0.98)' : 'white'
                        colorBorde = '#858485'
                        break;
                        case '6':
                        colorPunto = e.datum.facturada ? 'rgba(189,90,185,0.58)' : 'white'
                        colorBorde = 'rgb(189,90,185)'
                        break;
                    default:
                        colorPunto = e.datum.facturada ? 'rgba(6,1,85,0.58)' : 'white'
                        colorBorde = 'rgba(6,1,85,0.58)'
                        break;
                }

                return (
                    <circle cx="0" cy="0" r="5" stroke={colorBorde} strokeWidth="2" fill={colorPunto} />
                );

            }}

            pointBorderWidth={1}
            pointLabel="y"
            pointLabelYOffset={-12}
            enableArea={true}
            areaOpacity={0.1}
            areaBaselineValue={0}
            useMesh={true}
        />
    )
}



