import React, {useState} from "react";
import "./style.scss";
import {faHome, faPaperclip} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

function CardMenu(props) {

    return (
        <Link to={props.link} className="col-12 col-sm-6 col-md-4  p-1 mb-1">
            <div className="CardMenu " >
                <div>
                    {props.children}

                </div>
                <span>
                    {props.title}
                </span>
                <div className="OnHoverText">
                   {props.description}
                </div>
            </div>



        </Link>
    );
}
CardMenu.defaultProps = {
    link: "/home",
}
export default CardMenu;
