import React, {useContext, useEffect, useState} from "react";
import {faHome} from '@fortawesome/free-solid-svg-icons'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, Redirect, withRouter} from "react-router-dom";
import logo from "../../../img/electra-logo-w.png";

import "./style.scss";
import {AuthDataContext} from "../../../context/auth-context";
import SimpleMenu from "../desplegable/menu-nav";

import usu from "../../../img/user-b.png";
import campana from "../../../img/campana-b1.png";
import cruz from "../../../img/salir.png";
import back from "../../../img/back.png";
import goweb from "../../../img/goweb.png";
import {useTranslation} from 'react-i18next';

import DesplegableIdioma from "../desplegable/idioma";
import {useLocation} from 'react-router-dom'

function RenderHeader(props) {
    const {state} = useContext(AuthDataContext);
    let location = useLocation();

    const [renderBack, setrenderBack] = useState(false)
    const [renderGoHome, setrenderGoHome] = useState(false)

    function getBackUrl() {

        switch (location.pathname) {
            case "/mi-consumo":
                props.history.push('/home')

                break;

            case "/potencia-maxima":
                props.history.push('/home')

                break;
            case "/grafico-consumo":
                props.history.push('/mi-consumo')

                break;
            case "/grafico-contador":
                props.history.push('/contador-online')

                break;
            case "/grafico-potencia":
                props.history.push('/potencia-maxima')

                break;
            case "/perfil":
                props.history.push('/home')

                break;
            case "/contador-online":
                props.history.push('/home')

                break;
            case "/recuperar-contrasenya-email":
                props.history.push('/')

                break;
            case "/registrarse":
                props.history.push('/')

                break;
        }

    }

    /*EL HEADER HABRÁ QUE PONERLO EN CADA COMPONENTE PAGE Y NO EN EL APP.js PARA QUE ESTE USEEFFECT SE USE AL NAVEGAR
    * POR CUALQUIER PAGINA*/
    useEffect(() => {
        switch (location.pathname) {
            case "/mi-consumo":
                setrenderBack(true)
                setrenderGoHome(true)

                break;
            case "/potencia-maxima":
                setrenderBack(true)
                setrenderGoHome(true)

                break;
            case "/contador-online":
                setrenderBack(true)
                setrenderGoHome(true)

                break;
            case "/grafico-consumo":
                setrenderBack(true)
                setrenderGoHome(true)


                break;
            case "/grafico-potencia":
                setrenderBack(true)
                setrenderGoHome(true)


                break;
            case "/grafico-contador":
                setrenderBack(true)
                setrenderGoHome(true)


                break;
            case "/perfil":
                setrenderBack(true)
                setrenderGoHome(true)

                break;
            case "/registrarse":
                setrenderBack(true)


                break;
            case "/recuperar-contrasenya-email":
                setrenderBack(true)


                break;
            case "/":
                setrenderGoHome(true)

                break;
            default:
                setrenderBack(false)
                setrenderGoHome(false)
                break;

        }
    }, [props.history])


    if (state.token) {
        return <Header getBackUrl={getBackUrl} renderBack={renderBack} renderGoHome={renderGoHome}/>

    } else {
        return <HeaderNoLogued getBackUrl={getBackUrl} renderBack={renderBack} renderGoHome={renderGoHome}/>

    }
}


function Header(props) {
    const {state, dispatch} = useContext(AuthDataContext);

    const {t, i18n} = useTranslation();

    function logout() {
        dispatch({
            type: 'logout',
        });
        return <Redirect to='/'/>;

    }

    return (
        <>


            <header className=" Header Bg-dark-blue LogHeader">
                <img src={logo} alt="" className="Logo Logo-menu-login ml-4"/>

                <div className="container">

                    <div className="Menu-azul container">
                        <div className="Menu-Nav Escritorio">
                            <nav className="container d-flex align-items-center">
                                {props.renderGoHome && <Link to='/home'>
                                    <FontAwesomeIcon title={t('inicio')} icon={faHome} style={{'fontSize': '22px'}}
                                                     color="#fff"/>
                                </Link>}
                                {/* <Link to='/home'>
                    ELS TEUS SUMINISTRAMENTS
                </Link>
                <Link to='/home'>
                    AUTORITZACIONS
                </Link>*/}
                                <div className="d-flex align-items-center">
                                    <SimpleMenu openElement={t('serveis')}/>


                                </div>

                            </nav>
                        </div>

                        {/*ESCRITRIO*/}
                        <div className="Escritorio Row-datos text-right  d-flex align-items-center">

                            {/*   <FontAwesomeIcon icon={faBell} color="#fff"/>*/}
                            <img title="Notificacions" src={campana} style={{'width': '40px'}} className="Ico-p "
                                 alt='ico'/>

                            <Link className="mx-4 d-flex align-items-center" to='/perfil'
                                  style={{'textDecoration': 'none'}}>
                                {/* <FontAwesomeIcon className="mr-2" icon={faAddressCard} color="#fff"/>*/}
                                <img title="El meu perfil" src={usu} className="Ico-p mr-1" alt='ico'/>

                                <span>
                                {state.user.tipo == 'representante' ? state.user.razon_social : state.user.nombre + ' ' + state.user.apellidos}
                             </span>
                            </Link>

                            {props.renderBack &&
                            <div className="d-inline ml-2" style={{'cursor': 'pointer'}} onClick={props.getBackUrl}>
                                <img src={back} title={t('tornar')} className="Ico-p mr-1" alt='ico'/>

                            </div>}
                            <div className="d-inline ml-2" style={{'cursor': 'pointer'}} onClick={logout}>
                                {/*    <FontAwesomeIcon icon={faPowerOff} color="#fff"/>*/}
                                <img title="Tancar sesió" src={cruz} className="Ico-p mr-1" alt='ico'/>

                            </div>
                            <div className="d-inline ml-2" style={{'cursor': 'pointer'}}>

                                <DesplegableIdioma/>
                            </div>

                        </div>
                        {/*END*/}

                        {/*MOVIL*/}
                        <div className="Movil-menu Row-datos text-right  d-flex align-items-center">

                            {/*   <FontAwesomeIcon icon={faBell} color="#fff"/>*/}
                            <div className="D-f-menu">
                                <img title="Notificacions" src={campana} style={{'width': '40px'}} className="Ico-p "
                                     alt='ico'/>

                                <Link className="mx-4 d-flex align-items-center" to='/perfil'
                                      style={{'textDecoration': 'none'}}>
                                    {/* <FontAwesomeIcon className="mr-2" icon={faAddressCard} color="#fff"/>*/}
                                    <img title="El meu perfil" src={usu} className="Ico-p mr-1" alt='ico'/>

                                    <span>
                                {state.user.tipo == 'representante' ? state.user.razon_social : state.user.nombre + ' ' + state.user.apellidos}
                             </span>
                                </Link>

                            </div>
                            <div className="D-f-menu">
                                <div className="Menu-Nav ">
                                    <nav className="container d-flex align-items-center">
                                        {props.renderGoHome && <Link to='/home'>
                                            <FontAwesomeIcon title={t('inicio')} icon={faHome}
                                                             style={{'fontSize': '22px'}}
                                                             color="#fff"/>
                                        </Link>}
                                        {/* <Link to='/home'>
                    ELS TEUS SUMINISTRAMENTS
                </Link>
                <Link to='/home'>
                    AUTORITZACIONS
                </Link>*/}
                                        <div className="d-flex align-items-center">
                                            <SimpleMenu openElement={t('serveis')}/>


                                        </div>

                                    </nav>
                                </div>

                                {props.renderBack &&
                                <div className="d-inline ml-2" style={{'cursor': 'pointer'}} onClick={props.getBackUrl}>
                                    <img src={back} title={t('tornar')} className="Ico-p mr-1" alt='ico'/>

                                </div>}
                                <div className="d-inline ml-2" style={{'cursor': 'pointer'}} onClick={logout}>
                                    {/*    <FontAwesomeIcon icon={faPowerOff} color="#fff"/>*/}
                                    <img title="Tancar sesió" src={cruz} className="Ico-p mr-1" alt='ico'/>

                                </div>
                                <div className="d-inline ml-2" style={{'cursor': 'pointer'}}>

                                    <DesplegableIdioma/>
                                </div>

                            </div>

                        </div>
                        {/*END*/}

                    </div>
                </div>

            </header>

        </>
    );
}

function HeaderNoLogued(props) {
    const {t, i18n} = useTranslation();

    return (
        <>
            <header className=" Header Bg-dark-blue LogHeader">

                <div className="container">
                    <div className="row ">
                        <div className="col-12 text-left Header-menu">

                            <img src={logo} alt="" className="Logo ml-4"/>

                            <div className="mr-md-5 d-flex ">
                                {props.renderBack &&
                                <div className="d-inline mr-2" style={{'cursor': 'pointer'}} onClick={props.getBackUrl}>
                                    <img title={t('tornar')} src={back} className="Ico-p mr-1" alt='ico'/>

                                </div>}
                                {props.renderGoHome && <a href="https://electracaldensedistribucio.com" target="_blank"
                                                          className="position-relative d-flex align-items-center mx-md-2">
                                    <img title={t('tornar')} src={goweb} className="Ico-p " alt='ico'/>
                                </a>}
                                <DesplegableIdioma/>

                                {/*<FormattedMessage id="app.title" className="Txt-green">{(m) => {return(<h1>m</h1>)}} </FormattedMessage>
*/}
                            </div>
                        </div>
                    </div>
                </div>

            </header>
        </>
    )
}


export default withRouter(RenderHeader);