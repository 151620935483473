import React, {useContext} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import {Link} from "react-router-dom";
import RegisterFormStep1 from "./step1";
import {SignUpFormContext} from "../../../context/signup-form-context";
import RegisterFormStep2_Particular from "./step2_particular";
import './style.scss';
import RegisterFormStep2_Representante from "./step2_representante";
import logo from "../../../img/electra-logo-w.png";
import Typography from "@material-ui/core/Typography";
import {Footer} from "../../common-components/footer";
import DesplegableIdioma from "../../common-components/desplegable/idioma";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {RenderHeader} from "../../common-components/header";
import DivGrisTitulo from "../../common-components/seccion-titulo";
import {useTranslation} from "react-i18next";


export default function SignupFormRender() {
    const {state} = useContext(SignUpFormContext);
    const { t, i18n } = useTranslation();


    const renderStep = () => {
        switch (state.step) {
            case 1:
                return <RegisterFormStep1/>;

            case 2:
                if(state.tipo === 'representante'){
                    return <RegisterFormStep2_Representante/>
                }else{
                    return <RegisterFormStep2_Particular/>

                }
        }
    }

    return (
        <>
            <DivGrisTitulo tituloBienvenida={null} titulo={t('registro')}/>

            <div className=" Padding-seccion-principal">
               {/*{JSON.stringify(state)}*/}
                <div   className="White-card ">
                    <CssBaseline/>
                    <div className="Signup-div">
                        {/*  <Avatar className='Logo-login mx-auto'>
                        <LockOutlinedIcon/>
                    </Avatar>*/}



                        {/*  <TextField autoFocus fullWidth margin="normal" label="Email Address" variant="outlined" inputRef={register()} required  name="FirstName"/> */}

                        {renderStep()}


                        <Grid container className="mt-4">
                            <Grid item xs>
                                <Link to='/' >
                                    {t('cancelar')}

                                </Link>
                            </Grid>

                        </Grid>
                    </div>

                </div>

            </div>
            </>
    );
}