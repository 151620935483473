import React from 'react';
import MUIDataTable from "mui-datatables";
import {Link, Redirect} from "react-router-dom";
import './style.scss'
import {useTranslation} from "react-i18next";

function Datatable(props) {
    const {t} = useTranslation();


    let options_general = {
        print: false,
        textLabels: {
            pagination: {
                next: t('siguiente'),
                previous: t('pp'),
                rowsPerPage: t('filas'),
                displayRows: 'de' // 1-10 of 30
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: t('descargar') + "CSV",
                viewColumns: t('vc'),
                filterTable: t('ft')
            },
            body: {
                noMatch: t('no_results')
            },
            viewColumns: {
                title: t('columnas'),
                titleAria: "Mostrar/Ocultar "+t('columnas'),
            },
            filter: {
                all: t('tots'),
                title: t('filtros'),
                reset: t('reset'),
            },

        }
    }

   let options = props.options
   let options_final=  {...options_general, ...options}


    return (
        <>
            <MUIDataTable
                title={props.titulo}
                data={props.data}
                columns={props.columns}
                options={options_final}
            />
        </>
    );
}

export default Datatable;