import React from 'react';
import {makeStyles} from '@material-ui/core/styles';


import { useTranslation } from 'react-i18next';
import {
    ChartPotenciaMaximaMesLineal
} from "../../common-components/charts/potencia-maxima-chart";




const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'none',
    },
}));

export default function TabGraficoTabla(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const { t, i18n } = useTranslation();


    const handleChangePotencia = (e) => {
        props.setpotenciaPorFiltro(e.target.value)
    }


    if (props.data_grafico.length<1) {
        return (<h2 className="text-center mt-5">{t('nodadesf')}</h2>)
    } else {
        return (
            <div className={`${classes.root} Tabfiltro Z-index-2`}>

                    <div className="">

                        <p className="my-4">
                           <span className="Txt-green" style={{'fontSize':'22px','fontWeight':'bold'}}>
                              {t('representacion-p')}:
                           </span>
                            <br/>
                            <span className="D-bm" style={{'fontSize': '13px'}}>
                                {t('pulsar-p')}

</span>
                        </p>

           {/*             <span className="Energia-total"><b>{t('valor_maximo_registrado')} {props.valor_maxim.energia.toString().replace('.',',')} kWh el {t('dia')} { moment(props.valor_maxim.fecha_completa).format('DD-MM-YYYY')} a {t('las')} {intToHour(props.valor_maxim.hora)}h </b></span>
*/}
                        <div className="  Grafico-div  p-relative mt-md-5" style={{'width':'95vw'}}>
                         {/*   <FormControl className="mt-2 Filtro-pot" fullWidth>
                                <InputLabel>
                                    <p className="pl-0 pt-0">Potencia Por Periodo</p>
                                </InputLabel>
                                <Select required onChange={handleChangePotencia} defaultValue={props.potencia1}  name="pot"  fullWidth >

                                    <MenuItem value={props.potencia1} >
                                        {props.potencia1} kWh
                                    </MenuItem>
                                    {props.potencia2 && <MenuItem value={props.potencia2}>  {props.potencia2} kWh</MenuItem>}
                                    {props.potencia3 && <MenuItem value={props.potencia3}>  {props.potencia3} kWh</MenuItem>}
                                    {props.potencia4 && <MenuItem value={props.potencia4}>  {props.potencia4} kWh</MenuItem>}
                                    {props.potencia5 && <MenuItem value={props.potencia5}>  {props.potencia5} kWh</MenuItem>}
                                    {props.potencia6 && <MenuItem value={props.potencia6}>  {props.potencia6} kWh</MenuItem>}

                                </Select>


                            </FormControl>*/}
                            {/*<span className="Titulo-grafico"><b>kWh - dies</b></span>*/}
                            <ChartPotenciaMaximaMesLineal
                                tarifa={props.tarifa}
                                potencia_contratada={props.potencia_contratada}
                             data={props.data_grafico}/>

                        </div>


                    </div>
                    {/* <MyResponsiveLine data={props.data_grafico}/>*/}




            </div>
        );
    }


}