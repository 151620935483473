import React, {useEffect} from 'react';

import {ResponsivePie} from "@nivo/pie";
import {ResponsiveBar} from "@nivo/bar";
import {ResponsiveBullet} from "@nivo/bullet";
import {ResponsiveLine} from "@nivo/line";




export function ContadorChart2(props) {
   let potencia = ( parseFloat(props.datos['@Pimp']))/1000;



    return(
        <ResponsiveBullet
            data={[
                {
                    "id": "",
                    "ranges": [
                        potencia,
                        parseFloat(props.potencia_contratada)
                    ],
                    "measures": [
                        potencia
                    ],
                    "markers": [
                        parseFloat(props.potencia_contratada)
                    ]
                },

            ]}

            margin={{ top: 50, right: 90, bottom: 50, left: 90 }}
            spacing={28}
            titleAlign="start"
            titleOffsetX={-70}
            measureSize={1}
            rangeColors={['#e3e3e3']}
            measureColors={['#09008b','blue']}
            animate={true}
            motionStiffness={90}
            motionDamping={12}

            markerSize={1}
            markerColors={['#6ba79c']}
        />
    )
}