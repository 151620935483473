import React, {Component, useContext, useEffect, useState} from 'react';

import CardMenu from "../home-page/card-menu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faChartBar, faHome, faWifi} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Datatable from "../common-components/datatables/datatable";
import {Footer} from "../common-components/footer";
import Axios from "axios";
import {catchApiErrors, expulsarIfTokenInvalido, getLocalStorageData} from "../../helpers/auth-helpers";
import {AuthDataContext} from "../../context/auth-context";
import CircularProgress from "@material-ui/core/CircularProgress";
import DivGrisTitulo from "../common-components/seccion-titulo";
import RenderHeader from "../common-components/header";
import {BackdropCargandoContext} from "../../context/backdrop-cargando-context";
import { useTranslation } from 'react-i18next';
import {useAlert} from "react-alert";
import {returnProvincia} from "../../helpers/generales";
import imges from "../../img/atca.png"
import imgca from "../../img/ates.png"

function ContadorOnline(props) {

    const [FiltroCorbes, setFiltroCorbes] = useState();
    const {state} = useContext(AuthDataContext);
    const {backdrop_state,backdrop_dispatch} = useContext(BackdropCargandoContext);

    const [dataDatatable, setdataDatatable] = useState();
    const { t, i18n } = useTranslation();
    const alert = useAlert();


    function handleChange() {

    }

    const columns = [
        {
            name: "CUPS",
            label: "CUPS",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "TARIFA",
            label: t('te'),
            options: {
                filter: true,
                sort: false,


            }

        },
        {
            name: "POTENCIA",
            label: t('pc2'),
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {

                    return (
                       <> {value.replace('.',',')} kWh</>
                    );
                }
            },

        },
        {
            name: "DOMICILI",
            label: t('direccion'),
            options: {
                filter: true,
                sort: false,

            },

        },
        {
            name: "poblacio",
            label: t('poblacion'),
            options: {
                filter: true,
                sort: false,

            }
        },
        {
            name: "codi_post",
            label: "COD POSTAL",
            options: {
                filter: true,
                sort: false,
                display: false,

            }
        },
        {
            name: "provincia",
            label: t('provincia'),

            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <> {returnProvincia(tableMeta.rowData[5])}</>
                    );
                }
            }
        },
        {
            name: "grafico",
            label: t('curvas'),
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                /*SI LA TARIFA ES DIEFERENTE A 2.0A, 2.1A, 2.0DHA i 2.1DHA no muestr el boton*/

                    //if(tableMeta.rowData[1] != '2.0A' && tableMeta.rowData[1] != '2.1A' && tableMeta.rowData[1] != '2.0DHA' && tableMeta.rowData[1] != '2.1DHA' ){
                    if(tableMeta.rowData[1] != '2.0TD'){
                        return (
                            <button type="button"   className="d-inline Btn-transparent-border-green-disabled Btn-small" >

                                {t('consultar_contador')}

                            </button>
                        );
                    }else{
                        return (
                            <Link to={{
                                pathname: '/grafico-contador',
                                state: {"cups": tableMeta.rowData[0], "potencia_contratada": tableMeta.rowData[2], "direccion": tableMeta.rowData[3] +', '+ tableMeta.rowData[4] +', '+ tableMeta.rowData[5] +', '+ returnProvincia(tableMeta.rowData[5]) }
                            }} className="Btn-transparent-border-green Btn-small">

                                {t('consultar_contador')}

                            </Link>
                        );
                    }

                }
            },

        }
    ];
    const options = {
        selectableRows: 'none',
        responsive:false
    }
    useEffect(() => {
        backdrop_dispatch({
            type: 'open',
            data: {}
        });
        Axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(getLocalStorageData('token'))}`;
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.defaults.withCredentials = true;
        Axios.post(`https://api.zona-privada.electracaldensedistribucio.com/api/contador/get_peatges_no_historico`, {uuid: state.user.uniqid})
            .then(res => {

                setdataDatatable(res.data.data)
                backdrop_dispatch({
                    type: 'close',
                    data: {}
                });
            }).catch(function (error) {
            catchApiErrors(error,backdrop_dispatch,t,alert)
        })

    }, [])


    return (
        <>
            <RenderHeader/>

            <DivGrisTitulo style={{'textTransform':'uppercase'}} tituloBienvenida={false} titulo={t('contador_titulo')}/>

            <div className="El-teu-consum Padding-seccion-principal">
                <div className=" px-3 container">

                    <div className="row">
                        <div className="col-12 col-md-8 d-flex flex-wrap">

                        {/*    <h2 className=" Titulo2 mb-4">
                                Consulta de corbes de consum
                            </h2>*/}
                            <p>{t('contador_subtitulo')}</p>

                            <div>
                                <h5 className=" Titulo3 mt-5 mb-4">
                                    {t('selecrao')}
                                </h5>
                            </div>
                            <div className="Row-flex Row_input_btn my-2">

                            <FormControl className="mt-0" fullWidth>
                                    <InputLabel>
                                        {t('razon_social')}
                                    </InputLabel>

                                    <Select required onChange={handleChange} name="filtro_corbes" fullWidth
                                            value={FiltroCorbes} defaultValue="nif1">

                                        <MenuItem value="nif1">
                                            {state.user.tipo == 'representante' ? state.user.razon_social : state.user.nombre + ' ' + state.user.apellidos}

                                        </MenuItem>


                                    </Select>


                                </FormControl>
                                <button className="Btn Btn_green Btn-movil" type="button"><span>Aplicar</span></button>

                            </div>

                        </div>
                        <div className="col-12 col-md-4 d-flex flex-column">
                            <div className="d-flex flex-column">


                              {/*  <hr/>*/}
                                <div className=" Caja-gris Inferior-Contador ">
                                    <a href="tel:+34900181776" className="mw-100 mh-100 d-block">
                                        {
                                            getLocalStorageData('lang') === 'es'
                                                ?
                                                <img src={imges} className="w-100 h-100" style={{'objectFit': 'cover'}}
                                                     alt=""/>
                                                :
                                                <img src={imgca} className="w-100 h-100" style={{'objectFit': 'cover'}}
                                                     alt=""/>
                                        }
                                    </a>

                                </div>
                         {/*       <hr/>*/}


                            </div>
                        </div>

                        <div className="col-12 mt-5 Overflow-x-scroll-m">
                           {/* PARA QUE CARGUE EL SPINNER EN LA TABLA
                            {dataDatatable ? <Datatable titulo="Peatges" data={dataDatatable} options={options} columns={columns}/> :
                                <CircularProgress className="mx-auto" disableShrink/>}*/}
                            {!backdrop_state.open && <Datatable titulo={t('peatges')} data={dataDatatable} options={options} columns={columns}/>}
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
}

export default ContadorOnline;