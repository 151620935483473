import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './style.scss';
import {useTranslation} from "react-i18next";
import i18n from '../../../i18n';
import {getLocalStorageData, setLocalStorageData} from "../../../helpers/auth-helpers";

export default function DesplegableIdioma(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [desplegado, setDesplegado] = React.useState(false);
    const { t, i18n } = useTranslation();

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        setDesplegado(true)
    };

    const handleClose = () => {

        setAnchorEl(null);
        setDesplegado(false)

    };

    const changeLang = (lng) => {
        setLocalStorageData(lng,'lang')
        i18n.changeLanguage(lng).then(r => {
            handleClose()
        });

    }

    return (
        <div className="Idioma-div" title={t('desplegable_idioma')}>
            <Button aria-controls="simple-menu-lang" style={{'fontWeight':'700','fontSize':'17px'}} aria-haspopup="true" onClick={handleClick}>
                {getLocalStorageData('lang') ? getLocalStorageData('lang') : 'ca'} &nbsp; {!desplegado ? <FontAwesomeIcon icon={faAngleDown}  size="lg"/> :
                <FontAwesomeIcon icon={faAngleUp}  size="lg"/>}
            </Button>
            <Menu
                id="simple-menu-lang"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem onClick={() => changeLang('ca')} className="Idioma">CA</MenuItem>
                <MenuItem onClick={() => changeLang('es')} className="Idioma">ES</MenuItem>
            </Menu>
        </div>
    );
}