import React, {useContext, useEffect, useState} from 'react';

import 'chart.js'
import TabGraficoFiltro from "./tab-filtro";
import TabGraficoTabla from "./tab-grafico-tabla";
import Axios from "axios";
import {catchApiErrors, expulsarIfTokenInvalido, getLocalStorageData} from "../../../helpers/auth-helpers";
import {AuthDataContext} from "../../../context/auth-context";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Redirect} from 'react-router-dom';

import './style.scss';
import {url_api} from "../../../axiosConfig";
import DivGrisTitulo from "../../common-components/seccion-titulo";
import RenderHeader from "../../common-components/header";
import {BackdropCargandoContext} from "../../../context/backdrop-cargando-context";
import {useTranslation} from 'react-i18next';
import {useAlert} from "react-alert";
import moment from "moment";


function GraficoPagePotencia(props) {


    const {state} = useContext(AuthDataContext);
    const {backdrop_dispatch} = useContext(BackdropCargandoContext);
    const alert = useAlert();

    const [dataGrafico, setdataGrafico] = useState();
    const [potenciaPorFiltro, setpotenciaPorFiltro] = useState(props.location.state.potencia_contratada);//POTENCIA QUE CMABIARA SEGUN EL FILTRO DE PERIODO
    const [filtroTramo, setfiltroTramo] = useState('tots');//POTENCIA QUE CMABIARA SEGUN EL FILTRO DE PERIODO
    const [chartLoading] = useState(false);
    const [valorMaxim, setvalorMaxim] = useState(false);
    const [tipoGrafico] = useState('dias');
    const {t} = useTranslation();

    /*al cargar por primera vez cargo la grafica de hoy*/
    useEffect(() => {

        /*  getDataGraficoTabla(null, null, moment().subtract(1, 'days').format('YYYY-MM-DD'), 'days');*/ //PARA CARGAR GRAFICO DEL DIA ANTERIOR POR DEFECTO
    }, [])

     function crearObjetoParaGrafico(param_data){

        let array_grafico = []
        let maximetre1 = {
            id : "Max.1",
            color: "rgba(6,1,85,0.58)",
            data : []
        };
        let maximetre2 = {
            id : "Max.2",
            color: "rgba(42,146,77,1)",
            data : []
        };
        let maximetre3 = {
            id : "Max.3",
            color: "rgb(159,98,57)",
            data : []
        };
        let maximetre4 = {
            id : "Max.4",
            color: "rgba(30,30,30,0.8)",
            data : []
        };
        let maximetre5 = {
            id : "Max.5",
            color: "rgba(133,132,133,0.98)",
            data : []
        };
        let maximetre6 = {
            id : "Max.6",
            color: "rgba(189,90,185,0.58)",
            data : []
        };

        param_data.forEach(
            (data) => {

                let data_split = data.dataActual.split('-');
                let fecha = data_split[0]+'-'+data_split[1]

                let obj1 = {
                    x : fecha,
                    y : !isNaN(parseFloat(data.valor_maxim)) ? parseFloat(data.valor_maxim) : 0,
                    maximetre: 1
                }
                let obj2 = {
                    x : fecha,
                    y : !isNaN(parseFloat(data.valor_maxim2)) ? parseFloat(data.valor_maxim2) : 0,
                    maximetre: 2
                }

                let obj3 = {
                    x : fecha,
                    y : !isNaN(parseFloat(data.valor_maxim3)) ? parseFloat(data.valor_maxim3) : 0,
                    maximetre: 3
                }

                let obj4 = {
                    x : fecha,
                    y : !isNaN(parseFloat(data.valor_maxim4)) ? parseFloat(data.valor_maxim4) : 0,
                    maximetre: 4
                }

                let obj5 = {
                    x : fecha,
                    y : !isNaN(parseFloat(data.valor_maxim5)) ? parseFloat(data.valor_maxim5) : 0,
                    maximetre: 5
                }

                let obj6 = {
                    x : fecha,
                    y : !isNaN(parseFloat(data.valor_maxim6)) ? parseFloat(data.valor_maxim6) : 0,
                    maximetre: 6
                }

                if(filtroTramo!='tots'){
                    if(filtroTramo!=1)  obj1.y = 0
                    if(filtroTramo!=2)  obj2.y = 0
                    if(filtroTramo!=3)  obj3.y = 0
                    if(filtroTramo!=4)  obj4.y = 0
                    if(filtroTramo!=5)  obj5.y = 0
                    if(filtroTramo!=6)  obj6.y = 0
                }
                if(obj1.y!==0){
                    maximetre1.data.push(obj1)
                }
                if(obj2.y!==0){
                    maximetre2.data.push(obj2)
                }
                if(obj3.y!==0){
                    maximetre3.data.push(obj3)
                }
                if(obj4.y!==0){
                    maximetre4.data.push(obj4)
                }
                if(obj5.y!==0){
                    maximetre5.data.push(obj5)
                }
                if(obj6.y!==0){
                    maximetre6.data.push(obj6)
                }



            }
        )

        setTimeout( () => {

            if(maximetre1.data.length>1) array_grafico.push(maximetre1)
            if(maximetre2.data.length>1) array_grafico.push(maximetre2)
            if(maximetre3.data.length>1) array_grafico.push(maximetre3)
            if(maximetre4.data.length>1) array_grafico.push(maximetre4)
            if(maximetre5.data.length>1) array_grafico.push(maximetre5)
            if(maximetre6.data.length>1) array_grafico.push(maximetre6)

            setdataGrafico(array_grafico)
            setTimeout(() => {
                backdrop_dispatch({
                    type: 'close',
                    data: {}
                });
            }, 500)
        },1000)

    }

    //funcion que pasaré al component filtro para actualizar las fechas con el filtro
    function getDataGraficoTabla(fecha_actual_param = null, fecha_filtro_param = null) {

        let data_post = {
            uuid: state.user.uniqid,
            cups: props.location.state.cups,
            fecha_actual: fecha_actual_param,
            fecha_filtro: fecha_filtro_param,
            periodo: filtroTramo
        };


        setdataGrafico(null)

        backdrop_dispatch({
            type: 'open',
            data: {}
        });

        Axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(getLocalStorageData('token'))}`;
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.defaults.withCredentials = true;
        Axios.post(`${url_api + 'potencia_maxima/get_corbes_por_mes_nuevo'}`, data_post)
            .then(res => {


                if (res.data.length < 1) {

                    setdataGrafico('vacio')
                    setTimeout(() => {
                        backdrop_dispatch({
                            type: 'close',
                            data: {}
                        });
                    }, 500)
                } else {


                    crearObjetoParaGrafico(res.data)

                }




              /*  setvalorMaxim(res.data.valor_maxim_total) //le paso la energia total a la variale energiatotal
                delete res.data.valor_maxim_total//borro la energia total de la data ya que esta data se la pasare al grafico

                res.data = Object.values(res.data)//convierto el objeto en array de objetos que es el formato que pide el grafico

*/

                /*setchartLoading(false)*/


            }).catch(function (error) {
            catchApiErrors(error,backdrop_dispatch,t,alert)
        })
    }


    if (!props.location.state) {
        return <Redirect to="/potencia-maxima"/>
    } else {
        return (
            <>
                <RenderHeader/>
                <DivGrisTitulo tituloBienvenida={false} titulo={t('ptm')}/>
                <div className="Padding-seccion-principal">


                    <div className="Ver-grafica-potencia">
                        <div className="container px-md-5">
                            <div className="text-center mb-3"><h2 className=" mb-4 text-center Borde-gris">CUPS: &nbsp;
                                {props.location.state.cups}</h2>

                                <p className="text-center my-4 pt-2"  ><b>{t('direccion')}: </b> <span style={{'fontSize':'17px','textTransform':'uppercase'}}> {props.location.state.direccion}</span> </p>
                            </div>
                            <div className="row">
                                <div className="col-12  d-flex flex-wrap flex-column">


                                    <p className="text-center pl-0 pt-0" style={{'fontSize': '15px'}}>{t('potentitulo')} </p>

                                    <div>
                                        <h2 className=" Titulo-pag-grafico">
                                            {t('sp')}
                                        </h2>
                                    </div>
                                    <div className="d-flex w-100">
                                        <TabGraficoFiltro

                                                          tarifa={props.location.state.tarifa}
                                                          potencia1={props.location.state.potencia_contratada}
                                                          potencia2={props.location.state.potencia_contratada2}
                                                          potencia3={props.location.state.potencia_contratada3}
                                                          potencia4={props.location.state.potencia_contratada4}
                                                          potencia5={props.location.state.potencia_contratada5}
                                                          potencia6={props.location.state.potencia_contratada6}
                                                          setpotenciaPorFiltro={setpotenciaPorFiltro}
                                                          setfiltroTramo={setfiltroTramo}
                                                          actualizarFechas={getDataGraficoTabla}/>


                                    </div>

                                </div>


                            </div>
                        </div>

                        <div className="container-fluid px-md-5 Z-index-1">
                            <div className="row Z-index-1 Overflow-x-scroll-m">
                                <div className="col-12 mt-5 text-center">
                                    {chartLoading &&
                                    <CircularProgress className="mx-auto text-center "
                                                      disableShrink/>}
                                    {dataGrafico &&
                                    <TabGraficoTabla
                                        potencia_contratada={potenciaPorFiltro}
                                        valor_maxim={valorMaxim}
                                        data_grafico={dataGrafico}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }


}

export default GraficoPagePotencia;