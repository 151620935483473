import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import {getLocalStorageData} from "./helpers/auth-helpers";

const allowedLanguages = ['ca', 'es'];

const defaultLng = getLocalStorageData('lang') ? getLocalStorageData('lang') : 'ca';
let lng = defaultLng;


i18n
    .use(XHR)
    .use(initReactI18next)
    .init({
        lng,
        debug: false,
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: '/translations/{{lng}}.json',
            allowMultiLoading: true
        },
        react: {
            wait: true,
            useSuspense: false
        }
    });

export default i18n;