import React, {Component, useState, useContext, useEffect} from 'react';
import {Select, TextField, InputLabel, MenuItem, FormControl} from '@material-ui/core';
import {useForm, Controller, ErrorMessage} from "react-hook-form";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import {SignUpFormContext} from "../../../context/signup-form-context";
import './style.scss';
import Typography from "@material-ui/core/Typography";
import MyDropzone from "./fileUpload/dropzone";
import {ScrollToTopOnMount} from "../../scrollToTop";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import Axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import {positions, useAlert} from "react-alert";
import {withRouter} from 'react-router-dom';
import {expulsarIfTokenInvalido} from "../../../helpers/auth-helpers";
import dedo from "../../../img/dedo-ico.png";
import { useTranslation } from 'react-i18next';

function RegisterFormStep2_Representante(props) {
    const alert = useAlert();

    const {register, errors, handleSubmit, control, watch, getValues, setError, setValue} = useForm();

    const {state, dispatch} = useContext(SignUpFormContext);
    const [cargando, setCargando] = useState(false);
    const [errors_res, seterrors_res] = useState();
    const [ mostrarTxtObligatorio, setmostrarTxtObligatorio ] = useState(false);
    const [honeypot, sethoneypot] = useState('');

    const [cifExistente, setcifExistente] = useState();
    const [emailExistente, setemailExistente] = useState();
    const { t, i18n } = useTranslation();

    /*PARA QUE EL STATE DEL CONTEXT ENVIE LOS VALORES AL ACTUALIZARSE HAY QUE USAR EL USEFFECT
    * QUE PONIENDOLE COMO ARRAY EL STATE SE EJECUTARÁ SOLO CUANDO CAMBIE EL STATE DEL CONTEXT
    * */
    const [enviarFormulario, setenviarFormulario] = useState(false);//EN EL SUBMIT LA PONGO EN TRUE

    React.useEffect(
        () => {
            if (enviarFormulario === true) {

                enviarForm()
            }

        },
        [state]//para controlar que se ejecute cuando actualizo la variable state que será la el context form
    );

    React.useEffect(
        () => {

            register({ name: 'file_documento' }, { required: false})//luego actualizaré este campo con la funcion fileToForm que le pasaré al componente de file
            register({ name: 'file_apoderamiento' }, { required: false})//luego actualizaré este campo con la funcion fileToForm que le pasaré al componente de file
            register({ name: 'file_cif' }, { required: false})//luego actualizaré este campo con la funcion fileToForm que le pasaré al componente de file


        },
        []//vacio solo se ejecuta una vez
    );

    const fileToForm = (name,files) =>{ //funcion que pasaré por prop al dropzone y que actualiza la variables file registro arriba del formlario

        setValue(name, files)

     /*   console.log(getValues())*/

    }
    const onSubmit = data => {
        setenviarFormulario(true);//lo pongo en true para que cuando se actualize el state context se ejecutará el useefect y entrara al enviarform

        dispatch({
            type: 'update_form_value',
            data: getValues()
        })

    }
    function rellenarhoneypot(){
        console.log('h')
        sethoneypot('honey')
    }
    function enviarForm() {

        if(!state.file_documento || !state.file_apoderamiento || !state.file_cif){
            setmostrarTxtObligatorio(true)
            return


        }else{
            if( state.file_documento.length<1 || state.file_apoderamiento.length<1 || state.file_cif.length<1){
                setmostrarTxtObligatorio(true)
                return
            }else{
                setmostrarTxtObligatorio(false)

            }

        }
        setCargando(true)

        /*PRIMERO ACTUALIZO EL STATE CON LOS VALORES DE ESTE FORM, YA ESTARÁN LOS DEL STEP 1*/
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.defaults.withCredentials = true;
        Axios.post(`https://api.zona-privada.electracaldensedistribucio.com/api/auth/signup`, state)
            .then(res => {
                setCargando(false)
                seterrors_res('')

                alert.show(<div className="Alert Alert_success"><img src={dedo} alt=""/> <span className="pt-1">{t('ucc')}</span></div>, { position: positions.MIDDLE_RIGHT})

                setTimeout(() => {
                    props.history.push('/')

                }, 2000)
            }).catch((error) => {


            if (error.response) {
                if (error.response.data.code === 422) {

                    if (error.response.data.error == 'login_existente') {
                        seterrors_res('Aquest CIF ya existeix')

                        setError("cif", "notMatch", "Aquest CIF ya existeix")
                        document.getElementById("cif").scrollIntoView()//para hacer scroll al cif
                    }
                    if(error.response.data.error=='no_existe_en_electrai'){

                        seterrors_res('Aquest CIF no es client de Electra Caldense')

                    }
                    if (error.response.data.error == 'email_existente') {
                        seterrors_res('Aquest email ya existeix')

                        setError("email", "required", "Aquest email ya existeix")
                        document.getElementById("email").scrollIntoView()//para hacer scroll al cif

                    }
                    if (error.response.data.error == 'error_tamano') {
                        seterrors_res('Imatge més gran de 5,5mb')
                    }

                    if (error.response.data.error == 'ko_img') {
                        seterrors_res(t('eii'))
                    }
                } else {
                    seterrors_res(t('ei'))

                }


            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                /* console.log(error.request);*/
            } else {
                // Something happened in setting up the request that triggered an Error
                /*console.log('Error', error.message);*/
            }


            setCargando(false)

        })
    }


    const volver = () => {
        dispatch({
            type: 'come_back_form',
            data: getValues()
        });
    }


    return (
        <>
            <ScrollToTopOnMount/>
            <Typography component="h3" variant="h4" className="mt-3 text-center">
                {t('cetc')}
            </Typography>
            <Typography component="p" variant="subtitle1" className="mt-3 text-center d-block ">
                {t('empleamos_datos')}.
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)} className="Form-signup">

                {/*RAZON SOCAIL CIF*/}
                <div className="Row-flex">

                    <div className="w-100 w-100-m d-flex align-items-center Padding-movil p-relative"
                         style={{ paddingLeft: "0"}}>

                        <TextField className={`${errors.razon_social ? 'Error-input-border' : 'Vacio'}`}
                                   defaultValue={state.razon_social} name="razon_social" autoFocus
                                   fullWidth margin="normal" label={t('rao')} variant="outlined"

                                   inputRef={register({
                                       required: t('co') // <p>error message</p>
                                   })}/>
                        <FormHelperText style={{'position': 'absolute', 'color': 'red', 'bottom': '5px'}}>
                            {errors.razon_social && errors.razon_social.message}
                        </FormHelperText>
                    </div>


                </div>
                <Typography component="p" className="mt-3 mb-2 text-left d-block Subtitulo1">
                    {t('add')}

                  {/*  <Tooltip className="ml-2"
                                                                   style={{
                                                                       fontSize: "18px",
                                                                       color: "rgba(0, 0, 0, 0.24)"
                                                                   }}
                                                                   title={t('encas')}
                                                                   placement="top-end">
                    <InfoIcon/>
                    </Tooltip>*/}
                </Typography>


                <div className="Row-flex">
                    <div className="w-100 w-100-m d-flex align-items-center Padding-movil p-relative pb-0"
                         style={{ paddingLeft: "0"}}>

                        <TextField defaultValue={state.cif} name="cif" id="cif" autoFocus fullWidth
                                   className={`T-uppercase ${errors.cif ? 'Error-input-border' : 'Vacio'}`}
                                   margin="normal" label="CIF"
                                   variant="outlined"
                                   inputRef={register({
                                       required: t('co'),
                                       pattern: {
                                           value: /^(X(-|\.)?0?\d{7}(-|\.)?[a-zA-Z]|[a-zA-Z](-|\.)?\d{7}(-|\.)?[0-9a-zA-Z]|\d{8}(-|\.)?[a-zA-Z])$/,
                                           message: t('finc')
                                       }
                                   })}/>
                        <FormHelperText style={{'position': 'absolute', 'bottom': '5px', 'color': 'red'}}>
                            {errors.cif && errors.cif.message}
                        </FormHelperText>
                    </div>
                </div>
                <div className="Row-flex pt-1">

                    <div className=" w-100-m  pb-0 Padding-movil p-relative"
                         style={{ paddingLeft: "0"}}>
                        <TextField className={`${errors.password ? 'Error-input-border' : 'Vacio'}`} name="password"
                                   autoFocus fullWidth margin="normal" label={t('contrasenya')}
                                   variant="outlined" inputRef={register({
                            required: t('co'),
                            pattern: {
                                value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                                message: t('cob')
                            }

                        })}
                                   defaultValue={state.password}
                                   type="password"/>
                        <FormHelperText style={{'position': 'absolute', 'bottom': '-20px', 'color': 'red'}}>
                            {errors.password && errors.password.message}
                        </FormHelperText>
                    </div>

                    <div className=" w-100-m d-flex align-items-center pb-0 Padding-movil p-relative"
                         style={{ paddingLeft: "0", position: 'relative'}}>
                        <TextField className={`${errors.password_confirmation ? 'Error-input-border' : 'Vacio'}`}
                                   name="password_confirmation" autoFocus fullWidth margin="normal" type="password"
                                   label={t('confirmar_contrasenya')}
                                   defaultValue={state.password_confirmation}
                                   variant="outlined" inputRef={register({
                            validate: (value) => value === watch('password') || "La contrasenya no coincideix"
                        })}/>
                        <FormHelperText style={{position: 'absolute', bottom: '-15px', color: 'red'}}>
                            {errors.password_confirmation && errors.password_confirmation.message}
                        </FormHelperText>
                    </div>
                </div>


                <Typography component="p" className="mt-2 mb-4 text-left d-block Subtitulo2 text-center">
                    Adjuntar CIF
                    <Tooltip className="ml-2"
                             style={{
                                 fontSize: "18px",
                                 color: "rgba(0, 0, 0, 0.24)"
                             }}
                             title="El CIF és el document d'identificació fiscal de la teva empresa. Ho necessitem per a validar la teva empresa. "
                             placement="top-end">
                        <InfoIcon/>
                    </Tooltip>
                </Typography>


                <MyDropzone functionFileToForm={fileToForm} inputName='file_cif'/>



                <hr/>

                {/*NOMBRE Y APELLIDOS*/}

                <Typography component="p" className="mt-3 mb-2 text-left d-block Subtitulo1">
                    {t('dare')}
                </Typography>

                <div className="Row-flex">

                    <div className=" w-100-m d-flex align-items-center Padding-movil p-relative"
                         style={{ paddingLeft: "0"}}>

                        <TextField className={`${errors.nombre ? 'Error-input-border' : 'Vacio'}`} name="nombre"
                                   defaultValue={state.nombre} autoFocus fullWidth
                                   margin="normal" label={t('nombre')} variant="outlined"
                                   inputRef={register({
                                       required: t('co') // <p>error message</p>
                                   })}/>
                        <FormHelperText style={{'position': 'absolute', 'bottom': '-5px', 'color': 'red'}}>
                            {errors.nombre && errors.nombre.message}
                        </FormHelperText>
                    </div>
                    <div className=" w-100-m d-flex align-items-center Padding-movil p-relative"
                         style={{ paddingLeft: "0"}}>

                        <TextField className={`${errors.apellidos ? 'Error-input-border' : 'Vacio'}`} name="apellidos"
                                   defaultValue={state.apellidos} autoFocus fullWidth
                                   margin="normal" label={t('apellidos')}
                                   variant="outlined"
                                   inputRef={register({
                                       required: t('co') // <p>error message</p>
                                   })}/>
                        <FormHelperText style={{'position': 'absolute', 'bottom': '-5px', 'color': 'red'}}>
                            {errors.apellidos && errors.apellidos.message}
                        </FormHelperText>
                    </div>


                </div>

                <div className="Row-flex">
                    <div className=" w-100-m d-flex align-items-center pb-0 pl-0 Padding-movil"
                         >
                        <FormControl fullWidth error={Boolean(errors.tipo_documento)}>
                            <InputLabel className=" w-100">
                                Tipo de documento *


                            </InputLabel>

                            <Controller
                                as={
                                    <Select native>
                                        <option value="nif">
                                            NIF
                                        </option>
                                        <option value="nie">
                                            NIE
                                        </option>
                                        <option value="pasaporte">
                                            Pasaporte
                                        </option>
                                    </Select>
                                }
                                name="tipo_documento"
                                rules={{required: "Campo obligatorio"}}
                                control={control}
                                defaultValue={state.tipo_documento} fullWidth
                            />
                            <FormHelperText>
                                {errors.tipo_documento && errors.tipo_documento.message}
                            </FormHelperText>
                        </FormControl>
                    </div>
                    <div className=" w-100-m d-flex align-items-center pb-0 Padding-movil p-relative"
                         >
                        <TextField className={`mt-0 T-uppercase ${errors.n_documento ? 'Error-input-border NoRadius' : 'Vacio'}`}
                                   name="n_documento" defaultValue={state.n_documento}
                                   autoFocus fullWidth
                                   margin="normal"
                                   label={t('n_documento')}
                                   inputRef={register({
                                       required: t('co'),
                                       pattern: {
                                           value: /^(X(-|\.)?0?\d{7}(-|\.)?[a-zA-Z]|[a-zA-Z](-|\.)?\d{7}(-|\.)?[0-9a-zA-Z]|\d{8}(-|\.)?[a-zA-Z])$/,
                                           message: t('finc')
                                       }
                                   })}/>
                        <FormHelperText style={{'position': 'absolute', 'bottom': '-5px', 'color': 'red'}}>
                            {errors.n_documento && errors.n_documento.message}
                        </FormHelperText>
                    </div>
                </div>

                <div className="Row-flex justify-content-around">

                    <div className="M-h-310">

                        <Typography component="p" className="mt-2 mb-4 text-left d-block Subtitulo1 text-center">

                            Adjuntar {t('di')}
                            <Tooltip className="ml-2"
                                     style={{
                                         fontSize: "18px",
                                         color: "rgba(0, 0, 0, 0.24)"
                                     }}
                                     title={t('nifi')}
                                     placement="top-end">
                                <InfoIcon/>
                            </Tooltip>
                        </Typography>


                        <MyDropzone functionFileToForm={fileToForm} inputName='file_documento'/>

                    </div>

                    <div className="M-h-310">

                        <Typography component="p" className="mt-1 mb-4 text-left d-block Subtitulo1 text-center">
                            Adjuntar {t('apoderament')}
                            <Tooltip className="ml-2"
                                     style={{
                                         fontSize: "18px",
                                         color: "rgba(0, 0, 0, 0.24)"
                                     }}
                                     title="L'apoderament és el document que t'autoritza com a representant legal de l'empresa.  "
                                     placement="top-end">
                                <InfoIcon/>
                            </Tooltip>
                        </Typography>


                        <MyDropzone functionFileToForm={fileToForm} inputName='file_apoderamiento'/>

                    </div>

                </div>
                {/*END NOMBRE APELLIDOS*/}







                <hr/>

                <Typography component="p" className="mt-2 mb-4 text-left d-block Subtitulo1">
                    {t('dades')}
                </Typography>
                {/*END RAZON SOCAIL CIF */}


                {/*END NOMBRE APELIDOS */}

                <div className="Row-flex">

                    <div className=" w-100-m  pb-0 Padding-movil p-relative"
                         style={{ paddingLeft: "0"}}>
                        <TextField name="email" id="email" autoFocus defaultValue={state.email} fullWidth
                                   margin="normal" label={t('emcc')}
                                   className={`${errors.email ? 'Error-input-border' : 'Vacio'}`}
                                   variant="outlined"
                                   inputRef={register({
                                       pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                       required: t('co')
                                   })}/>
                        <FormHelperText style={{position: 'absolute', bottom: '-5px', color: 'red'}}>
                            {errors.email && errors.email.message}
                        </FormHelperText>
                    </div>

                    <div className=" w-100-m d-flex align-items-center pb-0 Padding-movil p-relative"
                         style={{ paddingLeft: "0", position: 'relative'}}>
                        <TextField className={`${errors.email_re ? 'Error-input-border' : 'Vacio'}`} name="email_re"
                                   autoFocus fullWidth margin="normal"
                                   label={t('cemc')} defaultValue={state.email_re}
                                   variant="outlined" inputRef={register({
                            validate: (value) => value === watch('email') || t('emc')
                        })}/>
                        <FormHelperText style={{position: 'absolute', bottom: '-10px', color: 'red'}}>
                            {errors.email_re && errors.email_re.message}
                        </FormHelperText>
                    </div>
                </div>


                <div className="Row-flex">
                    <div className="w-33 d-flex align-items-center pb-0 pl-0 Padding-movil" >
                        <FormControl fullWidth error={Boolean(errors.prefijo_tlf)}>
                            <InputLabel>
                                {t('pt')}
                            </InputLabel>
                            <Controller
                                as={
                                    <Select native>

                                        <option value="34">España (+34)</option>
                                        <option value="44">UK (+44)</option>
                                        <option value="1">USA (+1)</option>
                                        <option value="213">Algeria (+213)</option>
                                        <option value="376">Andorra (+376)</option>
                                        <option value="244">Angola (+244)</option>
                                        <option value="1264">Anguilla (+1264)</option>
                                        <option value="1268">Antigua &amp; Barbuda
                                            (+1268)
                                        </option>
                                        <option value="54">Argentina (+54)</option>
                                        <option value="374">Armenia (+374)</option>
                                        <option value="297">Aruba (+297)</option>
                                        <option value="61">Australia (+61)</option>
                                        <option value="43">Austria (+43)</option>
                                        <option value="994">Azerbaijan (+994)</option>
                                        <option value="1242">Bahamas (+1242)</option>
                                        <option value="973">Bahrain (+973)</option>
                                        <option value="880">Bangladesh (+880)</option>
                                        <option value="1246">Barbados (+1246)</option>
                                        <option value="375">Belarus (+375)</option>
                                        <option value="32">Belgium (+32)</option>
                                        <option value="501">Belize (+501)</option>
                                        <option value="229">Benin (+229)</option>
                                        <option value="1441">Bermuda (+1441)</option>
                                        <option value="975">Bhutan (+975)</option>
                                        <option value="591">Bolivia (+591)</option>
                                        <option value="387">Bosnia Herzegovina (+387)</option>
                                        <option value="267">Botswana (+267)</option>
                                        <option value="55">Brazil (+55)</option>
                                        <option value="673">Brunei (+673)</option>
                                        <option value="359">Bulgaria (+359)</option>
                                        <option value="226">Burkina Faso (+226)</option>
                                        <option value="257">Burundi (+257)</option>
                                        <option value="855">Cambodia (+855)</option>
                                        <option value="237">Cameroon (+237)</option>
                                        <option value="1">Canada (+1)</option>
                                        <option value="238">Cape Verde Islands (+238)</option>
                                        <option value="1345">Cayman Islands (+1345)</option>
                                        <option value="236">Central African Republic
                                            (+236)
                                        </option>
                                        <option value="56">Chile (+56)</option>
                                        <option value="86">China (+86)</option>
                                        <option value="57">Colombia (+57)</option>
                                        <option value="269">Comoros (+269)</option>
                                        <option value="242">Congo (+242)</option>
                                        <option value="682">Cook Islands (+682)</option>
                                        <option value="506">Costa Rica (+506)</option>
                                        <option value="385">Croatia (+385)</option>
                                        <option value="53">Cuba (+53)</option>
                                        <option value="90392">Cyprus North (+90392)</option>
                                        <option value="357">Cyprus South (+357)</option>
                                        <option value="42">Czech Republic (+42)</option>
                                        <option value="45">Denmark (+45)</option>
                                        <option value="253">Djibouti (+253)</option>
                                        <option value="1809">Dominica (+1809)</option>
                                        <option value="1809">Dominican Republic
                                            (+1809)
                                        </option>
                                        <option value="593">Ecuador (+593)</option>
                                        <option value="20">Egypt (+20)</option>
                                        <option value="503">El Salvador (+503)</option>
                                        <option value="240">Equatorial Guinea (+240)</option>
                                        <option value="291">Eritrea (+291)</option>
                                        <option value="372">Estonia (+372)</option>
                                        <option value="251">Ethiopia (+251)</option>
                                        <option value="500">Falkland Islands (+500)</option>
                                        <option value="298">Faroe Islands (+298)</option>
                                        <option value="679">Fiji (+679)</option>
                                        <option value="358">Finlandia (+358)</option>
                                        <option value="33">Francia (+33)</option>
                                        <option value="594">French Guiana (+594)</option>
                                        <option value="689">French Polynesia (+689)</option>
                                        <option value="241">Gabon (+241)</option>
                                        <option value="220">Gambia (+220)</option>
                                        <option value="7880">Georgia (+7880)</option>
                                        <option value="49">Germany (+49)</option>
                                        <option value="233">Ghana (+233)</option>
                                        <option value="350">Gibraltar (+350)</option>
                                        <option value="30">Greece (+30)</option>
                                        <option value="299">Greenland (+299)</option>
                                        <option value="1473">Grenada (+1473)</option>
                                        <option value="590">Guadeloupe (+590)</option>
                                        <option value="671">Guam (+671)</option>
                                        <option value="502">Guatemala (+502)</option>
                                        <option value="224">Guinea (+224)</option>
                                        <option value="245">Guinea - Bissau (+245)</option>
                                        <option value="592">Guyana (+592)</option>
                                        <option value="509">Haiti (+509)</option>
                                        <option value="504">Honduras (+504)</option>
                                        <option value="852">Hong Kong (+852)</option>
                                        <option value="36">Hungary (+36)</option>
                                        <option value="354">Iceland (+354)</option>
                                        <option value="91">India (+91)</option>
                                        <option value="62">Indonesia (+62)</option>
                                        <option value="98">Iran (+98)</option>
                                        <option value="964">Iraq (+964)</option>
                                        <option value="353">Ireland (+353)</option>
                                        <option value="972">Israel (+972)</option>
                                        <option value="39">Italia (+39)</option>
                                        <option value="1876">Jamaica (+1876)</option>
                                        <option value="81">Japan (+81)</option>
                                        <option value="962">Jordan (+962)</option>
                                        <option value="7">Kazakhstan (+7)</option>
                                        <option value="254">Kenya (+254)</option>
                                        <option value="686">Kiribati (+686)</option>
                                        <option value="850">Korea North (+850)</option>
                                        <option value="82">Korea South (+82)</option>
                                        <option value="965">Kuwait (+965)</option>
                                        <option value="996">Kyrgyzstan (+996)</option>
                                        <option value="856">Laos (+856)</option>
                                        <option value="371">Latvia (+371)</option>
                                        <option value="961">Lebanon (+961)</option>
                                        <option value="266">Lesotho (+266)</option>
                                        <option value="231">Liberia (+231)</option>
                                        <option value="218">Libya (+218)</option>
                                        <option value="417">Liechtenstein (+417)</option>
                                        <option value="370">Lithuania (+370)</option>
                                        <option value="352">Luxembourg (+352)</option>
                                        <option value="853">Macao (+853)</option>
                                        <option value="389">Macedonia (+389)</option>
                                        <option value="261">Madagascar (+261)</option>
                                        <option value="265">Malawi (+265)</option>
                                        <option value="60">Malaysia (+60)</option>
                                        <option value="960">Maldives (+960)</option>
                                        <option value="223">Mali (+223)</option>
                                        <option value="356">Malta (+356)</option>
                                        <option value="692">Marshall Islands (+692)</option>
                                        <option value="596">Martinique (+596)</option>
                                        <option value="222">Mauritania (+222)</option>
                                        <option value="269">Mayotte (+269)</option>
                                        <option value="52">Mexico (+52)</option>
                                        <option value="691">Micronesia (+691)</option>
                                        <option value="373">Moldova (+373)</option>
                                        <option value="377">Monaco (+377)</option>
                                        <option value="976">Mongolia (+976)</option>
                                        <option value="1664">Montserrat (+1664)</option>
                                        <option value="212">Morocco (+212)</option>
                                        <option value="258">Mozambique (+258)</option>
                                        <option value="95">Myanmar (+95)</option>
                                        <option value="264">Namibia (+264)</option>
                                        <option value="674">Nauru (+674)</option>
                                        <option value="977">Nepal (+977)</option>
                                        <option value="31">Netherlands (+31)</option>
                                        <option value="687">New Caledonia (+687)</option>
                                        <option value="64">New Zealand (+64)</option>
                                        <option value="505">Nicaragua (+505)</option>
                                        <option value="227">Niger (+227)</option>
                                        <option value="234">Nigeria (+234)</option>
                                        <option value="683">Niue (+683)</option>
                                        <option value="672">Norfolk Islands (+672)</option>
                                        <option value="670">Northern Marianas (+670)</option>
                                        <option value="47">Norway (+47)</option>
                                        <option value="968">Oman (+968)</option>
                                        <option value="680">Palau (+680)</option>
                                        <option value="507">Panama (+507)</option>
                                        <option value="675">Papua New Guinea (+675)</option>
                                        <option value="595">Paraguay (+595)</option>
                                        <option value="51">Peru (+51)</option>
                                        <option value="63">Philippines (+63)</option>
                                        <option value="48">Poland (+48)</option>
                                        <option value="351">Portugal (+351)</option>
                                        <option value="1787">Puerto Rico (+1787)</option>
                                        <option value="974">Qatar (+974)</option>
                                        <option value="262">Reunion (+262)</option>
                                        <option value="40">Romania (+40)</option>
                                        <option value="7">Russia (+7)</option>
                                        <option value="250">Rwanda (+250)</option>
                                        <option value="378">San Marino (+378)</option>
                                        <option value="239">Sao Tome &amp; Principe
                                            (+239)
                                        </option>
                                        <option value="966">Saudi Arabia (+966)</option>
                                        <option value="221">Senegal (+221)</option>
                                        <option value="381">Serbia (+381)</option>
                                        <option value="248">Seychelles (+248)</option>
                                        <option value="232">Sierra Leone (+232)</option>
                                        <option value="65">Singapore (+65)</option>
                                        <option value="421">Slovak Republic (+421)</option>
                                        <option value="386">Slovenia (+386)</option>
                                        <option value="677">Solomon Islands (+677)</option>
                                        <option value="252">Somalia (+252)</option>
                                        <option value="27">South Africa (+27)</option>
                                        <option value="34">España (+34)</option>
                                        <option value="94">Sri Lanka (+94)</option>
                                        <option value="290">St. Helena (+290)</option>
                                        <option value="1869">St. Kitts (+1869)</option>
                                        <option value="1758">St. Lucia (+1758)</option>
                                        <option value="249">Sudan (+249)</option>
                                        <option value="597">Suriname (+597)</option>
                                        <option value="268">Swaziland (+268)</option>
                                        <option value="46">Sweden (+46)</option>
                                        <option value="41">Switzerland (+41)</option>
                                        <option value="963">Syria (+963)</option>
                                        <option value="886">Taiwan (+886)</option>
                                        <option value="7">Tajikstan (+7)</option>
                                        <option value="66">Thailand (+66)</option>
                                        <option value="228">Togo (+228)</option>
                                        <option value="676">Tonga (+676)</option>
                                        <option value="1868">Trinidad &amp; Tobago
                                            (+1868)
                                        </option>
                                        <option value="216">Tunisia (+216)</option>
                                        <option value="90">Turkey (+90)</option>
                                        <option value="7">Turkmenistan (+7)</option>
                                        <option value="993">Turkmenistan (+993)</option>
                                        <option value="1649">Turks &amp; Caicos Islands (+1649)
                                        </option>
                                        <option value="688">Tuvalu (+688)</option>
                                        <option value="256">Uganda (+256)</option>
                                        <option value="380">Ukraine (+380)</option>
                                        <option value="971">United Arab Emirates
                                            (+971)
                                        </option>
                                        <option value="598">Uruguay (+598)</option>
                                        <option value="7">Uzbekistan (+7)</option>
                                        <option value="678">Vanuatu (+678)</option>
                                        <option value="379">Vatican City (+379)</option>
                                        <option value="58">Venezuela (+58)</option>
                                        <option value="84">Vietnam (+84)</option>
                                        <option value="84">Virgin Islands - British
                                            (+1284)
                                        </option>
                                        <option value="84">Virgin Islands - US
                                            (+1340)
                                        </option>
                                        <option value="681">Wallis &amp; Futuna
                                            (+681)
                                        </option>
                                        <option value="969">Yemen (North)(+969)</option>
                                        <option value="967">Yemen (South)(+967)</option>
                                        <option value="260">Zambia (+260)</option>
                                        <option value="263">Zimbabwe (+263)</option>
                                    </Select>
                                }
                                name="prefijo_tlf"
                                rules={{required: "Campo obligatorio"}}
                                control={control}
                                defaultValue={state.prefijo_tlf} fullWidth
                            />

                            <FormHelperText>
                                {errors.prefijo_tlf && errors.prefijo_tlf.message}
                            </FormHelperText>
                        </FormControl>
                    </div>
                    <div className="w-33 d-flex align-items-center pb-0 pl-0 Padding-movil"
                         style={{ position: 'relative'}}>
                        <TextField name="tlf" defaultValue={state.tlf} autoFocus fullWidth
                                   margin="normal" className="mt-0"
                                   label={t('tlf')}
                                   inputRef={register({
                                       pattern: /[0-9]{6,10}/,
                                       maxLength: 9
                                   })}/>
                        <FormHelperText style={{position: 'absolute', bottom: '-10px', color: 'red'}}>
                            {errors.tlf &&
                            errors.tlf.type === "pattern" &&
                            "Debe de tener formato tel" +
                            "éfono"}
                            {errors.tlf &&
                            errors.tlf.type === "maxLength" &&
                            "Debe de tener formato tel" +
                            "éfono"}
                        </FormHelperText>
                    </div>
                    <div className="w-33 d-flex align-items-center pb-0 pl-0"
                         style={{ position: 'relative'}}>
                        <TextField defaultValue={state.confirmar_tlf} name="confirmar_tlf" autoFocus
                                   fullWidth margin="normal" className="mt-0"
                                   label="Confirmar teléfono"
                                   inputRef={register({
                                       validate: (value) => value === watch('tlf') || "El teléfono no coincide"
                                   })}/>
                        <FormHelperText style={{position: 'absolute', bottom: '-10px', color: 'red'}}>
                            {errors.confirmar_tlf && errors.confirmar_tlf.message}
                        </FormHelperText>
                    </div>

                </div>
                <FormControl fullWidth error={Boolean(errors.idioma)}>
                    <InputLabel>
                        Idioma *
                    </InputLabel>
                    <Controller
                        as={
                            <Select native>
                                <option value="es">
                                    Español
                                </option>
                                <option value="ca">
                                    Catalán
                                </option>
                            </Select>
                        }
                        name="idioma"
                        rules={{required: "Campo obligatorio"}}
                        control={control}
                        defaultValue={state.idioma} fullWidth
                    />

                    <FormHelperText>
                        {errors.idioma && errors.idioma.message}
                    </FormHelperText>
                </FormControl>

                <FormControl fullWidth error={Boolean(errors.privacidad)}>

                    <div className="d-flex align-items-center ">

                        <input
                            className="mr-1"
                            type="checkbox"
                            name="privacidad"
                            ref={register({
                                required: t('co') // <p>error message</p>
                            })}
                        />
                        <label htmlFor="privacidad" className="m-0"> {t('acondi')}
                            <a href="https://electracaldensedistribucio.com/politica-de-privadesa/"> {t('alegales')}</a>
                        </label>
                    </div>

                    <FormHelperText>
                        {errors.privacidad && errors.privacidad.message}
                    </FormHelperText>
                </FormControl>


                {<p className="text-danger text-center">{errors_res}</p>}
                { mostrarTxtObligatorio && <p className="text-danger text-center">{t('errdr')}</p>}

                <div className="Div-botones-form">
                    <button
                        type="button"

                        onClick={volver}
                        className="Btn Btn_gray "
                    >
                        {t('tornar')}
                    </button>

                    <button
                        type="submit"
                        className="Btn Btn_green "
                        onClick={rellenarhoneypot}

                    >
                        {cargando ? <CircularProgress style={{'color': '#fff'}}/> : 'Guardar'}
                    </button>
                </div>

            </form>
        </>
    );
}

export default withRouter(RegisterFormStep2_Representante);