import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Datatable from "../../common-components/datatables/datatable";

import {ChartDias, ChartHoras} from "../../common-components/charts/mi-consumo-chart";
import moment from "moment";
import {useTranslation} from 'react-i18next';
import {returnProvincia} from "../../../helpers/generales";
import {Link} from "react-router-dom";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'none',
    },
}));




export default function TabGraficoTabla(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [tableRows, setTableRows] = useState()
    const [tableColumns, setTableColumns] = useState([])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const {t} = useTranslation();
    useEffect(() => {


        if (props.data_tabla == 'vacio' || props.data_tabla.length < 1) {

            return
        }

        /*CJO LAS FILAS CABECERA QUE SERAN LOS DIAS*/
        let arr_columnas = [{
            name: "CUPS",
            label: "CUPS",
            options: {
                filter: false,
                sort: false,
                display:false
            }
        },'DATA','HORA','ENERGIA (kWh)','REAL/ESTIMADA']
        /*
       ANTIGUO TABLA POR HORAS
       let arr_columnas = ['']
        for (let i = 0; i < props.data_tabla.length; i++) {
            let fecha_formateada = moment(props.data_tabla[i].data).format('DD-MM-YYYY');

            if (!arr_columnas.includes(fecha_formateada)) {
                arr_columnas.push(fecha_formateada)
            }

        }*/
        setTableColumns(arr_columnas)

        /*HAGO LA DATA DE LOS ROWS*/
        let arr_rows = []
        for (let i = 0; i < props.data_tabla.length; i++) { //creo las horas del array
            arr_rows[i] = [] // arr_rows[0] = [] , arr_rows[1] = [] ... etc
        }

        let contador_horas = 1;
        let primera_pasada = true;
        for (let i = 0; i < props.data_tabla.length; i++) {
            if (contador_horas > 24) {
                contador_horas = 1
                primera_pasada = false

            }

            arr_rows[i].push(props.data_tabla[i].CUPS )
            arr_rows[i].push(props.data_tabla[i].data )
            arr_rows[i].push(props.data_tabla[i].hora )
            arr_rows[i].push((props.data_tabla[i].energia / 1000).toString().replace('.', ',') )
            arr_rows[i].push(props.data_tabla[i].tipo_energia)
            contador_horas++;
        }
        setTableRows(arr_rows)


    }, [props.data_tabla])

   
    useEffect( () => {

        props.setFiltroLineasGrafico([t('energia'),t('eneta'),t('excedent'),t('eexportada')])},[])

    function doFiltroLineas(e){
        switch (e.target.id) {
            case 'btnenergia':


                if(props.filtroLineasGrafico.includes(t('energia'))){

                    if(props.filtroLineasGrafico.length<2){//para que no se puedan dejar todos los filtros sin seleccionar
                        return
                    }

                    props.setFiltroLineasGrafico(props.filtroLineasGrafico.filter(item => item !== t('energia'))); //para quitar el filtro del array
                    props.setdataGrafico(props.data_grafico.filter(item => item.id !== t('energia'))); //para quitar el objeto con id energia del array de objetos de data grafico
                    document.getElementById(e.target.id).classList.add("Disabled")
                }else{
                    props.setFiltroLineasGrafico([...props.filtroLineasGrafico, t('energia')])
                    props.setdataGrafico([...props.data_grafico, props.lineaEnergia])//añado al grafico la linea energia que tengo guardada en una variable prop

                    document.getElementById(e.target.id).classList.remove("Disabled")
                }

                break;
            case 'btneneta':
                if(props.filtroLineasGrafico.includes(t('eneta'))){
                    if(props.filtroLineasGrafico.length<2){//para que no se puedan dejar todos los filtros sin seleccionar
                        return
                    }
                    props.setFiltroLineasGrafico(props.filtroLineasGrafico.filter(item => item !== t('eneta'))); //para quitar el filtro del array
                    props.setdataGrafico(props.data_grafico.filter(item => item.id !== t('eneta'))); //para quitar el objeto con id energia del array de objetos de data grafico

                    document.getElementById(e.target.id).classList.add("Disabled")
                }else{
                    props.setFiltroLineasGrafico([...props.filtroLineasGrafico, t('eneta')])
                    props.setdataGrafico([...props.data_grafico, props.lineaEneta])//añado al grafico la linea energia que tengo guardada en una variable prop

                    document.getElementById(e.target.id).classList.remove("Disabled")
                }
                break;
            case 'btnexcedent':
                if(props.filtroLineasGrafico.includes(t('excedent'))){
                    if(props.filtroLineasGrafico.length<2){//para que no se puedan dejar todos los filtros sin seleccionar
                        return
                    }
                    props.setFiltroLineasGrafico(props.filtroLineasGrafico.filter(item => item !== t('excedent'))); //para quitar el filtro del array
                    props.setdataGrafico(props.data_grafico.filter(item => item.id !== t('excedent'))); //para quitar el objeto con id energia del array de objetos de data grafico

                    document.getElementById(e.target.id).classList.add("Disabled")
                }else{
                    props.setFiltroLineasGrafico([...props.filtroLineasGrafico, t('excedent')])
                    props.setdataGrafico([...props.data_grafico, props.lineaExcedent])//añado al grafico la linea energia que tengo guardada en una variable prop

                    document.getElementById(e.target.id).classList.remove("Disabled")
                }
                break;
            case 'btneexportada':
                if(props.filtroLineasGrafico.includes(t('eexportada'))){
                    if(props.filtroLineasGrafico.length<2){//para que no se puedan dejar todos los filtros sin seleccionar
                        return
                    }
                    props.setFiltroLineasGrafico(props.filtroLineasGrafico.filter(item => item !== t('eexportada'))); //para quitar el filtro del array
                    props.setdataGrafico(props.data_grafico.filter(item => item.id !== t('eexportada_id'))); //para quitar el objeto con id energia del array de objetos de data grafico

                    document.getElementById(e.target.id).classList.add("Disabled")
                }else{
                    props.setFiltroLineasGrafico([...props.filtroLineasGrafico, t('eexportada')])
                    props.setdataGrafico([...props.data_grafico, props.lineaEexportada])//añado al grafico la linea energia que tengo guardada en una variable prop

                    document.getElementById(e.target.id).classList.remove("Disabled")
                }
                break;
        }
    }
    const table_options = {
        selectableRows: 'none',
        filter: false,
        responsive: 'scrollMaxHeight',
        pagination: false,
        search: false,
        sortFilterList: false,
        downloadOptions: {
            filename: "Consum-"+props.cupstxt+".csv",
        },
        print: false
    };

    if (props.data_grafico === 'vacio') {
        return (<h2 className="text-center mt-5">{t('nodades')}</h2>)
    } else {
        return (
            <div className={`${classes.root} Tabfiltro Z-index-2`}>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="Gráfico" {...a11yProps(0)} />
                        <Tab label="Datos horarios y descarga" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <div className="">

                        <p className="my-4 px-3">
                           <span className="Txt-green" style={{'fontSize': '22px', 'fontWeight': 'bold'}}>
                              {t('representacion')}:
                           </span>
                            <br/>
                            <span style={{'fontSize': '13px'}}>
                                {t('pulsar')}

</span>
                        </p>
                        <span
                            className="Energia-total"><b>Energía Acumulada: {props.energia_total.toString().replace('.', ',')} kWh</b></span>
                        {(props.energiaTramo1 > 0 && props.tipo_grafico !== 'dias') && <span
                            className="Energia-total font-weight-bold M-txt text-center d-block">{t('pertr')} 1: {props.energiaTramo1.toFixed(3).toString().replace('.', ',')} kWh</span>}
                        {(props.energiaTramo2 > 0 && props.tipo_grafico !== 'dias') && <span
                            className="Energia-total font-weight-bold M-txt text-center d-block">{t('pertr')} 2: {props.energiaTramo2.toFixed(3).toString().replace('.', ',')} kWh</span>}
                        {(props.energiaTramo3 > 0 && props.tipo_grafico !== 'dias') && <span
                            className="Energia-total font-weight-bold M-txt text-center d-block">{t('pertr')} 3: {props.energiaTramo3.toFixed(3).toString().replace('.', ',')} kWh</span>}
                        {(props.energiaTramo4 > 0 && props.tipo_grafico !== 'dias') && <span
                            className="Energia-total font-weight-bold M-txt text-center d-block">{t('pertr')} 4: {props.energiaTramo4.toFixed(3).toString().replace('.', ',')} kWh</span>}
                        {(props.energiaTramo5 > 0 && props.tipo_grafico !== 'dias') && <span
                            className="Energia-total font-weight-bold M-txt text-center d-block">{t('pertr')} 5: {props.energiaTramo5.toFixed(3).toString().replace('.', ',')} kWh</span>}
                        {(props.energiaTramo6 > 0 && props.tipo_grafico !== 'dias') && <span
                            className="Energia-total font-weight-bold M-txt text-center d-block">{t('pertr')} 6: {props.energiaTramo6.toFixed(3).toString().replace('.', ',')} kWh</span>}

                      { props.autoconsumo && <>  <div className="Botones-filtro-linea">
                            <button id="btnenergia" onClick={doFiltroLineas} className="Btn-e">{t('txt_energia')} importada</button>
                            <button id="btneneta" onClick={doFiltroLineas} className="Btn-eneta">{t('eneta')}</button>
                            <button id="btnexcedent" onClick={doFiltroLineas} className="Btn-excedent">{t('excedent')}</button>
                            <button id="btneexportada" onClick={doFiltroLineas} className="Btn-eexportada">{t('txt_energia')} exportada</button>
                        </div></> }
                        <div className="  Grafico-div-consumo  p-relative mt-md-2">
                            {/*<span className="Titulo-grafico"><b>kWh - dies</b></span>*/}
                            {props.tipo_grafico === 'dias'
                                ?
                                <ChartDias tarifa={props.tarifa} generar_grafico_modal={props.generar_grafico_modal}
                                           data={
                                                   props.data_grafico
                                               }/>
                                :
                                <ChartHoras tarifa={props.tarifa} data={
                                    props.data_grafico
                                }/>
                            }
                        </div>


                    </div>
                    {/* <MyResponsiveLine data={props.data_grafico}/>*/}


                </TabPanel>

                <TabPanel value={value} index={1} className="Div-tabla-horas Overflow-x-scroll t1">
                    <Datatable titulo={t('edph')} options={table_options} columns={tableColumns} data={tableRows}/>


                </TabPanel>

            </div>
        );
    }


}