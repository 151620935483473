import React, { useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import 'react-day-picker/lib/style.css';
// Include the locale utils designed for moment

// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'moment/locale/ca';

import {useTranslation} from 'react-i18next';



const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'none',
    },
}));

export default function Filtro(props) {
    const classes = useStyles();

    const {t} = useTranslation();

    return (
        <div className={`${classes.root} Tabfiltro1`}>


            <div className="mr-md-5 my-3 Filtro-avanzado  justify-content-center ">
                <div className="d-flex flex-column-m  w-100"  >
                    <div className="position-relative  w-50 mb-3 mb-md-0  w-100-m d-flex justify-content-center">

                        <button className="Btn Btn_green" onClick={props.getContadorData}>{t('consultar_contador')}</button>


                    </div>
                    <div className="position-relative  w-50  w-100-m d-flex justify-content-center">

                        <button onClick={props.conectarContador} className={`Btn ${props.btnconectarDisabled ? 'Btn_gray' : 'Btn_green'}`} >{t('tonrar_conectar')}</button>


                    </div>
                </div>



            </div>
        </div>
    );


}