import React, {useContext, useEffect, useState} from 'react';
import {TextField} from '@material-ui/core';
import {useForm} from "react-hook-form";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import DivGrisTitulo from "../common-components/seccion-titulo";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import {Link, Redirect, withRouter} from "react-router-dom";
import Axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import {url_api} from "../../axiosConfig";
import {getLocalStorageData} from "../../helpers/auth-helpers";
import {
    useParams
} from "react-router-dom";
import {positions, useAlert} from "react-alert";
import RenderHeader from "../common-components/header";
import dedo from "../../img/dedo-ico.png";
import {useTranslation} from "react-i18next";




function ForgetPasswordCambiarPass(props) {

    let { token } = useParams();
    const {register, errors, handleSubmit, watch} = useForm();

    const [cargando, setCargando] = useState(true);
    const [errors_res, seterrors_res] = useState();
    const [tokenValido, settokenValido] = useState(false);
    const [usuarioLogin, setusuarioLogin] = useState();
    const alert = useAlert();

    const [formSubmited, setFormSubmited] = useState(false);
    const { t, i18n } = useTranslation();

    const onSubmit = data => {
        setCargando(true)

        let data_post = {
            password: data.password,
            password_confirmation: data.password_confirmation,
            token: token,
            login: usuarioLogin,
        };
        Axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        Axios.defaults.withCredentials = true;
        Axios.post(`${url_api + 'forget_password/reset'}`, data_post)

            .then(res => {

                settokenValido(true)

                setCargando(false)
                seterrors_res('')


                alert.show(<div className="Alert Alert_success"><img src={dedo} alt=""/> <span className="pt-1">{t('contraok')}</span></div>, { position: positions.MIDDLE_RIGHT})


                /*alert.success("{t('contraok')}");*/
                setTimeout(() => {
                    props.history.push('/')

                }, 2000)

            }).catch((error) => {

            settokenValido(false)
            if (error.response) {

                if(error.response.status===400){
                    seterrors_res(t('tn'))
                }else{
                    seterrors_res(t('ei'))

                }

            }


            setCargando(false)


        })
    }

    useEffect(
        () => {
            if (token) {
                checkTokenValid()
            }else{
                setCargando(false)
            }
        }
        ,[])

    function checkTokenValid() {

        Axios.get(url_api + 'forget_password/find/' + token)

            .then(res => {
                setCargando(false)
                settokenValido(true)
                setusuarioLogin(res.data.login)

                document.getElementById("passchange").focus()

            }).catch((error) => {

            if (error.response) {

                if(error.response.status===400){
                    seterrors_res(t('tn'))
                }else{
                    seterrors_res(t('ei'))

                }

            }
            setCargando(false)

        })
    }


    if(cargando){
        return (
            <div className="Vh-100">

                <CircularProgress style={{'position': 'absolute', 'top': '40%', 'left': '48%'}} disableShrink/>;
            </div>
        )
    }
    else if (tokenValido) {
        return (
            <>
                <RenderHeader/>

                <DivGrisTitulo tituloBienvenida={null} titulo={t('rest')}/>

                <div className=" Padding-seccion-principal">
                    <div className=" Login White-card ">
                        <CssBaseline/>
                        <div className=" w-100">
                            <Typography component="h3" variant="h4" className="mt-3 text-center">
                                {t('nova')}

                            </Typography>

                            <form onSubmit={handleSubmit(onSubmit)} className="Form-signup">
                                <div className="Row-flex mb-3">

                                    <div className=" w-100-m  pb-0 Padding-movil p-relative">
                                        <TextField className={`${errors.password ? 'Error-input-border' : 'Vacio'}`}
                                                   name="password"  fullWidth margin="normal"
                                                   label={t('contrasenya')} id="passchange"
                                                   variant="outlined" inputRef={register({
                                            required: t('co') // <p>error message</p>
                                        })}
                                                   type="password"/>
                                        <FormHelperText
                                            style={{'position': 'absolute', 'bottom': '-5px', 'color': 'red'}}>
                                            {errors.password && errors.password.message}
                                        </FormHelperText>
                                    </div>

                                    <div
                                        className={` w-100-m d-flex align-items-center pb-0 Padding-movil p-relative ${errors.password_confirmation ? 'Error-input-border' : 'Vacio'}`}
                                        style={{position: 'relative'}}>
                                        <TextField name="password_confirmation"  fullWidth margin="normal"
                                                   type="password"
                                                   label={t('confirmar_contrasenya')}
                                                   variant="outlined" inputRef={register({
                                            validate: (value) => value === watch('password') || "La contrasenya no coincideix"
                                        })}/>
                                        <FormHelperText style={{position: 'absolute', bottom: '-10px', color: 'red'}}>
                                            {errors.password_confirmation && errors.password_confirmation.message}
                                        </FormHelperText>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className="Btn Btn_green w-100 mw-100"
                                >
                                    {cargando ? <CircularProgress style={{'color': '#fff'}}/> : 'Guardar'}
                                </button>
                            </form>


                            <Grid container className="mt-4">
                                <Grid item xs>
                                    <Link to='/'>
                                        {t('cancelar')}

                                    </Link>
                                </Grid>

                            </Grid>
                        </div>

                    </div>

                </div>
            </>
        )
    } else {
        return (
            <>
                <RenderHeader/>

                <DivGrisTitulo tituloBienvenida={null} titulo={t('indica_nif')}/>

                <div className=" Padding-seccion-principal">
                    <div className="White-card ">
                        <CssBaseline/>
                        <div className="Signup-div">

                            <Typography component="h3" variant="h4" className="mt-3 text-center">
                              {errors_res}
                            </Typography>



                            <Grid container className="mt-4 text-center">
                                <Grid item xs>
                                    <Link to='/'>
                                        {t('tornar_login')}

                                    </Link>
                                </Grid>

                            </Grid>
                        </div>

                    </div>

                </div>
            </>

        );
    }

}

export default  withRouter(ForgetPasswordCambiarPass);