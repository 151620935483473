import React, {createContext, useReducer} from "react";

export const BackdropCargandoContext = createContext(null);

const initialBackdropCargando = {
    open : false,
};

function BackdropCargandoReducer(state, action) {
    switch (action.type) {
        case 'open':
            return {
                ...state,
                open : true
            };
        case 'close':
            return {
                ...state,
                open : false
            };
        default:
            return state
    }
}

const BackdropCargandoProvider = props => {
    const [backdrop_state, backdrop_dispatch] = useReducer(BackdropCargandoReducer, initialBackdropCargando);

    return (
        <BackdropCargandoContext.Provider value={{backdrop_state, backdrop_dispatch}}>
            {props.children}
        </BackdropCargandoContext.Provider>
    );
};

export default BackdropCargandoProvider;