import React, {useContext} from 'react';

import {FormSignupProvider} from "../../context/signup-form-context";
import './style.scss';
import SignupFormRender from "./forms/signupFormRender";
import {AuthDataContext} from "../../context/auth-context";
import {Redirect} from "react-router-dom";
import RenderHeader from "../common-components/header";

export default function Registrarse() {
    const {state, dispatch} = useContext(AuthDataContext);

    if(state.token){
        return <Redirect to="/home" />
    }else {
        return (
            <>
                <RenderHeader/>

                <FormSignupProvider>
                    <SignupFormRender/>
                </FormSignupProvider>
            </>
        );
    }
}