import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import './style.scss'

import CircularProgress from "@material-ui/core/CircularProgress";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCross, faHome, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        paddingRight: 0
    },
}));

export default function TransitionsModal(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const handleClose = () => {
        props.setopenModal_parent(false);
    };

    if(props.loading){
        return  (
            <div>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={`${classes.modal}`}
                    open={props.open ? props.open : false}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}

                >
                    <Fade in={props.open}>
                        <div className={classes.paper}  style={{'outline':'none','width': props.modalWidth,'textAlign':'center'}}>
                            <CircularProgress className="mx-auto text-center" disableShrink/>
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }else{
        return (

            <div className="Div-modal">

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal }
                    open={props.open ? props.open : false}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}

                >
                    <Fade in={props.open}>
                        <div className={classes.paper + ' ' + props.clase} style={{'position':'relative','outline':'none','width': props.modalWidth}}>
                            <h2 id="transition-modal-title " className="text-center mt-3 d-block">{props.titulo}</h2>
                            <FontAwesomeIcon onClick={handleClose} className="Ico-cerrar-modal" title={t('cerrar')} icon={faTimes} style={{'fontSize': '22px'}}
                                             color="#e3e3e3"/>
                            <div id="transition-modal-description">

                                {props.children}
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }

}