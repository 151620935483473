import React from "react";
import "./style.scss";


import { useTranslation } from 'react-i18next';
import {getLocalStorageData} from "../../../helpers/auth-helpers";

export function Footer() {
    const { t, i18n } = useTranslation();
    return (
        <footer className=" Fot" style={{marginTop :'150px'}}>
            <div className="jupiterx-subfooter">
                <div className="container">
                    <div className="row">
                        <div className="col-md">
                            <div className="jupiterx-subfooter-menu-container">
                                <ul id="jupiterx-subfooter-menu" className="jupiterx-subfooter-menu">
                                    <li id="menu-item-10164"
                                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-10164">

                                        {getLocalStorageData('lang') === 'es'
                                            ?  <a href="https://electracaldensedistribucio.com/es/aviso-legal/">{t('aviso_legal')} | </a>
                                            :  <a href="https://electracaldensedistribucio.com/avis-legal/">{t('aviso_legal')} | </a>
                                        }

                                    </li>
                                    <li id="menu-item-10165"
                                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-10165">
                                        {getLocalStorageData('lang') === 'es'
                                            ?  <a href="https://electracaldensedistribucio.com/es/politica-de-cookies/">{t('politica_cookies')} | </a>
                                            :  <a href="https://electracaldensedistribucio.com/politica-de-cookies/">{t('politica_cookies')} | </a>
                                        }

                                    </li>
                                    <li id="menu-item-10166"
                                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-10166">
                                        {getLocalStorageData('lang') === 'es'
                                            ?  <a href="https://electracaldensedistribucio.com/es/politica-de-privadesa/">{t('politica_privacidad')} | </a>
                                            :  <a href="https://electracaldensedistribucio.com/politica-de-privadesa/">{t('politica_privacidad')} | </a>
                                        }

                                    </li>
                                    <li id="menu-item-10168"
                                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-10168">

                                        {getLocalStorageData('lang') === 'es'
                                            ?  <a href="https://electracaldenseholding.es/es">Electra Caldense Distribución</a>
                                            :  <a href="https://electracaldenseholding.es">Electra Caldense Distribución</a>
                                        }

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-auto">
                            <div className="jupiterx-subfooter-copyright">© 2020 - Electra Caldense Distribución. All
                                rights reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}


function returnFooterByPath(){
        return
}

export default returnFooterByPath;
