import React, {useEffect, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import moment from "moment";

import 'react-day-picker/lib/style.css';
// Include the locale utils designed for moment


// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'moment/locale/ca';

import {useTranslation} from 'react-i18next';
import {FormControl, InputLabel, MenuItem, option, Select} from "@material-ui/core";

import {Controller, useForm} from "react-hook-form";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'none',
    },
}));

export default function TabGraficoFiltro(props) {
    const classes = useStyles();
    const [filtroPersonalizadoInicio, setfiltroPersonalizadoInicio] = useState(moment().format('YYYY-MM-DD'));
    const [filtroPersonalizadoFinal, setfiltroPersonalizadoFinal] = useState(moment().format('YYYY-MM-DD'));
    const [errorMas60Dias, seterrorMas60Dias] = useState();

    const {t} = useTranslation();
    const {register, errors, handleSubmit, control, getValues, setError, setValue} = useForm();


    const handleChangePotencia = (e) => {
        props.setpotenciaPorFiltro(e.target.value)
    }

    const handleFiltroTramo = (e) => {
        props.setfiltroTramo(e.target.value)
    }


    const handleClickPersonalizado = () => {
        let obj_fechas = getValues()

        let fecha_inicial = moment([obj_fechas.anyo_inicio, obj_fechas.mes_inicio - 1]).format('YYYY-MM-DD')

        let fecha_final = moment([obj_fechas.anyo_final, obj_fechas.mes_final - 1]).endOf('month').format('YYYY-MM-DD')


        props.actualizarFechas(fecha_final, fecha_inicial)//paso el valor filtro de vuelta porque si es de dai lalmaré a la funcion que devuelve las horas y no los días

    }



    return (
        <div className={`${classes.root} Tabfiltro1`}>


            <div className="mr-md-5 my-3 Filtro-avanzado  justify-content-center ">
                <div className="d-flex flex-column-m  w-100">
                    <div className="position-relative  w-50  w-100-m">

                        <p className="pl-0 pt-0 P-absolute Tit "> {t('f_inicial')}</p>
                        {/*   <input type="date" className="mr-4" onChange={handleChangeDateInputPersonalizadoInicio} defaultValue={filtroPersonalizadoInicio} max={moment().format('YYYY-MM-DD')} id="filtro_personalizado_inicio"  />
                        */}
                        <div className="d-flex">
                            <FormControl className="mt-2" fullWidth>

                                <InputLabel>
                                    <p className="pl-0 pt-0">Mes inicial</p>
                                </InputLabel>
                                <Controller
                                    as={
                                        <Select native>

                                            <option value='01'>
                                                {t('m-1')}
                                            </option>
                                            <option value='02'>
                                                {t('m-2')}
                                            </option>
                                            <option value='03'>
                                                {t('m-3')}
                                            </option>
                                            <option value='04'>
                                                {t('m-4')}
                                            </option>
                                            <option value='05'>
                                                {t('m-5')}
                                            </option>
                                            <option value='06'>
                                                {t('m-6')}
                                            </option>
                                            <option value='07'>
                                                {t('m-7')}
                                            </option>
                                            <option value='08'>
                                                {t('m-8')}
                                            </option>
                                            <option value='09'>
                                                {t('m-9')}
                                            </option>
                                            <option value='10'>
                                                {t('m-10')}
                                            </option>
                                            <option value='11'>
                                                {t('m-11')}
                                            </option>
                                            <option value='12'>
                                                {t('m-12')}
                                            </option>

                                        </Select>
                                    }
                                    defaultValue='01'
                                    name="mes_inicio"
                                    rules={{required: "Camp obligatori"}}
                                    control={control}
                                    fullWidth
                                />
                            </FormControl>

                            <FormControl className="mt-2" fullWidth>

                                <InputLabel>
                                    <p className="pl-0 pt-0"> {t('a_inicial')}</p>
                                </InputLabel>
                                <Controller
                                    as={
                                        <Select native>

                                            <option value={moment().subtract(2, 'years').format('YYYY')}>
                                                {moment().subtract(2, 'years').format('YYYY')}
                                            </option>
                                            <option value={moment().subtract(1, 'years').format('YYYY')}>
                                                {moment().subtract(1, 'years').format('YYYY')}

                                            </option>
                                            <option value={moment().format('YYYY')}>
                                                {moment().format('YYYY')}
                                            </option>

                                        </Select>
                                    }
                                    name="anyo_inicio"
                                    rules={{required: "Camp obligatori"}}
                                    control={control}
                                    fullWidth
                                    defaultValue='2020'

                                />
                            </FormControl>

                        </div>
                    </div>
                    <div className="position-relative w-50 mx-md-3 w-100-m">

                        <p className="pl-0 pt-0 P-absolute Tit "> {t('f_final')}</p>

                        <div className="d-flex">
                            <FormControl className="mt-2" fullWidth>

                                <InputLabel>
                                    <p className="pl-0 pt-0 "> Mes Final</p>
                                </InputLabel>
                                <Controller
                                    as={
                                        <Select native>

                                            <option value='01'>
                                                {t('m-1')}
                                            </option>
                                            <option value='02'>
                                                {t('m-2')}
                                            </option>
                                            <option value='03'>
                                                {t('m-3')}
                                            </option>
                                            <option value='04'>
                                                {t('m-4')}
                                            </option>
                                            <option value='05'>
                                                {t('m-5')}
                                            </option>
                                            <option value='06'>
                                                {t('m-6')}
                                            </option>
                                            <option value='07'>
                                                {t('m-7')}
                                            </option>
                                            <option value='08'>
                                                {t('m-8')}
                                            </option>
                                            <option value='09'>
                                                {t('m-9')}
                                            </option>
                                            <option value='10'>
                                                {t('m-10')}
                                            </option>
                                            <option value='11'>
                                                {t('m-11')}
                                            </option>
                                            <option value='12'>
                                                {t('m-12')}
                                            </option>

                                        </Select>
                                    }
                                    name="mes_final"
                                    defaultValue='01'
                                    rules={{required: "Camp obligatori"}}
                                    control={control}
                                    fullWidth
                                />
                            </FormControl>

                            <FormControl className="mt-2" fullWidth>

                                <InputLabel>
                                    <p className="pl-0 pt-0">{t('a_final')}</p>
                                </InputLabel>
                                <Controller
                                    as={
                                        <Select native>

                                            <option value={moment().subtract(2, 'years').format('YYYY')}>
                                                {moment().subtract(2, 'years').format('YYYY')}
                                            </option>
                                            <option value={moment().subtract(1, 'years').format('YYYY')}>
                                                {moment().subtract(1, 'years').format('YYYY')}

                                            </option>
                                            <option value={moment().format('YYYY')}>
                                                {moment().format('YYYY')}
                                            </option>

                                        </Select>
                                    }
                                    name="anyo_final"
                                    rules={{required: "Camp obligatori"}}
                                    control={control}
                                    fullWidth
                                    defaultValue='2020'

                                />
                            </FormControl>


                        </div>
                    </div>
                    <div className="position-relative w-33 mx-md-3 w-100-m d-flex flex-column justify-content-end No-pad">

                        <p className="pl-0 pt-0 P-absolute  Tit"> {t('max')}</p>

                        <div className="d-flex">
                            <FormControl className="mt-2" fullWidth>
                                <InputLabel>
                                    <p className="pl-0 pt-0">{t('num')}</p>
                                </InputLabel>

                                    { props.tarifa === '2.0A' && <Select required onChange={handleFiltroTramo} defaultValue='1' name="pot" fullWidth><MenuItem value='1'>1</MenuItem></Select>}
                                    { props.tarifa === '2.1A' && <Select required onChange={handleFiltroTramo} defaultValue='1' name="pot" fullWidth><MenuItem value='1'>1</MenuItem></Select>}
                                    { props.tarifa === '2.0DHA' && <Select required onChange={handleFiltroTramo} defaultValue='1' name="pot" fullWidth><MenuItem value='1'>1</MenuItem></Select>}
                                    { props.tarifa === '2.1DHA' && <Select required onChange={handleFiltroTramo} defaultValue='1' name="pot" fullWidth><MenuItem value='1'>1</MenuItem></Select>}
                                    { props.tarifa === '3.0A' && <Select required onChange={handleFiltroTramo} defaultValue='tots' name="pot" fullWidth><MenuItem value='tots'>{t('tots')}</MenuItem><MenuItem value='1'>1</MenuItem><MenuItem value='2'>2</MenuItem><MenuItem value='3'>3</MenuItem></Select>}
                                    { props.tarifa === '3.1A' && <Select required onChange={handleFiltroTramo} defaultValue='tots' name="pot" fullWidth><MenuItem value='tots'>{t('tots')}</MenuItem><MenuItem value='1'>1</MenuItem><MenuItem value='2'>2</MenuItem><MenuItem value='3'>3</MenuItem></Select>}
                                    { props.tarifa === '6.1A' && <Select required onChange={handleFiltroTramo} defaultValue='tots' name="pot" fullWidth><MenuItem value='tots'>{t('tots')}</MenuItem><MenuItem value='1'>1</MenuItem><MenuItem value='2'>2</MenuItem><MenuItem value='3'>3</MenuItem><MenuItem value='4'>4</MenuItem><MenuItem value='5'>5</MenuItem><MenuItem value='6'>6</MenuItem></Select>}



                            </FormControl>


                        </div>
                    </div>

                </div>

                <button className="Btn Btn_green ButtM" type="button" onClick={handleClickPersonalizado}>
                    <span>Buscar</span>
                </button>
            </div>
        </div>
    );


}