import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {FormControl, Select} from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import moment from "moment";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
// Include the locale utils designed for moment
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import { useTranslation } from 'react-i18next';

// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'moment/locale/ca';
import CircularProgress from "@material-ui/core/CircularProgress";
import MultipleSelect from "./multiple-select";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'none',
    },
}));

export default function TabGraficoFiltro(props) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [activarFiltroDia, setactivarFiltroDia] = useState(false);
    const [valorFiltro, setvalorFiltro] = useState('days');
    const [locale, setLocale] = useState('en');
    const [filterDiaValue, setfilterDiaValue] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'));
    const [filtroPersonalizadoInicio, setfiltroPersonalizadoInicio] = useState(moment().format('YYYY-MM-DD'));
    const [filtroPersonalizadoFinal, setfiltroPersonalizadoFinal] = useState(moment().format('YYYY-MM-DD'));
    const [filtroPeriodoInicio, setfiltroPeriodoInicio] = useState();
    const [filtroPeriodoFinal, setfiltroPeriodoFinal] = useState();
    const [errorMas60Dias, seterrorMas60Dias] = useState();
    const { t, i18n } = useTranslation();
    const [filtroLineas, setFiltroLineas] = useState([t('energia')]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeDateInput = (data) => {
        setfilterDiaValue(moment(data).format('YYYY-MM-DD'));
    };

    function handleChangeDateInputPersonalizadoInicio(data) {
        setfiltroPersonalizadoInicio(moment(data).format('YYYY-MM-DD'));
    };

    function handleChangeDateInputPersonalizadoFinal(data) {
        setfiltroPersonalizadoFinal(moment(data).format('YYYY-MM-DD'));

    };
    const handleChangeFiltro = event => {
        if (event.target.value === 'days') {
            setactivarFiltroDia(false)
        } else {
            setactivarFiltroDia(true)

        }
        setvalorFiltro(event.target.value);//controlo que el valor filtro varíe segun el change del radio
    };

    function handleChangeSelectPeriodo(event) {

        let arr_data = event.target.value.split("/")

        setfiltroPeriodoInicio(arr_data[0]);
        setfiltroPeriodoFinal(arr_data[1]);

    };
    useEffect(()=> {
        if(props.periodos){
            setfiltroPeriodoInicio(moment(props.periodos[0].dataAnterior).add(1, 'days').format('YYYY-MM-DD'));
            setfiltroPeriodoFinal(moment(props.periodos[0].dataActual).format('YYYY-MM-DD'));


        }


    },[props.periodos])


    const handleClick = () => {
        console.log(filtroLineas)

        let fecha_actual = moment().format('YYYY-MM-DD');

        /*al hacer click en aplicar uso momentjs para pasarle el valor del filtro al substract
        * Valor filtro será o days o weeks o months, para obtener la fecha de hace 1 dia/semana/mes
        * */
        let fecha_inicial_filtro = moment().subtract(1, valorFiltro).format('YYYY-MM-DD');

        /*llamo a la funcion que he pasado por props para actualizar las fechas del componente padre donde hago el get*/

        props.actualizarFechas(fecha_actual, fecha_inicial_filtro, filterDiaValue, valorFiltro,filtroLineas)//paso el valor filtro de vuelta porque si es de dai lalmaré a la funcion que devuelve las horas y no los días

    }





    const handleClickPersonalizado = () => {
        let fecha1 = moment(filtroPersonalizadoFinal)

        let fecha2 = moment(filtroPersonalizadoInicio)




        if(fecha1.diff(fecha2, 'days')>60){
            seterrorMas60Dias(t('limitdies'))
            return
        }
        if(filtroPersonalizadoInicio == moment().format('YYYY-MM-DD')){
            props.actualizarFechas(filtroPersonalizadoFinal, moment().subtract(1, 'days').format('YYYY-MM-DD'),null,null,filtroLineas)//paso el valor filtro de vuelta porque si es de dai lalmaré a la funcion que devuelve las horas y no los días

        }else{
            props.actualizarFechas(filtroPersonalizadoFinal, filtroPersonalizadoInicio,null,null,filtroLineas)//paso el valor filtro de vuelta porque si es de dai lalmaré a la funcion que devuelve las horas y no los días

        }

    }
    const handleClickPeriodo = () => {

        props.actualizarFechas( filtroPeriodoFinal,filtroPeriodoInicio,null,null,filtroLineas)//paso el valor filtro de vuelta porque si es de dai lalmaré a la funcion que devuelve las horas y no los días

    }

    function handleSelectChange(e) {
        setLocale(e.target.value);
    }

    return (
        <div className={`${classes.root} Tabfiltro1`}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className="">
                    <Tab label={t('pdata')} {...a11yProps(0)} />
                    <Tab label={t('pfacturat')} {...a11yProps(1)} />
                    <Tab label={t('av')} {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>

                <div className=" Filtro-avanzado-consumo d-flex align-items-center flex-column-m justify-content-center align-items-center">

                    <div   className="pt-1">
                        <RadioGroup aria-label="filtro_fecha" name="filtro_fecha" value={valorFiltro} row
                                    onChange={handleChangeFiltro}>
                            <div>
                                <FormControlLabel value="days" control={<Radio/>} label={t('ud')}/>

                                <DayPickerInput style={{'zIndex': 1000000000}} disabled={activarFiltroDia}
                                                format="DD-MM-YYYY" id="filtro_dia"
                                                value={`${moment().subtract(1, 'days').format('DD-MM-YYYY')}`}
                                                placeholder={`${moment().subtract(1, 'days').format('DD-MM-YYYY')}`} formatDate={formatDate}
                                                dayPickerProps={{localeUtils: MomentLocaleUtils, locale: "ca"}}
                                                onDayChange={day => handleChangeDateInput(day)}/>


                            </div>
                            <FormControlLabel value="weeks" control={<Radio/>} label={t('us')} className="mr-4"/>
                            <FormControlLabel value="months" control={<Radio/>} label={t('um')}/>
                        </RadioGroup>
                    </div>
                  {/*  <div style={{'width':'40%'}}>
                        <MultipleSelect id1={'multiple1_1'} id2={'multiple1_2'} id3={'multiple1_3'} filtroLineas={filtroLineas} setFiltroLineas={setFiltroLineas}/>
                    </div>*/}
                    <button className="Btn Btn_green ml-md-5" type="button" onClick={handleClick}><span>Buscar</span>
                    </button>
                </div>

            </TabPanel>
            <TabPanel value={value} index={1}>
                <div className="d-flex Filtro-avanzado-consumo align-items-end justify-content-center flex-column-m">
                    {props.cargandoPeriodo ? <CircularProgress className="mx-auto" disableShrink/> :
                    <div className=" w-100-m mb-3 mb-md-0 mr-md-5">
                        <p className="pl-0 mb-1"> {t('periodo2')}</p>
                         <Select onChange={handleChangeSelectPeriodo} fullWidth name="prefijo_tlf" native>
                            {
                                props.periodos && props.periodos.map((obj, index) => {

                                    return <option key={index}
                                                   value={moment(obj.dataAnterior).add(1, 'days').format('YYYY-MM-DD') + '/' + moment(obj.dataActual).format('YYYY-MM-DD')}>{moment(obj.dataAnterior).add(1, 'days').format('DD/MM/YYYY') + ' - ' + moment(obj.dataActual).format('DD/MM/YYYY')}</option>
                                })
                            }

                        </Select>

                    </div>}
                  {/*  <div style={{'width':'40%'}}>
                        <MultipleSelect id1={'multiple2_1'} id2={'multiple2_2'} id3={'multiple2_3'} filtroLineas={filtroLineas} setFiltroLineas={setFiltroLineas}/>
                    </div>*/}
                    <button className="Btn Btn_green ml-md-5" type="button" onClick={handleClickPeriodo}>
                        <span>Buscar</span>
                    </button>
                </div>


            </TabPanel>
            <TabPanel value={value} index={2}>
                <div className="mr-5  Filtro-avanzado-consumo flex-column-m justify-content-center ">
                    <div className="d-flex flex-column w-25">
                        <p className="pl-0"> {t('f_inicial')}</p>
                        {/*   <input type="date" className="mr-4" onChange={handleChangeDateInputPersonalizadoInicio} defaultValue={filtroPersonalizadoInicio} max={moment().format('YYYY-MM-DD')} id="filtro_personalizado_inicio"  />
                        */}
                        <DayPickerInput format="DD-MM-YYYY" formatDate={formatDate}
                                        dayPickerProps={{localeUtils: MomentLocaleUtils, locale: "es"}}
                                        placeholder={`${moment().subtract(1, 'days').format('DD-MM-YYYY')}`}
                                        onDayChange={day => handleChangeDateInputPersonalizadoInicio(day)}/>
    <small className="text-danger m-0 position-absolute" style={{'bottom':'0'}}>{errorMas60Dias}</small>
                    </div>
                    <div className="d-flex flex-column  w-25">
                        <p className="pl-0"> {t('f_final')}</p>

                        {/*  <input type="date" onChange={handleChangeDateInputPersonalizadoFinal} defaultValue={filtroPersonalizadoFinal} max={moment().format('YYYY-MM-DD')} id="filtro_personalizado_final"  />
                    */}

                        <DayPickerInput format="DD-MM-YYYY" formatDate={formatDate}
                                        dayPickerProps={{localeUtils: MomentLocaleUtils, locale: "es"}}
                                        placeholder={`${moment().format('DD-MM-YYYY')}`}
                                        onDayChange={day => handleChangeDateInputPersonalizadoFinal(day)}/>

                    </div>
                  {/*  <div style={{'width':'40%'}}>
                        <MultipleSelect id1={'multiple3_1'} id2={'multiple3_2'} id3={'multiple3_3'} filtroLineas={filtroLineas} setFiltroLineas={setFiltroLineas}/>
                    </div>*/}
                    <button className="Btn Btn_green " type="button" onClick={handleClickPersonalizado}>
                        <span>Buscar</span>
                    </button>


                </div>
            </TabPanel>
        </div>
    );


}